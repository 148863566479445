<template>
  <b-navbar toggleable="lg" type="light" :class="{ 'bg-white': zalogowany }">

    <b-nav-toggle target="nav_collapse" v-if="widocznaNawigacja"></b-nav-toggle>

    <b-navbar-brand href="#">
      <router-link :to="{ path: '/app'}">
        <img src="../assets/logo.svg" alt="eZLA" width="36" height="36">
      </router-link>
    </b-navbar-brand>

    <b-collapse is-nav id="nav_collapse" v-if="widocznaNawigacja">

      <b-navbar-nav>
        <b-nav-item :to="{ name: 'Wyszukiwanie'}">
          Nowe zwolnienie
        </b-nav-item>

        <b-nav-item :to="{ name: 'WystawioneZwolnienia'}">
          Wystawione zwolnienia
        </b-nav-item>

        <b-nav-item :to="{ name: 'WydrukFormularzyZla'}">
          Wydruk formularzy ZLA
        </b-nav-item>

        <b-nav-item id="navWiadomosci" :to="{ name: 'Wiadomosci'}">
          Wiadomości <b-badge :variant="nieprzeczytaneWiadomosci.length > 0 ? 'primary': ''">{{ nieprzeczytaneWiadomosci.length }}</b-badge>
        </b-nav-item>

        <b-nav-item to="/app/polecanie">
          Poleć znajomemu
        </b-nav-item>

        <b-nav-item v-if="maPremium" href="#">
          <b-badge variant="info">Wersja premium</b-badge>
        </b-nav-item>
        <b-nav-item v-else to="/app/porownanie">
          Kup wersję Premium
        </b-nav-item>

      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">

        <!-- <b-nav-form>
          <b-form-input size="sm" class="mr-sm-2" type="text" placeholder="Search"/>
          <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
        </b-nav-form> -->

        <b-nav-item-dropdown right v-if="aktywnyWyborMiejscaPracy">
          <template slot="button-content">
            {{ nazwaWystawcy }}
          </template>
          <b-dropdown-item href="#"
            v-for="(miejsce, index) in miejscaPracy"
            :key="index"
            @click="wybierz(miejsce)">
            <span v-b-tooltip :title="formatujAdres(miejsce.placowkaLecznicza.adres)">{{ miejsce.placowkaLecznicza.nazwaSkrocona }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
          <template slot="button-content">
            {{ imieNazwiskoLekarza }}
          </template>
          <b-dropdown-item to="/app/profil">Profil</b-dropdown-item>
          <b-dropdown-item to="/app/licencje">
            Licencje <b-badge v-if="!maPremium" variant="info">Premium</b-badge>
          </b-dropdown-item>
          <b-dropdown-item v-if="!maPremium" :to="{name: 'PorownanieWersji'}">
            Wizytówka <b-badge variant="info">Premium</b-badge>
          </b-dropdown-item>
          <b-dropdown-item v-if="maPremium" :to="{name: 'Wizytowka'}">
            Wizytówka
          </b-dropdown-item>
          <b-dropdown-item href="#" @click="wyloguj">Wyloguj</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import * as ZmianyStanu from '../store/ZmianyStanu'
import MiejscaPracy from '../services/MiejscaPracy'
import Wylogowanie from '../services/Wylogowanie'
import Utils from '../services/Utils'

export default {
  name: 'nawigacja',
  computed: {
    ...mapState([
      'idWystawcy',
      'nazwaWystawcy',
      'miejscaPracy'
    ]),
    ...mapGetters([
      'zalogowany',
      'imieNazwiskoLekarza',
      'maPremium',
      'maWymaganeZgody',
      'nieprzeczytaneWiadomosci'
    ]),
    aktywnyWyborMiejscaPracy () {
      return !!this.miejscaPracy && this.miejscaPracy.length > 0
    },
    widocznaNawigacja () {
      return this.zalogowany && this.maWymaganeZgody && !!this.idWystawcy
    }
  },
  methods: {
    wybierz (miejsce) {
      MiejscaPracy.wybierzDoKontekstu(miejsce).then(wystawca => {
        this.$store.commit({
          type: ZmianyStanu.USTAW_WYSTAWCE,
          idWystawcy: wystawca.idWystawcy,
          nazwaWystawcy: miejsce.placowkaLecznicza.nazwaSkrocona
        })
        this.$ga.event('miejsca pracy', 'wybrano', miejsce.placowkaLecznicza.nazwaSkrocona)
      })
    },
    wyloguj () {
      Wylogowanie.wyloguj()
    },
    formatujAdres (adres) {
      return adres.miejscowosc + ', ' + Utils.sformatujUlice(adres)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";

.navbar {
  border-top: 4px solid $primary;
  background: none;
  border-bottom: none;
}

.bg-white {
  background: white;
  border-bottom: 1px solid $line-bg;
}

.navbar-toggler {
  border: none;
}

.navbar .navbar-nav /deep/ .nav-link {
  padding: 0.25em 0.4em;
  border-radius: 0.25rem;
  margin: 0.2rem 0.5rem;

  &.active, &:hover {
    color: #fff;
    background-color: $green;
  }
}

</style>
