<template>
  <transition name="slide-fade" mode="out-in">
    <div v-if="!trybEdycja">
      <!-- kafelki -->
      <div class="tiles-container">
        <Adresy :adresy="adresyDoWyboru"
                :value="wybranyAdres"
                data-vv-name="adres"
                v-validate="'required|wymaganyKodPocztowy'"
                @input="wybranoAdres"
                @edytuj="edytujAdres"
                @usun="usunAdres">
        </Adresy>
      </div>

      <!-- przycisk dodawania -->
      <b-button v-if="adresyDoWyboru.length > 0"
        class="btn-add"
        variant="light"
        @click="nowyAdres()"
        v-b-tooltip="'nowy adres'">
        <strong>+</strong>
      </b-button>
      <b-button v-if="adresyDoWyboru.length === 0"
        variant="secondary"
        @click="nowyAdres()">
        zdefiniuj adres ubezpieczonego
      </b-button>

      <div v-if="this.errors.has('adres')"
        class="invalid-feedback d-block">
        {{ errors.first('adres') }}
      </div>
    </div>

    <!-- formularz -->
    <div v-if="trybEdycja">
      <AdresForm :adres="kopiaAdresu"
        @zapisz="zapisz"
        @cofnij="trybEdycja = false">
      </AdresForm>
    </div>
  </transition>
</template>

<script>
import Adresy from './Adresy'
import AdresForm from '../form/AdresForm'

export default {
  name: 'adres-ubezpieczonego',
  components: { Adresy, AdresForm },
  props: ['adresy', 'wybranyAdres'],
  inject: ['$validator'],
  data () {
    return {
      trybEdycja: false,
      edytowanyAdres: null,
      adresyDoWyboru: []
    }
  },
  computed: {
    kopiaAdresu () {
      return Object.assign({}, this.edytowanyAdres)
    }
  },
  mounted () {
    this.adresyDoWyboru = this.adresy.slice()
    if (!this.adresy || this.adresy.length === 0) {
      this.nowyAdres()
    } else if (this.adresyDoWyboru && this.wybranyAdres == null) {
      this.wybranoAdres(this.adresyDoWyboru[0])
    }
  },
  methods: {
    wybranoAdres (adres) {
      this.$emit('update:wybranyAdres', adres)
    },
    nowyAdres () {
      this.edytowanyAdres = { nowy: true }
      this.trybEdycja = true
    },
    edytujAdres (adres) {
      this.edytowanyAdres = adres
      this.trybEdycja = true
    },
    usunAdres (adres) {
      const index = this.adresyDoWyboru.indexOf(adres)
      if (index !== -1) {
        this.adresyDoWyboru.splice(index, 1)
      }
      if (this.wybranyAdres === adres) {
        this.wybranoAdres(null)
      }
    },
    zapisz (adres) {
      const index = this.adresyDoWyboru.indexOf(this.edytowanyAdres)
      if (index !== -1) {
        this.adresyDoWyboru[index] = adres
      } else {
        this.adresyDoWyboru.push(adres)
      }
      this.wybranoAdres(adres)
      this.trybEdycja = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";

.tiles-container {
  display: inline-block;
  vertical-align: top;
}

.btn-add {
  margin-top: 0.25rem;
  margin-left: -4px;
  background-color: $input-bg;
  height: 104px;
}
</style>
