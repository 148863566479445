<template>
  <b-form-checkbox-group
    name="checkboxPlatnicy"
    :checked="value"
    @input="aktualizuj($event)">
    <div v-for="(platnik, index) in platnicy"
      :key="index"
      :class="[{ 'checkbox-container-selected': value.indexOf(platnik) !== -1 }, 'checkbox-container']"
      @click="wybierz(platnik)">
      <b-form-checkbox :value="platnik">
        {{ sformatujNrPlatnika(platnik) }}<br>{{ sformatujNazwePlatnika(platnik) }}
        <b-badge variant="secondary"
            v-if="!platnik.maPue"
            v-b-tooltip.bottom="ostrzezenie">
            wydruk ZLA
        </b-badge>
      </b-form-checkbox>

      <a class="icon icon-edit"
        @click.stop="edytuj(platnik)"
        v-b-tooltip="'edytuj płatnika'">
        <icon name="pencil" scale="0.8" class="fa-fw text-muted"></icon>
      </a>
      <a class="icon icon-delete"
        v-if="platnik.nowy"
        @click.stop="usun(platnik)"
        v-b-tooltip="'usuń płatnika'">
        <icon name="times" scale="0.8" class="fa-fw text-muted"></icon>
      </a>
    </div>
  </b-form-checkbox-group>
</template>

<script>
import * as Komunikaty from '@/constants/Komunikaty'

export default {
  name: 'platnicy',
  props: ['platnicy', 'value'],
  data () {
    return {
      ostrzezenie: Komunikaty.PLATNIK_NIE_MA_PUE.TRESC_NOWE_ZLA
    }
  },
  methods: {
    aktualizuj (platnicy) {
      this.$emit('input', platnicy)
    },
    edytuj (p) {
      this.$emit('edytuj', p)
    },
    usun (p) {
      this.$emit('usun', p)
    },
    wybierz (platnik) {
      if (this.value.indexOf(platnik) === -1) {
        this.$emit('input', this.value.concat(platnik))
      } else {
        this.$emit('input', this.value.filter(p => p !== platnik))
      }
    },
    sformatujNrPlatnika (platnik) {
      if (platnik.instytucja) {
        return `${platnik.instytucja.nip}`
      } else {
        return `${platnik.osoba.pesel}`
      }
    },
    sformatujNazwePlatnika (platnik) {
      if (platnik.instytucja) {
        return `${platnik.instytucja.nazwa}`
      } else {
        return `${platnik.osoba.imie} ${platnik.osoba.nazwisko}`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-print {
  position: relative;
  top: 0.1rem;
}
</style>
