<template>
   <b-container class="container-alert mt-3">
    <b-card class="card-page" no-body>
      <b-form @submit.prevent="wydrukujFormularzeZla">
        <div class="card-body">
          <h4>Wydruk formularzy ZLA</h4>
          <p>Ile druków potrzebujesz?</p>
          <b-form-input id="ileDrukow" type="number" v-model="ileDrukow" size="lg"></b-form-input>
        </div>
        <div class="card-body text-left">
          <b-form-checkbox :disabled="!maPremium" v-model="wypelnijMiejsce">
            Wypełnij miejsce udzielania świadczeń
            <router-link v-if="!maPremium" :to="{name: 'PorownanieWersji'}">
              <b-badge variant="info">Premium</b-badge>
            </router-link>
          </b-form-checkbox>
          <b-form-radio-group
            stacked
            v-model="wybraneMiejsce"
            :options="miejsca"
            :disabled="!maPremium || (maPremium && !wypelnijMiejsce)">
          </b-form-radio-group>
          <div class="mt-4">
            <b-btn type="submit" variant="primary" size="lg">Pobierz wydruki</b-btn>
          </div>
        </div>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Ezla from '@/services/Ezla'

export default {
  name: 'wydrukFormularzyZla',
  data () {
    return {
      ileDrukow: 5,
      wypelnijMiejsce: false,
      wybraneMiejsce: null
    }
  },
  computed: {
    ...mapState(['miejscaPracy']),
    ...mapGetters(['maPremium']),
    miejsca () {
      return this.miejscaPracy.map(mp => (
        {
          text: mp.placowkaLecznicza.instytucja.nazwa,
          value: mp.placowkaLecznicza.instytucja.nip
        }
      ))
    }
  },
  mounted () {
    if (this.maPremium) {
      this.wypelnijMiejsce = true
      this.wybraneMiejsce = this.miejsca[0].value
    }
  },
  methods: {
    wydrukujFormularzeZla: function () {
      let miejsce = this.wypelnijMiejsce ? this.wybraneMiejsce : null
      Ezla.drukujFormularze(this.ileDrukow, miejsce)
    }
  }
}
</script>

<style>

</style>
