import Vue from 'vue'
import Router from '@/router/index'
import Store from '@/store/index'
import * as ZmianyStanu from '@/store/ZmianyStanu'

function wylogujDoGlownej () {
  Store.commit({ type: ZmianyStanu.WYLOGUJ })
  Router.push({ name: 'Glowna' })
}

export default {
  wyloguj () {
    Vue.http.post('wylogowanie').then(wylogujDoGlownej(), wylogujDoGlownej())
  }
}
