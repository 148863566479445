<template>
  <b-form-radio-group id="radioAdresy"
                      name="radioAdresy"
                      :checked="value"
                      @input="aktualizuj($event)">
    <div v-for="(adres, index) in adresy"
      :key="index"
      :class="[{ 'radio-container-selected': adres === value }, 'radio-container']"
      @click="aktualizuj(adres)">
      <b-form-radio :value="adres">
        {{ sformatujMiasto(adres) }}<br>{{ sformatujUlice(adres) }}<br>
        <b-badge variant="secondary" v-b-tooltip.bottom="pochodzenieTooltip(adres)">{{pochodzenie(adres)}}</b-badge>
      </b-form-radio>

      <a class="icon icon-edit"
        @click.stop="edytuj(adres)"
        v-b-tooltip="'edytuj adres'">
        <icon name="pencil" scale="0.8" class="fa-fw text-muted"></icon>
      </a>
      <a class="icon icon-delete"
        v-if="adres.nowy"
        @click.stop="usun(adres)"
        v-b-tooltip="'usuń adres'">
        <icon name="times" scale="0.8" class="fa-fw text-muted"></icon>
      </a>
    </div>
  </b-form-radio-group>
</template>

<script>
import Utils from '../../services/Utils'

export default {
  name: 'adresy',
  props: ['adresy', 'value'],
  methods: {
    aktualizuj (adres) {
      this.$emit('input', adres)
    },
    edytuj (adres) {
      this.$emit('edytuj', adres)
    },
    usun (adres) {
      this.$emit('usun', adres)
    },
    sformatujMiasto (adres) {
      return `${adres.kodPocztowy} ${adres.miejscowosc}`
    },
    sformatujUlice (adres) {
      return Utils.sformatujUlice(adres)
    },
    pochodzenie (adres) {
      if (adres.adresZus) {
        return 'ZUS'
      } else {
        return 'ezlapka'
      }
    },
    pochodzenieTooltip (adres) {
      if (adres.adresZus) {
        return 'adres pochodzi z systemu ZUS'
      } else {
        return 'adres wpisany ręcznie'
      }
    }
  }
}
</script>
