<template>
  <b-form-group class="btn-group-codes">
    <b-form-checkbox-group buttons
      id="checkboxKodyLiterowe"
      name="checkboxKodyLiterowe"
      button-variant="light"
      :checked="value"
      @input="aktualizuj($event)">
      <b-form-checkbox v-for="kod in kodyLiterowe"
                    :key="kod"
                    :value="kod"
                    v-b-tooltip="{title: opisy[kod], delay: 1000}">
        {{ kod }}
      </b-form-checkbox>
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'kody-literowe',
  props: ['value'],
  data () {
    return {
      kodyLiterowe: ['A', 'B', 'C', 'D', 'E'],
      opisy: {
        A: `Niezdolność do pracy powstała po przerwie nieprzekraczającej 60 dni spowodowana tą samą chorobą, która była przyczyną niezdolności do pracy przed przerwą`,
        B: `Niezdolność do pracy przypadająca w okresie ciąży`,
        C: `Niezdolność do pracy spowodowana nadużyciem alkoholu`,
        D: `Niezdolność do pracy spowodowana gruźlicą`,
        E: `Niezdolność do pracy spowodowana chorobą zakaźną, której okres wylęgania jest dłuższy niż 14 dni, lub inną chorobą, której objawy chorobowe ujawniają się po okresie dłuższym niż 14 dni od początku choroby`
      }
    }
  },
  methods: {
    aktualizuj (kody) {
      this.$emit('input', kody)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.btn-group-codes {
  @media (min-width: $sm) {
    float: right;
  }

  /deep/ .btn {
    width: 38px;
    background-color: $input-bg;
    border-color: $input-bg;

    &:hover {
      background-color: $green-dark;
      border-color: $green-dark;
      color: white;
    }

    &.active {
      background-color: $green;
      border-color: $green;
      color: white;
    }
  }
}
</style>
