import Vue from 'vue'
import Utils from './Utils'

export default {
  zarejestruj (ezla) {
    return Vue.http.post('ezla', ezla).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  wyszukaj (pesel, nazwisko, nip, nazwaPlatnika, odKiedy) {
    let kryteriaWyszukiwania = {
      pesel: pesel,
      nazwisko: nazwisko,
      nip: nip,
      nazwaPlatnika: nazwaPlatnika,
      odKiedy: odKiedy
    }
    return Vue.http.post('ezla/szukaj', kryteriaWyszukiwania).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  wyszukajObowiazujaceUbezpieczonego (pesel, konczySieNieWczesniejNiz) {
    let kryteriaWyszukiwania = {
      pesel: pesel,
      nazwisko: null,
      nip: null,
      nazwaPlatnika: null,
      odKiedy: null,
      konczySieNieWczesniejNiz: konczySieNieWczesniejNiz,
      tylkoZalogowanyLekarz: false,
      sprawdzajCzyMoznaAnulowac: false
    }
    return Vue.http.post('ezla/szukaj', kryteriaWyszukiwania).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  anuluj (ezla, przyczynaAnulowania, haslo, idWystawcy) {
    let zadanieAnulowania = {
      uuidWystawcy: idWystawcy,
      uuidEzla: ezla.uuid,
      hasloDoCertyfikatuZus: haslo,
      przyczynaAnulowania: przyczynaAnulowania.nazwa,
      kodPrzyczynyAnulowania: przyczynaAnulowania.kod
    }
    return Vue.http.post('ezla/anulowane', zadanieAnulowania).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  drukujFormularze (ile, nipMiejscaPracy) {
    return new Promise((resolve, reject) => {
      let w = window.open('/api/ezla/formularze?ile=' + ile + '&nip=' + nipMiejscaPracy)
      if (w !== null) {
        resolve(w)
      } else {
        reject(new Error('Nie można otworzyć okna z wydrukiem formularzy ZLA'))
      }
    })
  }
}
