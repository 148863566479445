import Vue from 'vue'
import Utils from './Utils'

export default {

  pobierzMiejscaPracy () {
    return Vue.http.get('miejsca-pracy/moje').then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  wybierzDoKontekstu (miejscePracy) {
    return Vue.http.post('miejsca-pracy/wybrane', miejscePracy).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  }
}
