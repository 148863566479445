<template>
  <div id="app">
    <header class="header-app">
      <nawigacja></nawigacja>
    </header>

    <div class="banner" :class="{ 'background-offset': zalogowany }"></div>

    <main class="main-app">
      <router-view></router-view>
    </main>

    <footer>
      <licznik-czasu-sesji v-if="zalogowany"></licznik-czasu-sesji>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Nawigacja from './components/Nawigacja'
import LicznikCzasuSesji from './components/LicznikCzasuSesji'

export default {
  name: 'app',
  components: { Nawigacja, LicznikCzasuSesji },
  metaInfo: {
    title: 'Aplikacja',
    titleTemplate: '%s | eZLApka - łatwe eZLA!'
  },
  computed: {
    ...mapGetters([
      'zalogowany'
    ])
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url('./assets/app1.png');
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
}

.background-offset {
  background-position-y: 66px;
}
</style>
