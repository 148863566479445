<template>
  <table class="table">
    <thead>
      <tr>
        <th>Nazwa</th>
        <th>Cena</th>
        <th>Ilość</th>
      </tr>
    </thead>
    <tr>
      <td>{{ opis }}</td>
      <td v-if="!staraCena">{{ cena | formatujKwote }}</td>
      <td v-else><s>{{ staraCena | formatujKwote }}</s>&nbsp;{{ cena | formatujKwote }}</td>
      <td>
        <b-form inline
          class="form-counter"
          v-if="maksymalnaIlosc > 1">
          <b-form-select v-model="ile" v-on:change="$emit('update:ilosc', $event)">
            <option v-for="n in maksymalnaIlosc"
              :key="n"
              :value="n">
              {{ n }}
            </option>
          </b-form-select>
        </b-form>
        <span v-else>1</span>
      </td>
    </tr>
    <tfoot>
      <tr>
        <th class="text-right">Razem do zapłaty:</th>
        <th> {{ sumaDoZaplaty | formatujKwote }}</th>
        <th></th>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: 'pozycja-cennika',
  props: ['opis', 'cena', 'staraCena', 'maksymalnaIlosc', 'ilosc'],
  data () {
    return {
      ile: this.ilosc
    }
  },
  computed: {
    sumaDoZaplaty () {
      return this.cena * this.ile
    }
  }
}
</script>

<style lang="scss" scoped>
td {
  vertical-align: middle;
}

.form-counter {
  min-width: 66px;
}
</style>
