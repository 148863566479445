import Vue from 'vue'
import Utils from './Utils'

export default {

  podpowiedzChoroby (fraza) {
    return Vue.http.get('choroby', { params: { q: fraza } }).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  podpowiedzOstatnioUzyteKodyChorob () {
    return Vue.http.get('choroby/ostatnio-uzyte').then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  podpowiedzKodyLiterowe (ubezpieczony, dataPoczatkuNiezdolnosci, kodChoroby) {
    let p = {
      pesel: ubezpieczony.pesel || null,
      nrPaszportu: ubezpieczony.numerPaszportu || null,
      dataPoczatkuNiezdolnosci: dataPoczatkuNiezdolnosci,
      kodChoroby: kodChoroby
    }
    return Vue.http.get('kody-literowe', { params: p }).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  }
}
