<template>
   <b-container class="container-alert mt-3">
    <b-card class="card-page" no-body>

      <!-- formularz -->
      <div class="card-body" v-if="!pokazPodsumowanie">
        <h4>Poleć eZLApkę</h4>
        <p>Możesz polecić eZLApkę swoim znajomym i dzięki temu korzystać przez <strong>1 miesiąc</strong>
        z wersji <b-badge variant="info">Premium</b-badge> zupełnie <strong>za darmo</strong>. Wystarczy,
        że jedna ze wskazanych osób zacznie korzystać z naszej aplikacji.</p>
        <b-alert show variant="info">
          <h5>Pamiętaj!</h5>
          Korzyści sumują się - im więcej osób zacznie używać eZLApki, tym dłużej będziesz
          miał darmowy dostęp do wersji <b-badge variant="info">Premium</b-badge>.
        </b-alert>
        <b-form @submit.prevent="polecAplikacje">
          <b-form-group label="Podaj adresy email osób, którym chesz polecić eZLApkę:"
            description="Poszczególne adresy oddziel spacjami lub wpisz je w osobnych liniach"
            :invalid-feedback="errors.first('emails')">
            <b-form-textarea name="emails"
              placeholder="Wpisz adresy e-mail"
              v-model="emaile"
              v-validate="'required|emails'"
              data-vv-as="e-mail"
              data-vv-name="emails"
              :rows="3"
              :max-rows="6"
              :state="!this.errors.has('emails')">
            </b-form-textarea>
          </b-form-group>
          <b-form-group id="grpWiadomosc"
                        label="Jeżeli chcesz możesz także napisać kilka słów od siebie:"
                        description="Powyższy tekst znajdzie się w treści wiadomości email, którą wyślemy do wskazanych przez Ciebie osób"
                        label-for="iEmaile">
            <b-form-textarea id="iEmaile"
                          placeholder="Wiadomość od polecającego"
                          v-model="wiadomosc"
                          :rows="3"
                          :max-rows="6">
            </b-form-textarea>
          </b-form-group>

          <div class="mt-4 text-center">
            <b-button type="submit" variant="primary" size="lg" :disabled="przetwarzanie">
              <icon v-if="przetwarzanie" name="circle-o-notch" scale="0.8" spin></icon>
              Poleć eZLApkę
            </b-button>
          </div>
        </b-form>
      </div>

      <!-- podsumowanie -->
      <div class="card-body" v-else>
         <b-row align-v="center">
          <b-col cols="2" class="text-center">
            <icon name="check-circle" scale="3" class="text-primary"></icon>
          </b-col>
          <b-col cols="10">
            <h4>Dziękujemy za polecenie eZLApki!</h4>
          </b-col>
        </b-row>
        <p class="my-4">Wysłaliśmy wiadomości email do wskazanych przez Ciebie osób. Cieszymy się, że grono
          użytkowników eZLApki znów się powiększy.
        </p>
        <p class="my-4">
          Jak tylko ktoś zacznie aktywnie korzystać z eZLApki, poinformujemy Cię o udostępnieniu Ci
          wersji <b-badge variant="info">Premium</b-badge>.
        </p>
        <div class="text-center mt-4">
          <b-btn variant="primary" size="lg" :to="{ name: 'Wyszukiwanie'}" title="Powrót do strony głównej">Zamknij</b-btn>
        </div>
      </div>
    </b-card>
   </b-container>
</template>

<script>
import PolecanieAplikacji from '@/services/PolecanieAplikacji'

export default {
  data () {
    return {
      emaile: '',
      wiadomosc: '',
      przetwarzanie: false,
      pokazPodsumowanie: false
    }
  },
  methods: {
    polecAplikacje () {
      this.$validator.validateAll().then((result) => {
        if (!result) { return }
        this.przetwarzanie = true
        PolecanieAplikacji.polec(this.emaile, this.wiadomosc).then(response => {
          this.pokazPodsumowanie = true
        }).catch(() => {
          this.blad()
        }).then(() => {
          this.przetwarzanie = false
        })
      })
    }
  },
  notifications: {
    blad: {
      title: 'Coś poszło nie tak...',
      message: 'Nie udało sie wysłać wiadomości email do wskazanych osób. Spróbuj kiedy indziej!',
      type: 'error',
      timeout: 7000
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

@media (max-width: $md) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

</style>
