import { render, staticRenderFns } from "./PozycjaCennika.vue?vue&type=template&id=76985dd2&scoped=true&"
import script from "./PozycjaCennika.vue?vue&type=script&lang=js&"
export * from "./PozycjaCennika.vue?vue&type=script&lang=js&"
import style0 from "./PozycjaCennika.vue?vue&type=style&index=0&id=76985dd2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76985dd2",
  null
  
)

component.options.__file = "PozycjaCennika.vue"
export default component.exports