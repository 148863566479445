<template>
  <b-container class="mt-3">
    <b-card class="card-page" no-body>

      <b-card-body>
        <b-row align-v="center">
          <b-col cols="2" class="text-center">
            <div class="bg-icon">
              <icon name="user-md" scale="2" class="text-white"></icon>
            </div>
          </b-col>
          <b-col cols="10">
            <h4>{{ imieNazwiskoLekarza }}</h4>
            <span>NPWZ {{ npwz }}</span>
          </b-col>
        </b-row>

        <b-table class="table-grid mt-4" stacked="md" :items="certyfikatyZus" :fields="naglowek">
          <template slot="nazwaPliku" slot-scope="data">
            <icon name="key"></icon> {{ data.item.nazwaPliku }}
          </template>
          <template slot="miejscePrzechowywania" slot-scope="data">
            <b-badge class="badge-logo badge-dropbox" v-if="data.item.miejscePrzechowywania == 'DROPBOX'">
              <img src="../../assets/dropbox.svg" alt="Logo eZLApka"> Dropbox
            </b-badge>
            <b-badge variant="light" class="badge-logo" v-if="data.item.miejscePrzechowywania == 'DYSK_GOOGLE'">
              <img src="../../assets/google_drive.svg" alt="Logo eZLApka"> Google Drive
            </b-badge>
            <b-badge variant="light" class="badge-logo" v-if="data.item.miejscePrzechowywania == 'ONEDRIVE'">
              <img src="../../assets/onedrive.svg" alt="Logo eZLApka"> OneDrive
            </b-badge>
            <b-badge variant="primary" class="badge-logo" v-if="data.item.miejscePrzechowywania == 'BAZA_DANYCH'">
              <img src="../../assets/logo_neg.svg" alt="Logo eZLApka"> eZLApka
            </b-badge>
          </template>
          <template slot="opcje" slot-scope="data">
            <b-btn v-if="data.item.miejscePrzechowywania == 'BAZA_DANYCH' && !czyDemo"
              class="btn-row btn-danger"
              @click="czyUsunacCertyfikat(data.item.uuid)">
              Usuń certyfikat
            </b-btn>
          </template>
        </b-table>
      </b-card-body>

      <!-- <b-card-footer>
        <b-btn variant="danger" size="sm" class="my-2">
          <icon name="trash"></icon> Usuń konto z eZLApki
        </b-btn>
      </b-card-footer> -->
    </b-card>

    <!-- modal usuwanie certyfikatu -->
    <b-modal ref="modalCertyfikat"
      centered
      title="Czy na pewno usunąć certyfikat?"
      ok-title="Usuń"
      ok-variant="danger"
      cancel-title="Anuluj"
      cancel-variant="outline-secondary"
      @ok="usunCertyfikat(false)"
      @cancel="uuidCertyfikatuDoUsuniecia = null">
      <p>
        Pamiętaj, tej operacji nie można confnąć! Upewnij się, że masz swój certyfikat ZUS zapisany
        na dysku komputera.
      </p>
    </b-modal>
    <b-modal ref="modalWylogowanie"
      centered
      title="Zostaniesz wylogowany!"
      ok-title="Usuń mimo to"
      ok-variant="danger"
      cancel-title="Anuluj"
      cancel-variant="outline-secondary"
      @ok="usunCertyfikat(true)"
      @cancel="uuidCertyfikatuDoUsuniecia = null">
      <p>
        Chcesz usunąć certyfikat ZUS, którego obecnie używasz. Jeśli to zrobisz zostaniesz wylogowany
        z eZLApki i będziesz musiał wskazać nowy certyfikat, którym będziesz podpisywać eZLA.
      </p>
    </b-modal>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import * as Komunikaty from '@/constants/Komunikaty'
import CertyfikatyZus from '@/services/CertyfikatyZus'
import Wylogowanie from '@/services/Wylogowanie'

export default {
  name: 'profil-lekarza',
  data () {
    return {
      naglowek: {
        nazwaPliku: {
          label: 'Nazwa certyfikatu'
        },
        waznyDo: {
          label: 'Ważny do',
          formatter: 'formatujDate'
        },
        miejscePrzechowywania: {
          label: 'Miejsce przechowywania'
        },
        opcje: {
          label: ' '
        }
      },
      uuidCertyfikatuDoUsuniecia: null
    }
  },
  computed: {
    ...mapState([
      'profilLekarza'
    ]),
    ...mapGetters([
      'imieNazwiskoLekarza',
      'czyDemo'
    ]),
    ...mapState({
      npwz (state) {
        return state.profilLekarza.daneLekarza.nrPrawaWykonywaniaZawodu
      },
      certyfikatyZus (state) {
        return state.profilLekarza.certyfikatyZus
      }
    }),
    wylogujPoUsunieciu () {
      return this.certyfikatyZus.length === 1
    }
  },
  methods: {
    formatujDate (d) {
      return moment(d).locale('pl').format('DD MMMM YYYY')
    },
    czyUsunacCertyfikat (uuid) {
      this.uuidCertyfikatuDoUsuniecia = uuid
      this.$refs.modalCertyfikat.show()
    },
    usunCertyfikat (force) {
      if (!force && this.wylogujPoUsunieciu) {
        this.$refs.modalWylogowanie.show()
      } else {
        CertyfikatyZus.usun(this.uuidCertyfikatuDoUsuniecia).then(() => {
          if (this.wylogujPoUsunieciu) {
            Wylogowanie.wyloguj()
          }
        }, () => { this.bladUsunieciaCertyfikatu() })
      }
    }
  },
  notifications: {
    bladUsunieciaCertyfikatu: {
      title: Komunikaty.NIEZNANY_BLAD.TYTUL,
      message: Komunikaty.NIEZNANY_BLAD.TRESC,
      type: 'error',
      timeout: 7000
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.bg-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  padding: 7px;
  background-color: $primary;
}

.table-grid /deep/ th {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 13px;
}

.badge-logo {
  font-size: $font-size-base;
  font-weight: normal;
  img {
    height: $font-size-base;
  }
}

.badge-dropbox {
  background-color: #0062FF;
  border-color: #0062FF;
}

.btn-row {
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;
}

</style>
