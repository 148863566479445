<template>
  <b-container class="mt-3">
    <b-card class="card-page" no-body>

      <b-card-body>
        <b-form @submit.prevent="publikuj" v-if="!publikacjaUdana">
          <p><strong>Nr Prawa Wykonywania Zawodu: {{ wizytowka.npwz }}</strong></p>

          <b-form-row>
            <b-col sm="8">
              <b-form-group label="Imię i nazwisko:"
                :invalid-feedback="errors.first('imieNazwisko')">
                <b-form-input name="imieNazwisko"
                  v-model="wizytowka.nazwa"
                  v-validate="'required'"
                  data-vv-as="imię i nazwisko"
                  :state="!this.errors.has('imieNazwisko')">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-group label="Specjalności:"
            :invalid-feedback="errors.first('specjalnosci')">
            <b-form-textarea name="specjalnosci"
              rows="3"
              v-model="wizytowka.specjalnosci"
              data-vv-as="specjalności"
              :state="!this.errors.has('specjalnosci')">
            </b-form-textarea>
          </b-form-group>

          <b-form-group label="Adresy:">
            <Adresy :adresy="wizytowka.adresy"></Adresy>
          </b-form-group>

          <b-form-row>
            <b-col md="5">
              <b-form-group label="Telefon:"
                :invalid-feedback="errors.first('telefon')">
                <b-form-input name="telefon"
                  v-model="wizytowka.telefon"
                  :state="!this.errors.has('telefon')">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="E-mail:"
                :invalid-feedback="errors.first('email')">
                <b-form-input name="email"
                  v-model="wizytowka.email"
                  v-validate="'email'"
                  :state="!this.errors.has('email')">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-group label="Opis:"
            :invalid-feedback="errors.first('opis')">
            <b-form-textarea name="opis"
              rows="5"
              v-model="wizytowka.opis"
              :state="!this.errors.has('opis')">
            </b-form-textarea>
          </b-form-group>

          <b-form-group label="Szablon:"
            :invalid-feedback="errors.first('szablon')">
            <b-form-radio-group class="radio-preview"
              v-model="wizytowka.szablon"
              name="szablon"
              v-validate="'required'">
              <b-form-radio v-for="(szablon, index) in szablony"
                :key="index"
                :value="szablon.kod">
                <b-img fluid rounded alt="szablon.kod"
                  :src="szablon.miniatura"
                  v-b-tooltip="szablon.opis" />
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

          <p class="text-center">
            <b-btn
              variant="info"
              target="_blank"
              size="lg"
              :href="'/api/wizytowki/podglad?w=' + wizytowkaBase64()">
                Podgląd
            </b-btn>
            <b-btn type="submit" variant="primary" size="lg">Publikuj</b-btn>
          </p>
        </b-form>
        <div v-else>
          <b-row align-v="center">
          <b-col cols="2" class="text-center">
            <icon name="check-circle" scale="3" class="text-primary"></icon>
          </b-col>
          <b-col cols="10">
            <h4>Twoja wizytówka jest już dostępna w sieci!</h4>
          </b-col>
          </b-row>
          <p class="my-4">Możesz ją zobaczyć pod adresem: <a :href="adresWizytowki" target="_blank">{{ adresWizytowki }}</a>
          </p>
          <div class="mt-4">
            <b-btn variant="primary" size="lg" :to="{name: 'Wyszukiwanie'}">Zamknij</b-btn>
          </div>
        </div>
      </b-card-body>

    </b-card>
  </b-container>
</template>

<script>
import { Base64 } from 'js-base64'
import { mapState, mapGetters } from 'vuex'
import Wizytowki from '@/services/Wizytowki'
import Adresy from './Adresy'

export default {
  name: 'wizytowka',
  components: { Adresy },
  data () {
    return {
      szablony: [],
      wizytowka: {
        npwz: null,
        nazwa: null,
        specjalnosci: null,
        telefon: null,
        email: null,
        adresy: [],
        opis: null,
        szablon: null
      },
      publikacjaUdana: false,
      adresWizytowki: ''
    }
  },
  created () {
    this.wczytajMojaWizytowke()
    this.pobierzSzablony()
  },
  computed: {
    ...mapState({
      daneLekarza (state) {
        return state.profilLekarza.daneLekarza
      },
      miejscaPracy (state) {
        return state.miejscaPracy
      }
    }),
    ...mapGetters([
      'imieNazwiskoLekarza'
    ])
  },
  methods: {
    wczytajMojaWizytowke () {
      Wizytowki.moja().then(wizytowka => {
        this.wizytowka = wizytowka
      }, blad => {
        if (blad.status === 404) {
          this.podpowiedzDaneLekarza()
        }
      })
    },
    podpowiedzDaneLekarza () {
      this.wizytowka.npwz = this.daneLekarza.nrPrawaWykonywaniaZawodu
      this.wizytowka.nazwa = this.imieNazwiskoLekarza
      this.daneLekarza.specjalizacje.forEach(s => { this.wizytowka.specjalnosci = s.nazwa + '\n' })
      this.miejscaPracy.forEach(m => { this.wizytowka.adresy.push(m.placowkaLecznicza.adres) })
    },
    pobierzSzablony () {
      Wizytowki.dostepneSzablony().then(szablony => {
        this.szablony = szablony.map((szablon) => {
          szablon.miniatura = `/wizytowka_${szablon.kod}.jpg`
          return szablon
        })
        this.wizytowka.szablon = this.szablony[0].kod
      })
    },
    publikuj () {
      this.$validator.validateAll().then((result) => {
        if (!result) { return }
        Wizytowki.publikuj(this.wizytowka).then(adres => {
          this.adresWizytowki = adres
          this.publikacjaUdana = true
        })
      })
    },
    wizytowkaBase64 () {
      return Base64.encodeURI(JSON.stringify(this.wizytowka))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

@media (max-width: $md) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.card-page {
  max-width: $md;
}

.radio-preview {
  /deep/ .custom-control-inline {
    margin-bottom: 1rem;
  }

  /deep/ .custom-control-label::before {
    top: 40%;
  }

  /deep/ .custom-control-label::after {
    top: 40%;
  }
}
</style>
