import Vue from 'vue'
import Utils from './Utils'

export default {

  pobierzCertyfikaty (skad) {
    return Vue.http.get('certyfikaty-zus', { params: { skad: skad } }).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  zalogujDoZus (c, uuidPolecenia) {
    var reqParams = null
    if (uuidPolecenia) {
      reqParams = { uuidPolecenia: uuidPolecenia }
    }
    return Vue.http.post('certyfikaty-zus', c, { params: reqParams }).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  usun (uuidCertyfikatu) {
    return Vue.http.delete('certyfikaty-zus/' + uuidCertyfikatu).then(response => {
      return response
    }, Utils.przechwycBlad)
  }
}
