<template>
  <b-container ref="samaGora" class="container-search">

    <b-alert class="bledy-zus" variant="danger" dismissible :show="bledyWalidacjiZus.length > 0">
      <h3>Anulowanie eZLA się nie powiodło...</h3>
      <p>Wysłaliśmy do ZUS prośbę o anulowanie wskazanego eZLA, jednak jego system informatyczny ją odrzucił. Oto co nam odpowiedział:</p>
      <ul v-for="(b, index) in bledyWalidacjiZus" :key="index">
        <li>{{b}}</li>
      </ul>
      <p>Mamy nadzieję, że powyższe komunikaty pomogą Ci zmienić co trzeba i następnym razem się uda!</p>
    </b-alert>

    <b-card class="card-page">

      <!-- kryteria wyszukiwania -->
      <b-btn size="sm" class="mb-3" @click="pokazKryteria = !pokazKryteria">
        <icon name="sliders" scale="0.8" class="icon-criteria"></icon>
        Wprowadź kryteria wyszukiwania
      </b-btn>
      <b-collapse id="kryteria" v-model="pokazKryteria">
        <b-form>

          <b-form-row>
            <b-col md="5">
              <b-form-group>
                <b-form-input type="text"
                              id="ubezpieczony"
                              v-model="peselLubNazwisko"
                              placeholder="PESEL lub nazwisko ubezpieczonego">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group>
                <b-form-input type="text"
                              id="platnik"
                              v-model="nipLubNazwaPlatnika"
                              placeholder="NIP lub nazwa płatnika">
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>

          <b-form-group>
            <label>Wystawione:</label>
            <b-form-radio-group v-model="kiedy">
              <b-form-radio value="DZIS">dziś</b-form-radio>
              <b-form-radio value="WCZORAJ">wczoraj</b-form-radio>
              <b-form-radio value="OSTATNI_TYDZIEN">w ostatnim tygodniu</b-form-radio>
              <b-form-radio value="OSTATNI_MIESIAC">w ostatnim miesiącu</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group>
            <b-button v-on:click.prevent="wyszukaj" variant="primary">
              <icon name="search" scale="0.8" class="text-light"></icon>
              Szukaj
            </b-button>
          </b-form-group>
        </b-form>
      </b-collapse>

      <b-container v-if="wyszukiwanie">
        <b-row align-h="center" class="text-center" style="margin-top: 20px">
          <b-col>
            <p class="lead">Zaczekaj chwileczkę, wyszukujemy ZLA...</p>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="10">
            <b-progress id="postep" height="2rem" :value="1" :max="1" variant="info" striped animated></b-progress>
          </b-col>
        </b-row>
      </b-container>

      <!-- grid -->
      <b-table v-if="!wyszukiwanie" class="table-grid" stacked="lg" hover
        :items="wystawioneZwolnienia"
        :fields="naglowek"
        :show-empty="true"
        empty-text="Nie znaleziono zwolnień dla wprowadzonych kryteriów wyszukiwania.">
        <template slot="zla" slot-scope="data">
          <span class="no-wrap">{{ data.item.seria }} {{ data.item.numer }}</span><br>
          <span class="no-wrap">{{ data.item.dataWystawienia | formatujDate }}</span>
        </template>
        <template slot="ubezpieczony" slot-scope="data">
          <span class="no-wrap">{{ data.item.ubezpieczony.imie }} {{ data.item.ubezpieczony.nazwisko }}</span><br>
          <span class="text-muted">{{ data.item.ubezpieczony.pesel }}</span>
        </template>
        <template slot="platnik" slot-scope="data">
          <template v-if="data.item.platnik.instytucja">
            {{ data.item.platnik.instytucja.nazwa }} <br>
            <span class="text-muted">{{ data.item.platnik.instytucja.nip }}</span>
          </template>
          <template v-else>
            <span class="no-wrap">{{ data.item.platnik.osoba.imie }} {{ data.item.platnik.osoba.nazwisko }}</span><br>
            <span class="text-muted">{{ data.item.platnik.osoba.pesel }}</span>
          </template>
        </template>
        <template slot="niezdolnoscDoPracy" slot-scope="data">
          <span>{{ data.item.niezdolnoscDoPracy.dataOd | formatujDate }} &rarr; {{ data.item.niezdolnoscDoPracy.dataDo | formatujDate }}</span>
        </template>
        <template slot="opcje" slot-scope="data">
          <b-btn :href="'/api/ezla/' + data.item.uuid"
            target="_blank"
            variant="link"
            size="sm"
            class="btn-row" :disabled="data.item.uuid === null">
            Pobierz PDF
          </b-btn>
          <span class="text-muted" v-if="data.item.moznaAnulowac">|</span>
          <b-btn v-if="data.item.moznaAnulowac"
            v-on:click="rozpocznijAnulowanie(data.item)"
            :disabled="data.item.uuid === null"
            variant="link"
            size="sm"
            class="btn-row text-danger">
            Anuluj
          </b-btn>
          <span class="text-muted">|</span>
          <b-btn variant="link"
            @click.stop="data.toggleDetails"
            size="sm"
            class="btn-row">
            Szczegóły
          </b-btn>
        </template>
        <template slot="row-details" slot-scope="row">
          <b-card v-if="maPremium">
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>ICD 10:</b></b-col>
              <b-col>{{ formatujOpcjonalnyTekst(row.item.niezdolnoscDoPracy.numerStatystycznyChoroby) }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Kody literowe:</b></b-col>
              <b-col>{{ formatujKodyLiterowe(row.item.niezdolnoscDoPracy.kodyLiterowe) }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Opieka nad:</b></b-col>
              <b-col>{{ formatujPodopiecznego(row.item.niezdolnoscDoPracy.podopieczny) }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Instytucja ubezpieczająca:</b></b-col>
              <b-col>{{ formatujInstytucjeUbezpieczajaca(row.item.ubezpieczony.instytucjaUbezpieczajaca) }}</b-col>
            </b-row>
          </b-card>
          <b-card v-else>
            <p>
              Szczegóły pojawią się po <b-link :to="{name: 'NowaLicencja'}">zakupie</b-link> wersji <b-badge variant="info">Premium</b-badge>
            </p>
          </b-card>
          <div class="text-center">
            <b-btn variant="link" size="sm" @click="row.toggleDetails">Ukryj</b-btn>
          </div>
        </template>
      </b-table>

    </b-card>

    <!-- modal anulowanie ezla -->
    <!-- no-enforce-focus https://github.com/bootstrap-vue/bootstrap-vue/issues/1908#issuecomment-438521566 -->
    <b-modal ref="modalCertyfikat"
      centered
      v-model="trwaAnulowanie"
      no-enforce-focus
      title="Czy na pewno chcesz anulować eZLA?"
      ok-title="Anuluj eZLA"
      ok-variant="danger"
      :ok-disabled="przetwarzanie"
      cancel-title="Zamknij"
      cancel-variant="outline-secondary"
      @shown="ustawFokusHaslo"
      @hidden="porzucAnulowanieEzla"
      @ok.prevent="anulujEzla">
      <b-form @submit.prevent="anulujEzla" @reset="porzucAnulowanieEzla">
        <b-form-group :invalid-feedback="errors.first('przyczynaAnulowania')"
            label="Wybierz przyczynę anulowania eZLA:">
          <b-form-select name="przyczynaAnulowania"
            v-model="kodPrzyczynyAnulowania"
            :options="slownikPrzyczynAnulowania"
            :select-size="slownikPrzyczynAnulowania.length"
            value-field="kod"
            text-field="nazwa"
            v-validate="'required'"
            data-vv-as="przyczyna anulowania"
            :state="!this.errors.has('przyczynaAnulowania')">
          </b-form-select>
        </b-form-group>
        <b-form-group v-if="!maPremium" :invalid-feedback="errors.first('haslo')"
          label="Hasło do certyfikatu ZUS:"
          label-for="inputHaslo">
          <b-form-input name="haslo"
            type="password"
            ref="haslo"
            placeholder="Hasło"
            v-model="haslo"
            v-validate="'required'"
            data-vv-as="hasło"
            :state="!this.errors.has('haslo')"></b-form-input>
        </b-form-group>
        <b-form-checkbox v-if="!maPremium" :disabled="true">
          Zapamiętaj hasło do certyfikatu
          <router-link :to="{name: 'PorownanieWersji'}">
            <b-badge variant="info">Premium</b-badge>
          </router-link>
        </b-form-checkbox>
      </b-form>
    </b-modal>

  </b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import Ezla from '@/services/Ezla'
import SlownikiZus from '@/services/SlownikiZus'
import * as Komunikaty from '@/constants/Komunikaty'
import * as KodyBledow from '@/constants/KodyBledow'

export default {
  name: 'wystawione-zwolnienia',
  data () {
    return {
      peselLubNazwisko: null,
      nipLubNazwaPlatnika: null,
      kiedy: 'DZIS',
      naglowek: {
        zla: {
          label: 'ZLA'
        },
        ubezpieczony: {
          label: 'Ubezpieczony'
        },
        platnik: {
          label: 'Płatnik'
        },
        niezdolnoscDoPracy: {
          label: 'Okres niezdolności do pracy'
        },
        opcje: {
          label: ' '
        }
      },
      opisyKodowPokrewienstwa: {
        'DZIECKO': 'dzieckiem',
        'OPIEKUN': 'podopiecznym',
        'INNA_OSOBA': 'osobą'
      },
      opisyInstytucjaUbezpieczajaca: {
        'ZUS': 'ZUS',
        'KRUS': 'KRUS',
        'INNY_PL': 'inna instytucja w Polsce',
        'INNY_POZA_PL': 'inne państwo'
      },
      haslo: '',
      wystawioneZwolnienia: [],
      ezlaDoAnulowania: null,
      kodPrzyczynyAnulowania: null,
      slownikPrzyczynAnulowania: [],
      bledyWalidacjiZus: [],
      pokazKryteria: false,
      przetwarzanie: false,
      wyszukiwanie: false
    }
  },
  mounted: function () {
    SlownikiZus.przyczynyAnulowaniaEzla().then(slownik => {
      this.slownikPrzyczynAnulowania = slownik
      this.kodPrzyczynyAnulowania = slownik[0].kod
    })

    this.wyszukaj()
  },
  computed: {
    ...mapState(['hasloDoCertyfikatu', 'idWystawcy']),
    ...mapGetters(['maPremium']),
    trwaAnulowanie: {
      get () {
        return this.ezlaDoAnulowania != null
      },
      set () {}
    }
  },
  methods: {
    czyNumer (fraza) {
      return /^\d*$/.test(fraza)
    },
    wyszukaj () {
      let nip = null
      let nazwaPlatnika = null
      let pesel = null
      let nazwisko = null
      if (this.czyNumer(this.nipLubNazwaPlatnika)) {
        nip = this.nipLubNazwaPlatnika
      } else {
        nazwaPlatnika = this.nipLubNazwaPlatnika
      }
      if (this.czyNumer(this.peselLubNazwisko)) {
        pesel = this.peselLubNazwisko
      } else {
        nazwisko = this.peselLubNazwisko
      }
      this.wyszukiwanie = true
      Ezla.wyszukaj(pesel, nazwisko, nip, nazwaPlatnika, this.kiedy).then(wystawioneZwolnienia => {
        this.wyszukiwanie = false
        this.wystawioneZwolnienia = wystawioneZwolnienia

        if (!wystawioneZwolnienia || wystawioneZwolnienia.length === 0) {
          this.pokazKryteria = true
        }
      }, () => {
        this.wyszukiwanie = false
      })
    },
    formatujDate (d) {
      return moment(d).locale('pl').format('DD MMMM YYYY')
    },
    formatujPlatnika (platnik) {
      if (platnik.instytucja) {
        return `${platnik.instytucja.nip} - ${platnik.instytucja.nazwa}`
      } else {
        return `${platnik.osoba.pesel} - ${platnik.osoba.imie} ${platnik.osoba.nazwisko}`
      }
    },
    formatujPodopiecznego (podopieczny) {
      if (!podopieczny) {
        return '-'
      }
      let podopiecznyStr = `${this.opisyKodowPokrewienstwa[podopieczny.kodPokrewienstwaOsobyPodOpieka]}, ur. ${this.formatujDate(podopieczny.dataUrodzeniaOsobyPodOpieka)}`
      return this.formatujOpcjonalnyTekst(podopiecznyStr)
    },
    formatujInstytucjeUbezpieczajaca (instytucja) {
      return this.formatujOpcjonalnyTekst(this.opisyInstytucjaUbezpieczajaca[instytucja])
    },
    formatujKodyLiterowe (kody) {
      if (!kody) {
        return 'brak'
      }

      return this.formatujOpcjonalnyTekst(kody.join(', '))
    },
    formatujOpcjonalnyTekst (tekst) {
      if (!tekst) {
        return 'brak'
      }

      return tekst
    },
    rozpocznijAnulowanie (ezla) {
      this.przetwarzanie = false
      this.ezlaDoAnulowania = ezla
    },
    ustawFokusHaslo () {
      if (!this.maPremium) {
        this.$nextTick(function () { this.$refs.haslo.focus() })
      }
    },
    anulujEzla (event) {
      this.$validator.validateAll().then((result) => {
        if (!result) { return }
        this.przetwarzanie = true
        let przyczynaAnulowania = this.slownikPrzyczynAnulowania.filter((p) => { return p.kod === this.kodPrzyczynyAnulowania })

        Ezla.anuluj(this.ezlaDoAnulowania, przyczynaAnulowania[0], this.zwrocHaslo(), this.idWystawcy).then(response => {
          this.porzucAnulowanieEzla()
          this.anulowanoEzla()
        }, (blad) => {
          this.przetwarzanie = false
          if (blad.body.kod === KodyBledow.ZLE_HASLO_DO_CERTYFIKATU) {
            this.niepoprawneHaslo()
          } else if (blad.body.kod === KodyBledow.BLAD_WALIDACJI_USLUGI_ZUS) {
            this.bledyWalidacjiZus = blad.body.opis
            this.$refs.samaGora.scrollIntoView({ behavior: 'smooth' })
            this.porzucAnulowanieEzla()
          } else {
            this.nieznanyBlad()
          }
        })
      })
    },
    porzucAnulowanieEzla () {
      this.ezlaDoAnulowania = null
      this.przyczynaAnulowania = ''
      this.haslo = null
    },
    zwrocHaslo: function () {
      if (this.maPremium) {
        return this.hasloDoCertyfikatu
      }

      return this.haslo
    }
  },
  notifications: {
    anulowanoEzla: {
      title: 'Udało się!',
      message: 'eZLA zostało anulowane w ZUS.',
      type: 'success',
      timeout: 3000
    },
    niepoprawneHaslo: {
      title: Komunikaty.ZLE_HASLO_LOGOWANIE.TYTUL,
      message: Komunikaty.ZLE_HASLO_LOGOWANIE.TRESC,
      type: 'error',
      timeout: 7000
    },
    nieznanyBlad: {
      title: Komunikaty.NIEZNANY_BLAD.TYTUL,
      message: Komunikaty.NIEZNANY_BLAD.TRESC,
      type: 'error',
      timeout: 7000
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.icon-criteria {
  color: #CCC;
}

.table-grid /deep/ th {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 13px;
}

.btn-row {
  padding-top: 0;
  padding-bottom: 0;
  white-space: nowrap;
}

.no-wrap {
  white-space: nowrap;
}

.bledy-zus {
  max-width: $md;
  margin: 20px auto 20px auto;
}

</style>
