import Vue from 'vue'

import pl from 'vee-validate/dist/locale/pl'
import VeeValidate, { Validator } from 'vee-validate'
import * as Walidator from 'validate-polish'

const config = {
  fieldsBagName: 'formFields' // domyślne 'fields' używane przez BTable
}

const notEmptyRule = {
  getMessage: (field, args) => `Przynajmniej jeden ${field} musi być wybrany.`,
  validate: function (value, args) {
    return value != null && value.length > 0
  }
}
Validator.extend('not_empty', notEmptyRule)

const peselRule = {
  getMessage: (field, args) => `Wprowadzony ${field} ma nieprawidłowy format.`,
  validate: function (value, args) {
    return Walidator.pesel(value)
  }
}
Validator.extend('pesel', peselRule)

const nipRule = {
  getMessage: (field, args) => `Wprowadzony ${field} ma nieprawidłowy format.`,
  validate: function (value, args) {
    return Walidator.nip(value)
  }
}
Validator.extend('nip', nipRule)

const emails = {
  getMessage: (field, args) => `Wprowadzony ${field} ma nieprawidłowy format.`,
  validate: function (value, args) {
    const list = value.replace('\n', ' ').split(' ')
      .filter((email) => { return !!email })
      .map((email) => { return email.trim() })

    const result = list.find((email) => {
      return Validator.rules.email.validate(email) === false
    })
    return !result
  }
}

const wymaganyKodPocztowy = {
  getMessage: (field) => `ZUS zwrócił adres ubezpieczonego bez wymaganego kodu pocztowego. Niestety musisz wprowadzić go ręcznie. Kliknij w symbol ołówka w rogu adresu.`,
  validate: (value, args) => {
    return value != null && value.kodPocztowy
  }
}

Vue.use(VeeValidate, config)

Validator.localize('pl', pl)
Validator.extend('emails', emails)
Validator.extend('wymaganyKodPocztowy', wymaganyKodPocztowy)
