import Vue from 'vue'
import Utils from './Utils'

export default {

  moja () {
    return Vue.http.get('wizytowki/moja').then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  publikuj (wizytowka) {
    return Vue.http.post('wizytowki', wizytowka).then(response => {
      return response.headers.get('Location')
    }, Utils.przechwycBlad)
  },

  dostepneSzablony () {
    return Vue.http.get('wizytowki/szablony').then(response => {
      return response.body
    }, Utils.przechwycBlad)
  }

}
