import { render, staticRenderFns } from "./PolecenieAplikacji.vue?vue&type=template&id=19905e94&scoped=true&"
import script from "./PolecenieAplikacji.vue?vue&type=script&lang=js&"
export * from "./PolecenieAplikacji.vue?vue&type=script&lang=js&"
import style0 from "./PolecenieAplikacji.vue?vue&type=style&index=0&id=19905e94&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19905e94",
  null
  
)

component.options.__file = "PolecenieAplikacji.vue"
export default component.exports