// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Ezla from './Ezla'
import router from './router'
import BootstrapVue from 'bootstrap-vue'

import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'nprogress/nprogress.css'
import './styles/styles.scss'

import store from './store'
import moment from 'moment'
import vSelect from 'vue-select'
import VueCountdown from '@xkeshi/vue-countdown'
import ToggleButton from 'vue-js-toggle-button'
import VueScrollTo from 'vue-scrollto'
import Meta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'

import './configuration/analytics'
import './configuration/facebook'
import './configuration/http'
import './configuration/kalendarz'
import './configuration/powiadomienia'
import './configuration/walidator'

Vue.use(BootstrapVue)
Vue.component('icon', Icon)
Vue.component('vSelect', vSelect)
Vue.component('countdown', VueCountdown)
Vue.use(ToggleButton)
Vue.use(VueScrollTo)
Vue.use(Meta)
Vue.use(VueClipboard)

Vue.filter('formatujDate', function (d) {
  return moment(d).locale('pl').format('DD MMMM YYYY')
})
Vue.filter('formatujDateZwiezle', function (d) {
  return moment(d).locale('pl').format('DD-MM-YYYY')
})
Vue.filter('formatujKwote', function (kwota) {
  if (typeof kwota !== 'number') {
    return kwota
  }
  var opcje = { style: 'currency', currency: 'PLN' }
  var formatKwoty = new Intl.NumberFormat('pl-PL', opcje)

  return formatKwoty.format(kwota / 100)
})
Vue.filter('formatujMiare', function (m) {
  if (m === 'MIESIAC') {
    return 'm-c'
  }
  if (m === 'SZTUKA') {
    return 'szt.'
  }

  return ''
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(Ezla),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#ezla')
