export const USTAW_WYSTAWCE = 'ustawWystawce'
export const USTAW_PROFIL_LEKARZA = 'ustawProfilLekarza'
export const ZAPAMIETAJ_MIEJSCA_PRACY = 'zapamietajMiejscaPracy'
export const USTAW_INSTYTUCJE_UBEZPIECZAJACA = 'ustawInstytucjeUbezpieczajaca'
export const USTAW_DOSTAWCE_PODPISU = 'ustawDostawcePodpisu'
export const PRZEKIERUJ_PO_ZALOGOWANIU = 'przekierujPoZalogowaniu'
export const PRZEKIERUJ_PO_ZGODACH = 'przekierujPoZgodach'
export const WYLOGUJ = 'wyloguj'
export const ZAPAMIETAJ_SLOWNIK_PRZYCZYN_ANULOWANIA_EZLA = 'zapamietajSlownikPrzyczynAnulowaniaEzla'
export const ZAPAMIETAJ_HASLO_DO_CERTYFIKATU_ZUS = 'zapamietajHasloDoCertyfikatuZus'
export const ODNOW_SESJE_ZUS = 'odnowSesjeZus'
export const ZAPAMIETAJ_UUID_POLECENIA = 'zapamietajUuidPolecenia'
export const ZAPAMIETAJ_WIADOMOSCI = 'zapamietajWiadomosci'
