import Vue from 'vue'

import flatPickr from 'vue-flatpickr-component'
import Polish from 'flatpickr/dist/l10n/pl'
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/airbnb.css'

Polish.pl.firstDayOfWeek = 1
Polish.pl.rangeSeparator = 'do'

Vue.use(flatPickr)
