<template>
  <b-container class="mt-3">
    <b-card class="card-page" no-body>
      <div class="card-body">
        <b-container v-if="platnoscBlad">
          <b-alert show variant="danger">Obsługa płatności za zakup licencji premium zakończyła się błędem. Sprobuj jeszcze raz.</b-alert>
        </b-container>
        <b-container class="mt-3" v-else-if="platnoscOk">
          <b-row align-v="center">
            <b-col cols="2" class="text-center">
              <icon name="check-circle" scale="5" class="text-primary"></icon>
            </b-col>
            <b-col cols="10">
              <h4>Gratulujemy zakupu!</h4>
              <p>Twoja licencja na użycie funkcji <b-badge variant="info">premium</b-badge> jest już aktywna. Teraz możesz wystawiać
                eZLA jeszcze szybciej i wygodniej!</p>
                <p>Plik PDF z wydrukiem faktury znajdziesz w zakładce <b-link :to="{name: 'Licencje'}">Licencje</b-link>.</p>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else>
          <b-row align-h="center">
            <b-col cols="7">
              <icon id="ikonaKomunikacja" name="hourglass-half" scale="2" style="float: left"></icon>
              <br/>
              Musimy poczekać na realizację transakcji, nie powinno to potrwać długo...
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-col cols="10">
              <b-progress id="postep" :value="1" :max="1" variant="success" striped animated></b-progress>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import Platnosci from '@/services/Platnosci'
import ProfileLekarzy from '@/services/ProfileLekarzy'

export default {
  name: 'obslugaTransakcji',
  data () {
    return {
      timer: null,
      platnoscOk: false,
      platnoscBlad: false
    }
  },
  computed: {
    idPlatnosci: function () {
      return this.$route.params.id
    }
  },
  mounted () {
    this.timer = setInterval(() => {
      Platnosci.sprawdzStatus(this.idPlatnosci).then(r => {
        if (r.status === 'OPLACONA') {
          this.czyscTimer()
          ProfileLekarzy.ustawProfil().then(r => {
            this.platnoscOk = true
          })
        } else if (r.status === 'BLAD') {
          this.czyscTimer()
          this.platnoscBlad = true
        }
      })
    }, 2000)
  },
  beforeDestroy () {
    this.czyscTimer()
  },
  methods: {
    czyscTimer: function () {
      clearInterval(this.timer)
      this.timer = null
    }
  }
}
</script>

<style>
  #postep {
    margin: 20px
  }
</style>
