import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as ZmianyStanu from './ZmianyStanu'
import * as DostawcyPodpisu from '@/constants/DostawcyPodpisu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    profilLekarza: null,
    miejscaPracy: [],
    idWystawcy: null,
    nazwaWystawcy: null,
    instytucjaUbezpieczajaca: 'ZUS',
    uuidCertyfikatuZus: null,
    hasloDoCertyfikatu: null,
    dostawcaPodpisu: DostawcyPodpisu.CERTYFIKAT_ZUS,
    przekierowanie: null,
    slownikPrzyczynAnulowaniaEzla: [],
    uuidPolecenia: null,
    wiadomosci: [],
    host: window && window.location && window.location.hostname
  },
  getters: {
    zalogowany: state => {
      return !!state.profilLekarza
    },
    imieNazwiskoLekarza: state => {
      if (state.profilLekarza) {
        return `${state.profilLekarza.daneLekarza.imie} ${state.profilLekarza.daneLekarza.nazwisko}`
      }

      return 'Użytkownik'
    },
    emailLekarza: state => {
      if (state.profilLekarza) {
        return state.profilLekarza.email
      }

      return ''
    },
    npwzLekarza: state => {
      if (state.profilLekarza) {
        return state.profilLekarza.daneLekarza.nrPrawaWykonywaniaZawodu
      }

      return ''
    },
    maWymaganeZgody: state => {
      return !!state.profilLekarza && state.profilLekarza.regulaminZaakceptowany
    },
    maPremium: state => {
      return !!state.profilLekarza && !!state.profilLekarza.wersjaProDo
    },
    nieprzeczytaneWiadomosci: state => {
      return state.wiadomosci.filter(w => { return w.dataPrzeczytania == null })
    },
    czyDemo: state => {
      return state.host === 'demo.ezlapka.pl'
    }
  },
  mutations: {
    [ZmianyStanu.USTAW_WYSTAWCE] (state, payload) {
      state.idWystawcy = payload.idWystawcy
      state.nazwaWystawcy = payload.nazwaWystawcy
    },
    [ZmianyStanu.ZAPAMIETAJ_MIEJSCA_PRACY] (state, payload) {
      state.miejscaPracy = payload.miejscaPracy
    },
    [ZmianyStanu.USTAW_INSTYTUCJE_UBEZPIECZAJACA] (state, payload) {
      state.instytucjaUbezpieczajaca = payload.instytucjaUbezpieczajaca
    },
    [ZmianyStanu.USTAW_DOSTAWCE_PODPISU] (state, payload) {
      state.dostawcaPodpisu = payload.dostawcaPodpisu
    },
    [ZmianyStanu.PRZEKIERUJ_PO_ZALOGOWANIU] (state, payload) {
      state.przekierowanie = payload.przekierowaniePoZalogowaniu
    },
    [ZmianyStanu.PRZEKIERUJ_PO_ZGODACH] (state, payload) {
      state.przekierowanie = payload.przekierowaniePoZgodach
    },
    [ZmianyStanu.WYLOGUJ] (state, payload) {
      state.idWystawcy = null
      state.nazwaWystawcy = null
      state.uuidCertyfikatuZus = null
      state.miejscaPracy = []
      state.profilLekarza = null
      state.przekierowanie = null
      state.hasloDoCertyfikatu = null
    },
    [ZmianyStanu.ZAPAMIETAJ_SLOWNIK_PRZYCZYN_ANULOWANIA_EZLA] (state, payload) {
      state.slownikPrzyczynAnulowaniaEzla = payload.slownik
    },
    [ZmianyStanu.USTAW_PROFIL_LEKARZA] (state, payload) {
      state.profilLekarza = payload.profil
    },
    [ZmianyStanu.ZAPAMIETAJ_HASLO_DO_CERTYFIKATU_ZUS] (state, payload) {
      state.hasloDoCertyfikatu = payload.haslo
    },
    [ZmianyStanu.ODNOW_SESJE_ZUS] (state, payload) {
      state.profilLekarza = null
      state.przekierowanie = payload.przekierowaniePoOdnowieniu
    },
    [ZmianyStanu.ZAPAMIETAJ_UUID_POLECENIA] (state, payload) {
      state.uuidPolecenia = payload.uuid
    },
    [ZmianyStanu.ZAPAMIETAJ_WIADOMOSCI] (state, payload) {
      state.wiadomosci = payload.wiadomosci
    }
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })]
})
