<template>
  <div id="landing">

    <!-- Navigation -->
    <b-navbar :class="navClass" ref="mainNav" v-if="czyStronaGlowna">
      <div class="container">
        <a class="navbar-brand" href="#" v-scroll-to="'#page-top'">
          <img class="logo-normal" src="./assets/logo_neg.svg" alt="eZLApka" height="40"/>
          <img class="logo-shrink" src="./assets/logo_sygnet.svg" alt="eZLApka" height="40"/>
        </a>
        <b-nav-toggle target="navbarResponsive"></b-nav-toggle>
        <b-collapse is-nav id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="#" v-scroll-to="'#oaplikacji'">O aplikacji</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" v-scroll-to="'#aktualnosci'">Aktualności</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" v-scroll-to="'#cennik'">Cennik</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" v-scroll-to="'#kontakt'">Kontakt</a>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'Logowanie'}" class="nav-link">
                Wystaw eZLA
              </router-link>
            </li>
          </ul>
        </b-collapse>
      </div>
    </b-navbar>
    <b-navbar v-else class="main-nav navbar navbar-expand-lg navbar-light fixed-top navbar-shrink">
      <div class="container">
        <router-link :to="{name: 'Glowna'}" class="navbar-brand">
          <img class="logo-shrink" src="./assets/logo_sygnet.svg" alt="eZLApka" height="40"/>
        </router-link>
        <b-nav-toggle target="navbarResponsive"></b-nav-toggle>
        <b-collapse is-nav id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link" href="/#oaplikacji">O aplikacji</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#aktualnosci">Aktualności</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#cennik">Cennik</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#kontakt">Kontakt</a>
            </li>
            <li class="nav-item">
              <router-link :to="{name: 'Logowanie'}" class="nav-link">
                Wystaw eZLA
              </router-link>
            </li>
          </ul>
        </b-collapse>
      </div>
    </b-navbar>

    <router-view></router-view>

    <!-- Site footer -->
    <b-container>
      <footer class="footer">
        <b-row>
          <b-col>
            <p>ezlapka.pl &copy; {{ rok }} <a href="https://nubisoft.pl" target="_blank">NubiSoft</a> Sp. z o.o.</p>
          </b-col>
          <b-col>
            <p class="text-right">
              <a href="https://www.facebook.com/ezlapka" class="ml-2"><icon name="facebook"></icon></a>
              <a href="https://www.twitter.com/ezlapka" class="ml-2"><icon name="twitter"></icon></a>
            </p>
          </b-col>
        </b-row>
      </footer>
    </b-container>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Landing',
  metaInfo: {
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} | eZLApka - łatwe eZLA!` : 'eZLApka - łatwe eZLA!'
    }
  },
  data () {
    return {
      navClass: {
        'main-nav': true,
        'navbar': true,
        'navbar-expand-lg': true,
        'navbar-light': true,
        'fixed-top': true,
        'navbar-shrink': false
      },
      rok: moment(new Date()).format('YYYY')
    }
  },
  computed: {
    czyStronaGlowna () {
      return this.$route.name === 'Glowna'
    }
  },
  methods: {
    handleScroll () {
      if (this.czyStronaGlowna) {
        let rect = this.$refs.mainNav.getBoundingClientRect()
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop
        if (rect.top + scrollTop > 100) {
          this.navClass['navbar-shrink'] = true
        } else {
          this.navClass['navbar-shrink'] = false
        }
      }
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="scss">
@import './styles/landing';
</style>
