<template>
  <transition name="slide-fade" mode="out-in">
    <div v-if="!trybEdycja">
      <!-- kafelki -->
      <div class="tiles-container">
        <Platnicy :platnicy="platnicyDoWyboru"
          :value="wybraniPlatnicy"
          data-vv-name="platnicy"
          data-vv-as="płatnik"
          v-validate="instytucjaUbezpieczajaca === 'ZUS' ? 'required' : ''"
          @input="wybranoPlatnikow"
          @edytuj="edytujPlatnika"
          @usun="usunPlatnika">
        </Platnicy>
      </div>

      <!-- przycisk dodawania -->
      <b-button v-if="platnicyDoWyboru.length > 0"
        class="btn-add"
        variant="light"
        @click="nowyPlatnik()"
        v-b-tooltip="'nowy płatnik'">
        <strong>+</strong>
      </b-button>
      <b-button v-if="platnicyDoWyboru.length === 0"
        variant="secondary"
        @click="nowyPlatnik()">
        zdefiniuj płatnika
      </b-button>

      <div v-if="this.errors.has('platnicy')"
        class="invalid-feedback d-block">
        {{ errors.first('platnicy') }}
      </div>
    </div>

    <!-- formularz -->
    <div v-if="trybEdycja">
      <PlatnikForm :platnik="kopiaPlatnika"
        @zapisz="zapisz"
        @cofnij="trybEdycja = false">
      </PlatnikForm>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'
import Platnicy from './Platnicy'
import PlatnikForm from './PlatnikForm'

export default {
  name: 'platnicy-ubezpieczonego',
  components: { Platnicy, PlatnikForm },
  props: ['platnicy', 'wybraniPlatnicy'],
  inject: ['$validator'],
  data () {
    return {
      trybEdycja: false,
      edytowanyPlatnik: null,
      platnicyDoWyboru: []
    }
  },
  computed: {
    ...mapState(['instytucjaUbezpieczajaca']),
    kopiaPlatnika () {
      return JSON.parse(JSON.stringify(this.edytowanyPlatnik))
    }
  },
  mounted () {
    this.platnicyDoWyboru = this.platnicy.map(p => {
      p.wydruk = !p.maPue
      return p
    })
    if (this.platnicyDoWyboru && this.wybraniPlatnicy == null) {
      this.wybranoPlatnikow(this.platnicyDoWyboru.slice())
    }
  },
  methods: {
    wybranoPlatnikow (platnicy) {
      this.$emit('update:wybraniPlatnicy', platnicy)
    },
    nowyPlatnik () {
      this.edytowanyPlatnik = { osoba: {}, instytucja: {}, nowy: true }
      this.trybEdycja = true
    },
    edytujPlatnika (p) {
      this.edytowanyPlatnik = p
      this.trybEdycja = true
    },
    usunPlatnika (p) {
      let index = this.platnicyDoWyboru.indexOf(p)
      if (index !== -1) {
        this.platnicyDoWyboru.splice(index, 1)
      }
      let indexWybranych = this.wybraniPlatnicy.indexOf(p)
      if (indexWybranych !== -1) {
        this.wybraniPlatnicy.splice(indexWybranych, 1)
      }
    },
    zapisz (p) {
      const index = this.platnicyDoWyboru.indexOf(this.edytowanyPlatnik)
      if (index !== -1) {
        this.platnicyDoWyboru[index] = p
        this.zaktualizujWybranychPlatnikow(this.edytowanyPlatnik, p)
      } else {
        this.platnicyDoWyboru.push(p)
        this.wybraniPlatnicy.push(p)
      }
      this.trybEdycja = false
    },
    zaktualizujWybranychPlatnikow (staryPlatnik, nowyPlatnik) {
      const index = this.wybraniPlatnicy.indexOf(staryPlatnik)
      if (index !== -1) {
        this.wybraniPlatnicy[index] = nowyPlatnik
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";

.tiles-container {
  display: inline-block;
  vertical-align: top;
}

.btn-add {
  margin-top: 0.25rem;
  margin-left: -4px;
  background-color: $input-bg;
  height: 80px;
}
</style>
