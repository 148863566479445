<template>
  <div></div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Ping from '../services/Ping'

export default {
  name: 'licznik-czasu-sesji',
  computed: {
    ...mapGetters([
      'maPremium'
    ]),
    ...mapState(['hasloDoCertyfikatu'])
  },
  created () {
    this.uruchomLicznik = () => {
      setTimeout(this.przedluzSesje, 15 * 60 * 1000)
    }
    this.przedluzSesje = () => {
      if (this.maPremium) {
        Ping.ping(this.hasloDoCertyfikatu)
        this.uruchomLicznik()
      }
    }
  },
  mounted () {
    if (this.maPremium) {
      this.uruchomLicznik()
    }
  }
}
</script>
