<template>
  <div class="container-codes text-center">
    <b-button-group size="sm">
      <b-button variant="light"
        v-for="kod in kodyPokrewienstwa"
        :key="kod"
        :pressed="value == kod"
        @click.stop="wybierz(kod)"
        v-b-tooltip.hover="opisy[kod]">
      <icon :name="ikony[kod]"></icon>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
export default {
  name: 'kod-pokrewienstwa',
  props: ['value'],
  inject: ['$validator'],
  data () {
    return {
      kodyPokrewienstwa: ['DZIECKO', 'OPIEKUN', 'INNA_OSOBA'],
      ikony: {
        'DZIECKO': 'child',
        'OPIEKUN': 'male',
        'INNA_OSOBA': 'group'
      },
      opisy: {
        'DZIECKO': 'dziecko',
        'OPIEKUN': 'małżonek, rodzice, teściowie, dziadkowie, wnuki, rodzeństwo, rodzic dziecka, ojczym, macocha',
        'INNA_OSOBA': 'inne osoby'
      },
      konfigDaty: {
        dateFormat: 'Y-m-d',
        locale: 'pl'
      }
    }
  },
  methods: {
    wybierz (kod) {
      this.$emit('input', kod)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.container-codes {
  /deep/ .btn {
    background-color: $input-bg;
    border-color: $input-bg;

    &:hover {
      background-color: $green-dark;
      border-color: $green-dark;
      color: white;
    }

    &.active {
      background-color: $green;
      border-color: $green;
      color: white;
    }
  }

  .form-control[readonly] {
    background-color: $input-bg;
  }
}
</style>
