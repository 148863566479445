<template>
  <b-container>
    <main role="main">

      <b-row>
        <b-col sm="8" offset-sm="2">
          <header class="text-center">
            <h3 class="my-4">Promocja dla uczestników XVIII Kongresu Medycyny Rodzinnej "Szlakiem Zdrowia" w Wiśle, 19-22 kwietnia 2018</h3>
            <hr class="my-4">
            <p class="text-muted my-4">www.kongres2018.pl</p>
          </header>

          <h4 class="text-center my-4">Darmowa, 3-miesięczna wersja <b-badge variant="info">Premium</b-badge> eZLApka</h4>

          <p>Aby uzyskać dostęp do darmowej wersji premium:</p>
          <ul>
            <li class="my-2">uzyskaj Certyfikat ZUS - <a target="_blank" href="http://www.zus.pl/o-zus/aktualnosci/-/publisher/aktualnosc/3/bezplatny-certyfikat-z-zus-nowe-rozwiazanie-dla-lekarzy-ktorzy-wystawiaja-elektroniczne-zwolnienia-lekarskie/1267817">http://www.zus.pl/o-zus/aktualnosci/-/publisher/akt...</a></li>
            <li class="my-2">zaloguj się certyfikatem ZUS do <a target="_blank" href="/app/logowanie">eZLApka</a></li>
            <li class="my-2">kliknij przycisk <a target="_blank" href="/app/kody-rabatowe">kody rabatowe</a> i wprowadź kod promocyjny</li>
          </ul>

          <div class="table-responsive-sm my-4">
            <table class="table">
              <tr>
                <th></th>
                <th class="text-center">Wersja darmowa</th>
                <th class="text-center">Wersja <b-badge variant="info">Premium</b-badge></th>
              </tr>
              <tr>
                <td>Obsługa certyfikatu ZUS</td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              </tr>
              <tr>
                <td>Wystawienie eZLA w trybie bieżącym</td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              </tr>
              <tr>
                <td>Wydruk eZLA</td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              </tr>
              <tr>
                <td>Anulowanie eZLA</td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              </tr>
              <tr>
                <td>Wizytówka</td>
                <td></td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              </tr>
              <tr>
                <td>Rozpoznawanie mowy *</td>
                <td></td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              </tr>
              <tr>
                <td>Zapamiętywanie hasła do certyfikatu ZUS</td>
                <td></td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              </tr>
              <tr>
                <td>Podtrzymywanie sesji z ZUS</td>
                <td></td>
                <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              </tr>
              <tfoot>
                <tr>
                  <td></td>
                  <td class="text-center">za darmo</td>
                  <td class="text-center">
                    0 zł <s>19,90 zł</s> miesięcznie
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </b-col>
      </b-row>

    </main>
    <div class="text-center my-4">
      <router-link :to="{ name: 'Glowna'}" class="text-muted">
        <small>&larr; wróć do strony głównej</small>
      </router-link>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'KodyRabatoweInstrukcja',
  metaInfo: {
    title: 'Kody Rabatowe',
    link: [{ rel: 'canonical', href: 'https://ezlapka.pl/kody_rabatowe' }],
    meta: [
      { 'property': 'og:title', 'content': 'Kody Rabatowe | eZLApka - łatwe eZLA!', 'vmid': 'og:title' },
      { 'property': 'og:url', 'content': 'https://ezlapka.pl/kody_rabatowe', 'vmid': 'og:url' }
    ]
  }
}
</script>

<style lang="scss" scoped>
header {
  margin-top: 6rem;
}
</style>
