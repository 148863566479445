<template>

  <b-container class="container-alert mt-3">
    <b-card class="card-page" no-body>
      <div class="card-body">
        <h4>Miejsca pracy</h4>
        <p>Wskaż miejsce pracy, w którym aktualnie jesteś. Umieść kursor na nazwą instytucji, aby zobaczyć jej adres.</p>
      </div>
      <b-list-group class="list-group-card" flush>
        <b-list-group-item v-for="(miejsce, index) in miejscaPracy" :key="index">
          <a href="#"
            @click="wybierz(miejsce)"
            v-b-tooltip
            :title="formatujAdres(miejsce.placowkaLecznicza.adres)">
            <icon name="hospital-o"></icon>
            {{ miejsce.placowkaLecznicza.instytucja.nazwa }}
          </a>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-container>

</template>

<script>
import MiejscaPracy from '../services/MiejscaPracy'
import * as ZmianyStanu from '../store/ZmianyStanu'
import Utils from '../services/Utils'

export default {
  name: 'miejscaPracy',
  data () {
    return {
      miejscaPracy: []
    }
  },
  created () {
    MiejscaPracy.pobierzMiejscaPracy().then(miejscaPracy => {
      this.$store.commit({
        type: ZmianyStanu.ZAPAMIETAJ_MIEJSCA_PRACY,
        miejscaPracy: miejscaPracy
      })
      if (miejscaPracy.length === 1) {
        this.wybierz(miejscaPracy[0])
      } else {
        this.miejscaPracy = miejscaPracy
      }
    })
  },
  methods: {
    wybierz (miejsce) {
      MiejscaPracy.wybierzDoKontekstu(miejsce).then(wystawca => {
        this.$store.commit({
          type: ZmianyStanu.USTAW_WYSTAWCE,
          idWystawcy: wystawca.idWystawcy,
          nazwaWystawcy: miejsce.placowkaLecznicza.nazwaSkrocona
        })
        this.$ga.event('miejsca pracy', 'wybrano', miejsce.placowkaLecznicza.nazwaSkrocona)
        this.$router.push({ name: 'StronaStartowa' })
      })
    },
    formatujAdres (adres) {
      return adres.miejscowosc + ', ' + Utils.sformatujUlice(adres)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.list-group-card {
  margin-top: -1.25rem;

  .list-group-item {
    padding: 0;
  }

  a {
    display: block;
    padding: 0.75rem 1.25rem;
    color: #495057;
  }

  a:hover {
    background: $body-bg;
    text-decoration: none;
  }
}
</style>
