<template>
  <div>
    <b-button-group size="sm" class="mb-2">
      <b-button variant="outline-primary"
        :pressed="instytucja"
        v-on:click="instytucja = true">
        instytucja
      </b-button>
      <b-button variant="outline-primary"
        :pressed="!instytucja"
        v-on:click="instytucja = false">
        osoba
      </b-button>
    </b-button-group>

    <b-form-row v-if="instytucja">
      <b-col sm="6">
        <b-form-group label="Nazwa:"
                      :invalid-feedback="errors.first('nazwa')">
          <b-form-input name="pNazwa"
                        v-model="platnik.instytucja.nazwa"
                        v-validate="'required'"
                        data-vv-as="nazwa"
                        data-vv-name="nazwa"
                        :state="!this.errors.has('nazwa')">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="6">
        <b-form-group label="NIP:"
                      :invalid-feedback="errors.first('nip')">
          <b-form-input name="pNip"
                        v-model="platnik.instytucja.nip"
                        v-validate="'required|nip'"
                        data-vv-as="NIP"
                        data-vv-name="nip"
                        :state="!this.errors.has('nip')">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div v-if="!instytucja">
      <b-form-row>
        <b-col sm="6">
          <b-form-group label="PESEL:"
                        :invalid-feedback="errors.first('pesel')">
            <b-form-input name="pPesel"
                          v-model="platnik.osoba.pesel"
                          v-validate="'required|pesel'"
                          data-vv-as="PESEL"
                          data-vv-name="pesel"
                          :state="!this.errors.has('pesel')">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Nr paszportu:">
            <b-form-input name="nrPaszportu"
                          v-model="platnik.osoba.nrPaszportu">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="6">
          <b-form-group label="Imię:"
                        :invalid-feedback="errors.first('imie')">
            <b-form-input name="pImie"
                          v-model="platnik.osoba.imie"
                          v-validate="'required'"
                          data-vv-as="imię"
                          data-vv-name="imie"
                          :state="!this.errors.has('imie')">
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Nazwisko:"
                        :invalid-feedback="errors.first('nazwisko')">
            <b-form-input name="pNazwisko"
                          v-model="platnik.osoba.nazwisko"
                          v-validate="'required'"
                          data-vv-as="nazwisko"
                          data-vv-name="nazwisko"
                          :state="!this.errors.has('nazwisko')">
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
    </div>

    <div class="text-right">
      <b-button class="mr-2"
        variant="primary"
        @click="zapisz()">
        zapisz
      </b-button>
      <b-link class="text-muted-light"
        @click="cofnij()">
        <icon name="undo" scale="0.7"></icon> cofnij
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'platnik-form',
  props: ['platnik'],
  inject: ['$validator'],
  data () {
    return {
      instytucja: true
    }
  },
  created () {
    const p = this.platnik
    if (p.osoba && (p.osoba.pesel || p.osoba.nrPaszportu)) {
      this.instytucja = false
      p.instytucja = {}
    } else {
      p.osoba = {}
    }
  },
  methods: {
    zapisz () {
      this.$validator.validateAll(['nazwa', 'nip', 'pesel', 'imie', 'nazwisko'])
        .then((result) => {
          if (!result) { return }

          if (this.instytucja) {
            this.platnik.osoba = null
          } else {
            this.platnik.instytucja = null
          }

          this.$emit('zapisz', this.platnik)
        })
    },
    cofnij () {
      this.$emit('cofnij')
    }
  }
}
</script>
