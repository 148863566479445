<template>
  <b-form-radio-group name="radioPodopieczni"
                      :checked="value">
    <div v-for="(podopieczny, index) in podopieczni"
      :key="index"
      :class="[{ 'radio-container-selected': podopieczny === value }, 'radio-container']"
      @click="aktualizuj(podopieczny)">
      <b-form-radio :value="podopieczny">
        {{ podopieczny.imie }} {{ podopieczny.nazwisko }}
        <br>
        <span :class="{'text-single': !podopieczny.nazwisko}">
          {{ podopieczny.dataUrodzenia | formatujDateUrodzenia }}
        </span>

        <KodPokrewienstwa
          v-model="podopieczny.kodPokrewienstwa"
          @input="zmienionoKod">
        </KodPokrewienstwa>
      </b-form-radio>

      <a class="icon icon-edit"
        @click.stop="edytuj(podopieczny)"
        v-b-tooltip="'edytuj podopiecznego'">
        <icon name="pencil" scale="0.8" class="fa-fw text-muted"></icon>
      </a>
      <a class="icon icon-delete"
        v-if="podopieczny.nowy"
        @click.stop="usun(podopieczny)"
        v-b-tooltip="'usuń podopiecznego'">
        <icon name="times" scale="0.8" class="fa-fw text-muted"></icon>
      </a>
    </div>
  </b-form-radio-group>
</template>

<script>
import moment from 'moment'
import KodPokrewienstwa from './KodPokrewienstwa'

export default {
  name: 'podopieczni',
  components: { KodPokrewienstwa },
  props: ['podopieczni', 'value'],
  methods: {
    aktualizuj (podopieczny) {
      if (podopieczny === this.value) {
        this.$emit('input', null)
      } else {
        this.$emit('input', podopieczny)
      }
    },
    edytuj (podopieczny) {
      this.$emit('edytuj', podopieczny)
    },
    usun (podopieczny) {
      this.$emit('usun', podopieczny)
    },
    zmienionoKod (kod) {
      // wymuszenie odświeżenia kafelki po zmianie kodu
      this.$forceUpdate()
    }
  },
  filters: {
    formatujDateUrodzenia (d) {
      return moment(d).locale('pl').format('DD.MM.YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-container .custom-control {
  padding-bottom: 0;
}

.text-single {
  position: relative;
  top: -11px;
}
</style>
