<template>
  <b-container class="mt-3">
    <b-card class="card-page" no-body>
      <div class="card-body">
        <b-alert variant="info" :show="!zalogowany">
          Jeśli jesteś zdecydowany na wersję <b-badge variant="info">Premium</b-badge>, przed zakupem będziesz
          musiał się zalogować używając certyfikatu ZUS.
        </b-alert>
        <div class="table-responsive-sm">
          <table class="table">
            <tr>
              <th></th>
              <th class="text-center">Wersja darmowa</th>
              <th class="text-center">Wersja <b-badge variant="info">Premium</b-badge></th>
            </tr>
            <tr>
              <td>Obsługa certyfikatu ZUS</td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tr>
              <td>Wystawienie eZLA w trybie bieżącym</td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tr>
              <td>Wydruk eZLA</td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tr>
              <td>Anulowanie eZLA</td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tr>
              <td>Wizytówka</td>
              <td></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tr>
              <td>Podgląd poprzednich zwolnień</td>
              <td></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tr>
              <td>Podpowiadania daty poprzedniego zwolnienia</td>
              <td></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tr>
              <td>Podpowiadania najczęściej używanych kodów chorób</td>
              <td></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tr>
              <td>Zapamiętywanie hasła do certyfikatu ZUS</td>
              <td></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tr>
              <td>Podtrzymywanie sesji z ZUS</td>
              <td></td>
              <td class="text-center"><icon name="check" class="text-primary"></icon></td>
            </tr>
            <tfoot>
              <tr>
                <td></td>
                <td class="text-center">za darmo</td>
                <td class="text-center">
                  Promocja 10zł za 3 miesiące<br>
                  <!-- 9,90 zł <s>19,90 zł</s> miesięcznie <br> -->
                  <b-btn variant="primary" to="/app/zakup-premium">Kup wersję Premium</b-btn>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <p class="my-4">
          Chcę wykorzystać <b-link :to="{name: 'KodyRabatowe'}">kod rabatowy</b-link>!
        </p>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'porownanie-wersji',
  computed: {
    ...mapGetters(['zalogowany'])
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

@media (max-width: $md) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.table th {
  border-top: none;
}
</style>
