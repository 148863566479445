<template>
  <b-container class="container-alert mt-3">

    <template v-if="co === 'logowanie_epuap'">

      <b-card class="card-page">
        <b-row align-v="center">
          <b-col cols="2" class="text-center">
            <icon name="check-circle" scale="3" class="text-primary"></icon>
          </b-col>
          <b-col cols="10">
            <h4>Logowanie Profilem Zaufanym ePUAP przebiegło pomyślnie!</h4>
          </b-col>
        </b-row>
        <p class="my-4">Niestety to dopiero połowa drogi. Teraz musimy zalogować Cię w systemie informatycznym ZUS.
          W tym celu musisz podpisać przygotowany przez nas plik na platformie ePUAP. Postępuj zgodnie
          z instrukcjami na ekranie.
        </p>
        <hr>
        <div>
          <b-btn variant="primary" size="lg" @click="zalogujWZus()">OK, idę dalej!</b-btn>
        </div>
      </b-card>

    </template>

    <template v-else-if="co === 'logowanie_zus'">

      <b-card class="card-page">
        <b-row align-v="center">
          <b-col cols="2" class="text-center">
            <icon name="check-circle" scale="3" class="text-primary"></icon>
          </b-col>
          <b-col cols="10">
            <h4>Logowanie do systemu informatycznego ZUS przebiegło pomyślnie!</h4>
          </b-col>
        </b-row>

        <countdown :leading-zero="false"
          :time="dismissSecs * 1000"
          @countdownend="naGlowna()">
          <template slot-scope="props">
            <p class="my-4">Zamkniemy to okno za {{props.seconds}} sekundy...</p>
            <b-progress variant="info" :max="dismissSecs" :value="props.seconds" height="4px"></b-progress>
          </template>
        </countdown>

        <div class="mt-4">
          <b-btn variant="primary" size="lg" @click="naGlowna()">Chodźmy od razu!</b-btn>
        </div>
      </b-card>

    </template>

    <template v-else-if="zalogowanoDoChmury">
       <b-card class="card-page">
        <b-row align-v="center">
          <b-col cols="2" class="text-center">
            <icon name="check-circle" scale="3" class="text-primary"></icon>
          </b-col>
          <b-col cols="10">
             <h4 v-if="co === 'google'">Logowanie do konta Google przebiegło pomyślnie!</h4>
             <h4 v-else-if="co === 'dropbox'">Logowanie w Dropbox przebiegło pomyślnie!</h4>
             <h4 v-else-if="co === 'onedrive'">Logowanie w OneDrive przebiegło pomyślnie!</h4>
          </b-col>
        </b-row>
        <p>Niestety to dopiero połowa drogi. Teraz musimy zalogować Cię w systemie informatycznym ZUS.
          W tym celu musisz podpisać przygotowany przez nas plik certyfikatem otrzymanym z ZUS.
        </p>
        <b-form @submit="podpiszOswiadczenieCertyfikatemZus">
          <b-form-group id="exampleInputGroup1"
                    label="Hasło do certyfikatu:"
                    label-for="haslo"
                    description="Podaj hasło do certyfikatu otrzymanego z ZUS.">
            <b-form-input id="haslo"
                      ref="kontrolkaHasla"
                      type="password"
                      v-model="haslo"
                      required
                      placeholder="Hasło">
            </b-form-input>
          </b-form-group>
          <b-button type="submit" variant="primary">Podpisz</b-button>
        </b-form>
       </b-card>
    </template>

    <template v-else>
      <h3>Sukces, ale nie wiadomo jaki...</h3>
    </template>

  </b-container>
</template>

<script>
import LogowanieDoZus from '../services/LogowanieDoZus'
import ProfileLekarzy from '../services/ProfileLekarzy'
import * as KodyBledow from '../constants/KodyBledow'
import * as Komunikaty from '../constants/Komunikaty'

export default {
  name: 'sukces',
  data () {
    return {
      dismissSecs: 10,
      haslo: null
    }
  },
  mounted: function () {
    if (this.co === 'logowanie_zus') {
      ProfileLekarzy.ustawProfil()
    } else if (this.zalogowanoDoChmury) {
      this.$nextTick(function () { this.$refs.kontrolkaHasla.focus() })
    }
  },
  computed: {
    co: function () {
      return this.$route.params.co
    },
    zalogowanoDoChmury: function () {
      let co = this.$route.params.co
      return co === 'google' || co === 'dropbox' || co === 'onedrive'
    }
  },
  methods: {
    naGlowna () {
      this.$router.replace({ name: 'StronaStartowa' })
    },
    zalogujWZus () {
      LogowanieDoZus.podpiszOswiadczenieEpuapem().then(response => {
        window.location.assign(response.url)
      })
    },
    podpiszOswiadczenieCertyfikatemZus (e) {
      e.preventDefault()
      LogowanieDoZus.podpiszOswiadczenieCertyfikatemZus(null, this.haslo).then(response => {
        ProfileLekarzy.ustawProfil().then(r => {
          this.naGlowna()
        })
      }, blad => {
        if (blad.body.kod === KodyBledow.ZLE_HASLO_DO_CERTYFIKATU) {
          this.niepoprawneHaslo()
        } else {
          this.bladLadowaniaCertyfikatu()
        }
      })
    }
  },
  notifications: {
    niepoprawneHaslo: {
      title: Komunikaty.ZLE_HASLO_LOGOWANIE.TYTUL,
      message: Komunikaty.ZLE_HASLO_LOGOWANIE.TRESC,
      type: 'error',
      timeout: 7000
    },
    bladLadowaniaCertyfikatu: {
      title: Komunikaty.BLAD_CERTYFIKATU.TYTUL,
      message: Komunikaty.BLAD_CERTYFIKATU.TRESC,
      type: 'error',
      timeout: 7000
    }
  }
}
</script>
