import store from '@/store'
import * as ZmianyStanu from '@/store/ZmianyStanu'
import router from '@/router'
import toast from '@/configuration/powiadomienia'
import * as Komunikaty from '@/constants/Komunikaty'

const czyDev = () => {
  return process.env.NODE_ENV === 'development'
}

const przechwycBlad = (response) => {
  // sesja wygasła?
  if (response.status === 403) {
    store.commit({ type: ZmianyStanu.WYLOGUJ })
    location.reload()
  }

  // wygasła sesja ZUS
  if (response.status === 409) {
    store.commit({
      type: ZmianyStanu.ODNOW_SESJE_ZUS,
      przekierowaniePoOdnowieniu: router.currentRoute.fullPath
    })

    toast({
      title: Komunikaty.WYGASLA_SESJA_ZUS.TYTUL,
      message: Komunikaty.WYGASLA_SESJA_ZUS.TRESC,
      type: 'warn',
      timeout: 7000
    })

    router.push({ name: 'Logowanie' })
  }

  // błąd ZUS
  if (response.status === 500 && response.data.kod === 'BLAD_API_USLUGI_ZUS') {
    toast({
      title: Komunikaty.BLAD_ZUS.TYTUL,
      message: Komunikaty.BLAD_ZUS.TRESC,
      type: 'warn',
      timeout: 7000
    })
  }

  if (czyDev()) {
    // eslint-disable-next-line
    console.warn(response)
  }
  return Promise.reject(response)
}

const sformatujUlice = (adres) => {
  let str = adres.ulica ? `${adres.ulica}` : (adres.poczta ? `${adres.poczta}` : '')
  str += ` ${adres.nrDomu}`
  str += adres.nrMieszkania ? `/${adres.nrMieszkania}` : ''

  return str
}

export default { czyDev, przechwycBlad, sformatujUlice }
