<template>
  <span>
    <b-badge id="bagdePoprzednie"
            href="#"
            class="ml-2"
            variant="light"
            :disabled="popupWidoczny"
            @click="wczytajPoprzednieZwolnienia">
      <icon name="book" scale="1.5" class="text-muted" v-b-tooltip="'poprzednie zwolnienia'"></icon>
    </b-badge>
    <b-popover target="bagdePoprzednie"
               placement="auto"
               triggers="click"
               :show.sync="popupWidoczny">
      <template slot="title">
        &nbsp;
        <div class="float-right" v-b-tooltip.left="'Zamknij'">
          <b-link @click="popupWidoczny=false"><icon name="times-circle" scale="1.5" class="text-muted-light"></icon></b-link>
        </div>
      </template>
      <b-container v-if="maPremium">
        <p v-if="pobieranie" class="text-muted">Pobieramy dane z ZUS...</p>
        <div v-if="!pobieranie && odpytanoZus">
          <b-table
            class="table-grid"
            stacked="lg"
            hover
            :per-page="ileNaStronie"
            :current-page="biezacaStrona"
            :items="poprzednieZwolnienia"
            :fields="naglowek"
            :show-empty="true"
            empty-text="Brak poprzednich zwolnień.">
            <template slot="niezdolnoscDoPracy" slot-scope="data">
              <span>{{ data.item.niezdolnoscDoPracy.dataOd | formatujDateZwiezle }} &rarr; {{ data.item.niezdolnoscDoPracy.dataDo | formatujDateZwiezle }}</span>
            </template>
            <template slot="rozpoznanie" slot-scope="data">
              <span>{{data.item.niezdolnoscDoPracy.numerStatystycznyChoroby}}</span>
            </template>
          </b-table>
          <b-pagination
              v-if="!pobieranie && odpytanoZus && poprzednieZwolnienia.length > ileNaStronie"
              size="sm"
              align="center"
              hide-goto-end-buttons
              v-model="biezacaStrona"
              :total-rows="poprzednieZwolnienia.length"
              :per-page="ileNaStronie">
          </b-pagination>
        </div>
      </b-container>
      <b-container v-else>
        <p>Ta funkcja dostępna jest tylko w wersji <b-badge variant="info">Premium</b-badge>.</p>
        <p>
          <b-link :to="{name: 'NowaLicencja'}">Chcę kupić licencję</b-link>
        </p>
      </b-container>
    </b-popover>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import Ezla from '../../services/Ezla'

export default {
  name: 'poprzednie-zwolnienia',
  props: ['pesel'],
  data () {
    return {
      ileNaStronie: 5,
      biezacaStrona: 1,
      popupWidoczny: false,
      pobieranie: false,
      odpytanoZus: false,
      poprzednieZwolnienia: [],
      naglowek: {
        niezdolnoscDoPracy: {
          label: 'Okres niezdolności do pracy'
        },
        rozpoznanie: {
          label: 'ICD-10'
        }
      }
    }
  },
  computed: {
    ...mapGetters(['maPremium'])
  },
  methods: {
    wczytajPoprzednieZwolnienia () {
      this.pokaz = true
      if (!this.odpytanoZus) {
        this.pobieranie = true
        Ezla.wyszukajObowiazujaceUbezpieczonego(this.pesel, null).then(zwolnienia => {
          this.poprzednieZwolnienia = zwolnienia
          this.pobieranie = false
        },
        e => {
          this.pobieranie = false
        })
        this.odpytanoZus = true
      }
    },
    onClose () {
      this.popupWidoczny = false
    }
  }
}
</script>

<style>

</style>
