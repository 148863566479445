<template>
  <div>
    <b-form-group :state="!errors.has('kodChoroby')"
                  :invalid-feedback="errors.first('kodChoroby')">

      <b-card v-if="wybranaChoroba"
        class="card-form"
        @click="przelaczWyszukiwarke">
        <big><b-badge variant="primary">{{ wybranaChoroba.kod }}</b-badge></big>
        {{ wybranaChoroba.nazwa }}
      </b-card>

      <b-collapse id="kodChorobyCollapse"
        class="collapse-search"
        v-model="pokazWyszukiwarke"
        @shown="pokazanoWyszukiwarke">
        <v-select
          :on-search="wyszukaj"
          :options="choroby"
          :clear-search-on-select="false"
          :value.sync="wybranaChoroba"
          :on-change="wybrano"
          v-validate="`${wymagajKodu ? 'required' : ''}`"
          data-vv-name="kodChoroby"
          data-vv-as="kod choroby"
          data-vv-validate-on="onChange"
          placeholder="Wprowadź kod ICD-10 lub nazwę choroby"
          ref="kodChorobyWyszukiwarka">
          <template slot="option" slot-scope="option">
            <strong>{{ option.kod }}&nbsp;</strong>
            <i>{{ option.nazwa }}</i>
          </template>
        </v-select>
      </b-collapse>
      <div v-if="maPremium">
        Często używane:
        <b-btn-group v-for="(choroba, index) in ostatnioUzyte" :key="index">
          <b-button v-b-tooltip.hover.bottom :title="choroba.nazwa" variant="link" @click="wybranoSkrot(choroba)">
            <b-badge variant="primary">{{ choroba.kod }}</b-badge>
          </b-button>
        </b-btn-group>
      </div>
      <p v-else>
        Ostatnio użyte kody pojawią się po <b-link :to="{name: 'NowaLicencja'}">zakupie</b-link> wersji <b-badge variant="info">Premium</b-badge>
      </p>
    </b-form-group>
  </div>
</template>

<script>
import Podpowiadaczki from '../../services/Podpowiadaczki'
import debounce from 'debounce'
import { mapGetters } from 'vuex'

export default {
  name: 'kod-choroby',
  props: ['kodChoroby', 'wymagajKodu'],
  inject: ['$validator'],
  data () {
    return {
      choroby: [],
      fraza: '',
      wybranaChoroba: null,
      pokazWyszukiwarke: false,
      ostatnioUzyte: []
    }
  },
  computed: {
    ...mapGetters(['maPremium'])
  },
  created () {
    this.wyszukaj = debounce(this.wyszukaj, 300)

    this.budujOpisChoroby = (c) => {
      c.label = `${c.kod} - ${c.nazwa}`
      return c
    }

    if (!this.wybranaChoroba) {
      this.pokazWyszukiwarke = true
    }
  },
  mounted () {
    this.$refs.kodChorobyWyszukiwarka.$refs.search.focus()
    if (this.maPremium) {
      Podpowiadaczki.podpowiedzOstatnioUzyteKodyChorob().then(choroby => {
        this.ostatnioUzyte = choroby
      })
    }
  },
  watch: {
    kodChoroby (newValue) {
      if (newValue === null) {
        this.wybranaChoroba = null
        this.pokazWyszukiwarke = true
        return
      }
      Podpowiadaczki.podpowiedzChoroby(newValue).then(choroby => {
        if (choroby && choroby.length > 0) {
          this.wybranaChoroba = this.budujOpisChoroby(choroby[0])
        }
      })
    }
  },
  methods: {
    wyszukaj (search, loading) {
      loading(true)
      Podpowiadaczki.podpowiedzChoroby(search).then(podpowiedzi => {
        this.choroby = podpowiedzi.map(this.budujOpisChoroby)
        if (podpowiedzi.length === 1) {
          this.wybrano(podpowiedzi[0])
        }
        loading(false)
      })
    },
    wybranoSkrot (choroba) {
      this.$ga.event('kod choroby', 'skrót')
      this.wybrano(choroba)
    },
    wybrano (choroba) {
      this.wybranaChoroba = choroba
      this.pokazWyszukiwarke = false
      this.$validator.validate('kodChoroby')

      const nowyKod = choroba ? choroba.kod : null
      if (this.kodChoroby !== nowyKod) {
        this.$emit('update:kodChoroby', nowyKod)
      }
    },
    przelaczWyszukiwarke () {
      this.pokazWyszukiwarke = !this.pokazWyszukiwarke
    },
    pokazanoWyszukiwarke () {
      let input = this.$refs.kodChorobyWyszukiwarka.$el.querySelector("input[type='search']")
      input.focus()
    }
  }
}
</script>
