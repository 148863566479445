import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import * as ZmianyStanu from '@/store/ZmianyStanu'
import Logowanie from '@/components/Logowanie'
import StronaStartowa from '@/components/StronaStartowa'
import Wyszukiwanie from '@/components/Wyszukiwanie'
import NoweEzla from '@/components/ezla/NoweEzla'
import NoweEzlaSukces from '@/components/ezla/NoweEzlaSukces'
import PrzekierowaniePodpisEpuap from '@/components/ezla/PrzekierowaniePodpisEpuap'
import Sukces from '@/components/Sukces'
import WystawioneZwolnienia from '@/components/ezla/WystawioneZwolnienia'
import MiejscaPracy from '@/components/MiejscaPracy'
import CertyfikatyZus from '@/components/CertyfikatyZus'
import App from '../App'
import Landing from '../Landing'
import Glowna from '@/components/landing/Glowna'
import Demo from '@/components/landing/Demo'
import KodyRabatoweInstrukcja from '@/components/landing/KodyRabatoweInstrukcja'
import ProfilLekarza from '@/components/profil/ProfilLekarza'
import Licencje from '@/components/profil/Licencje'
import Wizytowka from '@/components/profil/Wizytowka'
import Oswiadczenia from '@/components/Oswiadczenia'
import PorownanieWersji from '@/components/premium/PorownanieWersji'
import NowaLicencja from '@/components/premium/NowaLicencja'
import ObslugaPlatnosci from '@/components/ObslugaPlatnosci'
import WejscieZPolecenia from '@/components/WejscieZPolecenia'
import PolecenieAplikacji from '@/components/PolecenieAplikacji'
import Wiadomosci from '@/components/profil/Wiadomosci'
import KodyRabatowe from '@/components/premium/KodyRabatowe'
import WydrukFormularzyZla from '@/components/ezla/WydrukFormularzyZla'
import Utils from '../services/Utils'

Vue.use(Router)

const routes = [
  {
    path: '/app',
    name: 'App',
    component: App,
    redirect: '/app/logowanie',
    children: [
      {
        path: '/app/start',
        name: 'StronaStartowa',
        component: StronaStartowa,
        meta: {
          zalogowany: true
        }
      },
      {
        path: '/app/wyszukiwanie',
        name: 'Wyszukiwanie',
        component: Wyszukiwanie,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true
        }
      },
      {
        path: '/app/nowe-ezla/sukces',
        name: 'NoweEzlaSukces',
        component: NoweEzlaSukces,
        meta: { zalogowany: true }
      },
      {
        path: '/app/nowe-ezla/:pesel',
        name: 'NoweEzla',
        component: NoweEzla,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true
        }
      },
      {
        path: '/app/podpis/epuap',
        name: 'PrzekierowaniePodpisEpuap',
        component: PrzekierowaniePodpisEpuap,
        meta: { zalogowany: true }
      },
      {
        path: '/app/wystawione-zwolnienia',
        name: 'WystawioneZwolnienia',
        component: WystawioneZwolnienia,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true
        }
      },
      {
        path: '/app/miejsca-pracy',
        name: 'MiejscaPracy',
        component: MiejscaPracy,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true
        }
      },
      {
        path: '/app/certyfikaty-zus/:skad',
        name: 'CertyfikatyZus',
        component: CertyfikatyZus
      },
      {
        path: '/app/sukces/:co',
        name: 'Sukces',
        component: Sukces
      },
      {
        path: '/app/logowanie',
        name: 'Logowanie',
        component: Logowanie
      },
      {
        path: '/app/profil',
        name: 'ProfilLekarza',
        component: ProfilLekarza,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true
        }
      },
      {
        path: '/app/licencje',
        name: 'Licencje',
        component: Licencje,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true,
          premium: true
        }
      },
      {
        path: '/app/wizytowka',
        name: 'Wizytowka',
        component: Wizytowka,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true,
          premium: true
        }
      },
      {
        path: '/app/oswiadczenia',
        name: 'Oswiadczenia',
        component: Oswiadczenia,
        meta: { zalogowany: true }
      },
      {
        path: '/app/porownanie',
        name: 'PorownanieWersji',
        component: PorownanieWersji
      },
      {
        path: '/app/zakup-premium',
        name: 'NowaLicencja',
        component: NowaLicencja,
        meta: { zalogowany: true }
      },
      {
        path: '/app/obsluga-platnosci/:id',
        name: 'ObslugaPlatnosci',
        component: ObslugaPlatnosci,
        meta: { zalogowany: true }
      },
      {
        path: '/app/polecanie',
        name: 'PolecenieAplikacji',
        component: PolecenieAplikacji,
        meta: { zalogowany: true }
      },
      {
        path: '/app/z-polecenia/:uuid',
        name: 'WejscieZPolecenia',
        component: WejscieZPolecenia
      },
      {
        path: '/app/kody-rabatowe',
        name: 'KodyRabatowe',
        component: KodyRabatowe,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true
        }
      },
      {
        path: '/app/wiadomosci',
        name: 'Wiadomosci',
        component: Wiadomosci,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true
        }
      },
      {
        path: '/app/wydruk-formularzy-zla',
        name: 'WydrukFormularzyZla',
        component: WydrukFormularzyZla,
        meta: {
          zalogowany: true,
          zgodaNaRegulamin: true
        }
      }
    ]
  },
  {
    path: '/',
    component: Landing,
    children: [
      {
        path: '/',
        name: 'Glowna',
        component: Glowna
      },
      {
        path: '/demo',
        name: 'Demo',
        component: Demo
      },
      {
        path: '/kody_rabatowe',
        name: 'KodyRabatoweInstrukcja',
        component: KodyRabatoweInstrukcja
      }
    ]
  },
  {
    path: '/app/*', redirect: '/app'
  },
  {
    path: '*', redirect: '/'
  }
]

const router = new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.zalogowany) && !store.getters.zalogowany) {
    if (Utils.czyDev()) {
      // eslint-disable-next-line
      console.log('Użytkownik nie jest zalogowany. Po zalogowaniu przekieruję na:', to.fullPath)
    }

    store.commit({
      type: ZmianyStanu.PRZEKIERUJ_PO_ZALOGOWANIU,
      przekierowaniePoZalogowaniu: to.fullPath
    })

    next({ name: 'Logowanie' })
    return
  }
  if (to.matched.some(record => record.meta.zgodaNaRegulamin) && !store.getters.maWymaganeZgody) {
    if (Utils.czyDev()) {
      // eslint-disable-next-line
      console.log('Użytkownik nie zaakceptował regulaminu. Po akceptacji przekieruję na:', to.fullPath)
    }

    store.commit({
      type: ZmianyStanu.PRZEKIERUJ_PO_ZGODACH,
      przekierowaniePoZgodach: to.fullPath
    })

    next({ name: 'Oswiadczenia' })
    return
  }
  if (to.matched.some(record => record.meta.premium) && !store.getters.maPremium) {
    if (Utils.czyDev()) {
      // eslint-disable-next-line
      console.warn('Użytkownik nie ma wymaganej licencji.')
    }

    next({ name: 'StronaStartowa' })
    return
  }

  next()
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.name === 'Logowanie') && store.getters.zalogowany) {
    next({ name: 'StronaStartowa' })
  } else {
    next()
  }
})

export default router
