<template>
  <b-container class="mt-3">
    <b-card class="card-page">
      <h4>Realizacja kodu rabatowego</h4>
      <div v-if="!pozycjaCennika">
        <p>Wprowadź posiadany kod, abyśmy mogli przedstawić Ci szczegóły oferty promocyjnej.</p>
        <b-form @submit.prevent="sprawdzKod">
          <b-form-group
            :invalid-feedback="errors.first('kod')">
            <b-form-input
              name="kod"
              v-model="kod"
              v-validate="'required'"
              data-vv-as="kod"
              :state="!this.errors.has('kod')"></b-form-input>
          </b-form-group>
          <b-btn type="submit" variant="primary" :disabled="przetwarzanie" size="lg">
            <icon v-if="przetwarzanie" name="circle-o-notch" scale="0.8" spin></icon>
            Dalej
          </b-btn>
        </b-form>
      </div>
      <transition name="placenie">
        <div v-if="pozycjaCennika">
          <pozycja-cennika v-bind="pozycjaCennika" v-on:update:ilosc="pozycjaCennika.ilosc = $event"></pozycja-cennika>
          <b-form v-if="!darmowe" @submit.prevent="aktywuj">
            <kontrahent :kontrahent="kontrahent"></kontrahent>
            <div class="mt-4 text-center">
              <b-btn type="submit" variant="primary" :disabled="przetwarzanie" size="lg">
                <icon v-if="przetwarzanie" name="circle-o-notch" scale="0.8" spin></icon>
                Płacę on-line
                <img src="../../assets/przelewy24_neg.svg" alt="Przelewy24" class="przelewy24"/>
              </b-btn>
            </div>
          </b-form>
          <div v-else class="mt-4 text-center">
            <b-button @click="aktywuj" size="lg" variant="primary">Aktywuj!</b-button>
          </div>
        </div>
      </transition>
    </b-card>
  </b-container>
</template>

<script>
import Licencje from '@/services/Licencje'
import PozycjaCennika from './PozycjaCennika'
import Kontrahent from '@/components/form/KontrahentForm'
import ProfileLekarzy from '@/services/ProfileLekarzy'

export default {
  name: 'kody-rabatowe',
  components: { PozycjaCennika, Kontrahent },
  data () {
    return {
      przetwarzanie: false,
      kod: '',
      pozycjaCennika: null,
      kontrahent: {
        nip: null,
        nazwa: null,
        email: null,
        adres: {
          kodPocztowy: null,
          miejscowosc: null,
          ulica: null,
          nrDomu: null,
          nrMieszkania: null
        }
      }
    }
  },
  computed: {
    darmowe () {
      return this.pozycjaCennika.cena === 0
    }
  },
  methods: {
    sprawdzKod: function () {
      this.$validator.validateAll().then((result) => {
        if (!result) { return }
        this.przetwarzanie = true
        Licencje.sprawdzKodRabatowy(this.kod).then(pozycjaCennika => {
          this.pozycjaCennika = pozycjaCennika
          this.pozycjaCennika.ilosc = 1
        }, blad => {
          if (blad.status === 404) {
            this.niepoprawnyKod()
          } else if (blad.status === 409) {
            this.przekroczonyLimit()
          } else if (blad.status === 400) {
            this.ponowneUzycieKodu()
          }
        }).catch().then(() => {
          this.przetwarzanie = false
        })
      })
    },
    aktywuj: function () {
      this.$validator.validateAll().then((result) => {
        this.przetwarzanie = true
        if (this.darmowe) {
          this.kontrahent.adres = null // usuwamy cały obiekt adresu
        }
        Licencje.realizujKodRabatowy(this.kod, this.pozycjaCennika, this.kontrahent).then(response => {
          if (!this.darmowe) {
            window.location.assign(response.url)
          } else {
            ProfileLekarzy.ustawProfil().then(r => {
              this.aktywacjaPomyslna()
              this.$router.push({ name: 'Wyszukiwanie' })
            })
          }
        }, blad => {
          if (blad.status === 404) {
            this.niepoprawnyKod()
          } else if (blad.status === 409) {
            this.przekroczonyLimit()
          } else if (blad.status === 400) {
            this.ponowneUzycieKodu()
          }
        }).catch().then(() => {
          this.przetwarzanie = false
        })
      })
    }
  },
  notifications: {
    niepoprawnyKod: {
      title: 'Niepoprawny kod!',
      message: 'Nic nie wiemy o takim kodzie rabatowym. Sprawdź czy wszystkie litery są na swoim miejscu i spróbuj jeszcze raz!',
      type: 'error',
      timeout: 7000
    },
    przekroczonyLimit: {
      title: 'Spóźniłeś się!',
      message: 'Niestety ten kod nie może już zostać zrealizowany.',
      type: 'error',
      timeout: 7000
    },
    ponowneUzycieKodu: {
      title: 'Kod wykorzystany!',
      message: 'Już skorzystałeś z tej promocji!',
      type: 'error',
      timeout: 5000
    },
    aktywacjaPomyslna: {
      title: 'Gratulujemy!',
      message: 'Licencja na użycie wersji premium jest już aktywna.',
      type: 'info'
    }
  }
}
</script>

<style lang="scss" scoped>
.placenie-enter-active, .placenie-leave-active {
  transition: opacity .6s;
}
.placenie-enter, .placenie-leave-to {
  opacity: 0;
}
.przelewy24 {
  height: 26px;
  margin-left: 1rem;
}
</style>
