<template>
  <b-container class="container-alert mt-3">

    <b-card class="card-page">
      <b-row align-v="center">
        <b-col cols="2" class="text-center">
          <icon name="check-square-o" scale="3" class="text-primary"></icon>
        </b-col>
        <b-col cols="10">
          <h4>Korzystanie z eZLApki wymaga akceptacji regulaminu.</h4>
        </b-col>
      </b-row>

      <div class="my-4 container-scroll">
        <vue-markdown :source="regulamin"></vue-markdown>
      </div>

      <b-form @submit.prevent="wyslijOswiadczenia">
        <b-form-group>
          <b-form-checkbox-group required stacked id="zgody" v-model="zgody">
            <b-form-checkbox state="false" value="regulamin">Oświadczam, że zapoznałem się i akceptuję
              <strong>Regulamin</strong> korzystania z portalu.</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <div class="mt-4">
          <b-btn type="submit" size="lg" variant="primary"
            :disabled="!zaznaczoneZgody">
            Akceptuję regulamin
          </b-btn>
        </div>
      </b-form>
    </b-card>

  </b-container>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import Oswiadczenia from '../services/Oswiadczenia'
import ProfileLekarzy from '../services/ProfileLekarzy'

export default {
  name: 'zgody',
  components: { VueMarkdown },
  data () {
    return {
      zgody: [],
      regulamin: ''
    }
  },
  computed: {
    zaznaczoneZgody () {
      return this.zgody.indexOf('regulamin') > -1
    }
  },
  created () {
    this.$http.get('/regulamin.md').then(response => {
      this.regulamin = response.body
    })
  },
  methods: {
    wyslijOswiadczenia () {
      Oswiadczenia.akceptujRegulamin().then(response => {
        ProfileLekarzy.ustawProfil().then(r => {
          this.$ga.event('oświadczenia', 'zaakceptowano')
          this.$router.replace({ name: 'StronaStartowa' })
        })
      }, blad => {
        this.bladPrzyjeciaOswiadczen()
      })
    }
  },
  notifications: {
    bladPrzyjeciaOswiadczen: {
      title: 'Błąd',
      message: 'Nie udało nam się zapisać Twojej zgody. Spróbuj ponownie za chwilę...',
      type: 'error'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables.scss';

.container-alert {
  max-width: $md;
}

.container-scroll {
  height: 30rem;
  overflow: auto;
  border: 1px solid $line-bg;
  padding: 1rem;
}
</style>
