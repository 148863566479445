<template>
  <div class="text-center text-info mt-3">
    <icon name="cog" scale="3" spin></icon>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Wiadomosci from '@/services/Wiadomosci'
import * as ZmianyStanu from '@/store/ZmianyStanu'

export default {
  name: 'strona-startowa',
  computed: {
    ...mapState(['idWystawcy', 'przekierowanie']),
    ...mapGetters(['maWymaganeZgody', 'imieNazwiskoLekarza', 'npwzLekarza', 'nieprzeczytaneWiadomosci'])
  },
  created () {
    this.$ga.set('userId', `${this.imieNazwiskoLekarza} - ${this.npwzLekarza}`)
    if (!this.maWymaganeZgody) {
      this.$router.replace({ name: 'Oswiadczenia' })
      return
    }
    if (!this.idWystawcy) {
      this.$router.replace({ name: 'MiejscaPracy' })
      return
    }
    Wiadomosci.pobierz().then(wiadomosci => {
      this.$store.commit(ZmianyStanu.ZAPAMIETAJ_WIADOMOSCI, {
        wiadomosci: wiadomosci
      })
      if (this.nieprzeczytaneWiadomosci.length > 0) {
        this.noweWiadomosci()
      }
    })
    if (this.przekierowanie) {
      this.$router.replace({ path: this.przekierowanie })
      this.$store.commit(ZmianyStanu.PRZEKIERUJ_PO_ZALOGOWANIU, {
        przekierowanie: null
      })
      return
    }

    this.$router.replace({ name: 'Wyszukiwanie' })
  },
  notifications: {
    noweWiadomosci: {
      title: 'Nowa wiadomość',
      message: 'Masz co najmniej jedną nieprzeczytaną wiadomość. Kliknij w przycisk Wiadomości.',
      type: 'info',
      timeout: 7000
    }
  }
}
</script>
