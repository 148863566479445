<template>
  <b-container>

    <b-card>
      <b-card-body>
        <section>
          <h1>Aby wystawić eZLA w eZLApka.pl musisz wskazać Twój certyfikat ZUS.</h1>
          <p>
            Certyfikat będzie używany do zweryfikowania w systemie informatycznym ZUS, czy jesteś lekarzem, który ma prawo do wystawiania elektronicznych zwolnień lekarskich. Będziemy go także wykorzystywać do ich cyfrowego podpisywania.
          </p>
          <p>
            Jeżeli nie posiadasz jeszcze certyfikatu ZUS kliknij <a href="http://www.zus.pl/o-zus/aktualnosci/-/publisher/aktualnosc/3/bezplatny-certyfikat-z-zus-nowe-rozwiazanie-dla-lekarzy-ktorzy-wystawiaja-elektroniczne-zwolnienia-lekarskie/1267817" target="_blank">tutaj</a>, aby dowiedzieć się jak go zdobyć.
          </p>
          <p>
            eZLApka umożliwia wskazanie pliku z certyfikatem na kilka sposobów:
          </p>
          <!-- <p>
            Chcesz dowiedzieć się więcej o możliwych miejscach przechowywania certyfikatów ZUS?
            <router-link :to="{ name: 'Certyfikaty_instrukcja'}">Czytaj więcej</router-link>.
          </p> -->
        </section>

        <section v-if="!czyDemo">
          <h1>Mam plik z certyfikatem ZUS na moim koncie w chmurze.</h1>
          <p>
            Wybierz usługę, z której korzystasz klikając w odpowiedni przycisk:
          </p>

          <!-- <b-button variant="info" class="btn-login btn-epuap"
            @click="zalogujEpuapem">
            <img src="../assets/pz_neg.svg" alt="Logo Profilu Zaufanego ePUAP">
            Profilem Zaufanym ePUAP
          </b-button> -->

          <b-button variant="info" class="btn-login btn-dropbox"
            @click="zalogujDoDropbox">
            <img src="../assets/dropbox.svg" alt="Logo Dropbox">
            Dropbox
          </b-button>

          <b-button variant="info" class="btn-login"
            @click="zalogujDoGoogle">
            <img src="../assets/google_drive_neg.svg" alt="Logo Google Drive">
            Google Drive
          </b-button>

          <b-button variant="info" class="btn-login"
            @click="zalogujDoOnedrive">
            <img src="../assets/onedrive_neg.svg" alt="Logo OneDrive">
            OneDrive
          </b-button>
        </section>

        <b-row>
          <b-col md="5">
            <section>
              <h1>Przesłałem już certyfikat ZUS do eZLApka.pl.</h1>
              <p>
                Kliknij w przycisk poniżej, aby użyć wgranego wcześniej certyfikatu:
              </p>
              <b-button variant="primary" class="btn-login" v-b-modal.modalLogowanie>
                <img src="../assets/logo_neg.svg" alt="Logo eZLApka">
                eZLApka.pl
              </b-button>
            </section>
          </b-col>

          <b-col md="7" v-if="!czyDemo">
            <section>
              <h1>Chcę wgrać certyfikat ZUS do eZLApka.pl.</h1>
              <p>
                Aby wgrać swój certyfikat, skorzystaj z pola znajdującego się poniżej.<br>
                Jeśli to zrobisz, następnym razem będziesz mógł używać przycisku <strong>eZLApka.pl</strong>.
              </p>
              <b-form @submit.stop.prevent="wgrajCertyfikatZus">
                <vue-dropzone ref="kontrolkaDropzone"
                              id="dropzone"
                              class="cert-dropzone"
                              :options="opcjeUploaduCertyfikatu"
                              @vdropzone-file-added="dodanyPlik"
                              @vdropzone-complete="plikPrzetworzony"
                              @vdropzone-success="plikWgrany">
                </vue-dropzone>
              </b-form>
            </section>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <div class="text-center my-4">
      <router-link :to="{ name: 'Glowna'}" class="text-muted">
        <small>&larr; wróć do strony głównej</small>
      </router-link>
    </div>

    <!-- modal logowanie NPWZ -->
    <!-- no-enforce-focus https://github.com/bootstrap-vue/bootstrap-vue/issues/1908#issuecomment-438521566 -->
    <b-modal id="modalLogowanie"
             ref="modalLogowanie"
             class="modal-login"
             centered hide-footer
             no-enforce-focus
             title="Wprowadź NPWZ oraz hasło do certyfikatu"
             :no-close-on-backdrop="przetwarzanie"
             :no-close-on-esc="przetwarzanie"
             :hide-header-close="przetwarzanie"
             @shown="ustawFokusNpwz">
      <form @submit.stop.prevent="zalogujEzlapka">
        <b-form-group :invalid-feedback="errors.first('npwz')">
          <b-form-input name="npwz"
            class="input-npwz"
            ref="npwz"
            type="text"
            maxlength="7"
            placeholder="NPWZ"
            v-model="npwz"
            v-validate="'required|min:7|numeric'"
            data-vv-as="NPWZ"
            :state="!this.errors.has('npwz')">
          </b-form-input>
        </b-form-group>
        <b-form-group :invalid-feedback="errors.first('npwzHaslo')">
          <b-form-input name="npwzHaslo"
            type="password"
            placeholder="Hasło"
            v-model="haslo"
            v-validate="'required'"
            data-vv-as="hasło"
            :state="!this.errors.has('npwzHaslo')">
          </b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" block :disabled="przetwarzanie">
          <icon v-if="przetwarzanie" name="circle-o-notch" scale="0.8" spin></icon>
          Zatwierdź
        </b-button>
      </form>
    </b-modal>

    <!-- modal certyfikat -->
    <!-- no-enforce-focus https://github.com/bootstrap-vue/bootstrap-vue/issues/1908#issuecomment-438521566 -->
    <b-modal id="dialogCertyfikat"
             ref="dialogCertyfikat"
             class="modal-login"
             centered hide-footer
             no-enforce-focus
             title="Podaj hasło do certyfikatu otrzymanego z ZUS"
             v-model="pokazdialogCertyfikat"
             :no-close-on-backdrop="przetwarzanie"
             :no-close-on-esc="przetwarzanie"
             :hide-header-close="przetwarzanie"
             @shown="ustawFokusHaslo"
             @hidden="usunCertyfikat">
      <form @submit.stop.prevent="wgrajCertyfikatZus">
        <b-form-group :invalid-feedback="errors.first('certHaslo')">
          <b-form-input name="certHaslo"
            ref="certHaslo"
            type="password"
            placeholder="Hasło"
            v-model="haslo"
            v-validate="'required'"
            data-vv-as="hasło"
            :state="!this.errors.has('certHaslo')">
          </b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" block :disabled="przetwarzanie">
          <icon v-if="przetwarzanie" name="circle-o-notch" scale="0.8" spin></icon>
          Wgraj
        </b-button>

        <hr class="my-4">

        <b-row>
          <b-col cols="2" class="text-center">
            <icon name="info-circle" scale="1.5" class="text-primary"
              v-b-tooltip="'Wskazówka!'"></icon>
          </b-col>
          <b-col cols="10">
            <span class="text-muted">
            Po wgraniu certyfikatu możesz następnym razem wejść do systemu za pomocą przycisku
            <strong>Certyfikat z ZUS przechowywany w ezlapce</strong>
            </span>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-container>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.css'

import { mapState, mapGetters } from 'vuex'
import LogowanieDoZus from '../services/LogowanieDoZus'
import ProfileLekarzy from '../services/ProfileLekarzy'
import * as Komunikaty from '../constants/Komunikaty'
import * as KodyBledow from '../constants/KodyBledow'
import * as ZmianyStanu from '../store/ZmianyStanu'

export default {
  name: 'Logowanie',
  metaInfo: {
    title: 'Logowanie',
    link: [{ rel: 'canonical', href: 'https://ezlapka.pl/app/logowanie' }],
    meta: [
      { 'property': 'og:title', 'content': 'Logowanie | eZLApka - łatwe eZLA!', 'vmid': 'og:title' },
      { 'property': 'og:url', 'content': 'https://ezlapka.pl/app/logowanie', 'vmid': 'og:url' }
    ]
  },
  components: {
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      haslo: null,
      npwz: null,
      pokazdialogCertyfikat: false,
      przetwarzanie: false,
      opcjeUploaduCertyfikatu: {
        url: '/api/certyfikaty-zus/z-lokalnego-dysku',
        autoProcessQueue: false,
        addRemoveLinks: true,
        clickable: true,
        maxFilesize: 0.5,
        maxFiles: 1,
        acceptedFiles: 'application/x-pkcs12,.pfx',
        dictDefaultMessage: 'Upuść go tutaj lub kliknij, aby go wskazać',
        dictFileTooBig: 'Ten plik jest zbyt duży! Nie przyjmujemy większych niż {{maxFilesize}}MB.',
        dictInvalidFileType: 'Wygląda na to, że to nie plik z certyfikatem otrzymanym z ZUS...',
        dictResponseError: 'Upps... coś poszło nie tak, nie możemy w tej chwili wgrać certyfikatu, spróbuj za chwilę...',
        dictMaxFilesExceeded: 'Możesz wskazać tylko jeden plik.',
        dictRemoveFile: 'Usuń'
      }
    }
  },
  computed: {
    ...mapState(['uuidPolecenia']),
    ...mapGetters(['czyDemo'])
  },
  methods: {
    zalogujEpuapem () {
      window.location.assign('/api/epuap/sso')
    },
    zalogujDoGoogle () {
      window.location.assign('/api/google/login')
    },
    zalogujDoDropbox () {
      window.location.assign('/api/dropbox/login')
    },
    zalogujDoOnedrive () {
      window.location.assign('/api/onedrive/login')
    },
    pokazDialogLogowania () {
      this.$refs.modalLogowanie.show()
    },
    ustawFokusNpwz () {
      this.$refs.npwz.focus()
    },
    ustawFokusHaslo () {
      this.$refs.certHaslo.focus()
    },
    zalogujEzlapka () {
      this.$validator.validateAll(['npwz', 'npwzHaslo']).then((result) => {
        if (!result) { return }
        this.przetwarzanie = true
        LogowanieDoZus.podpiszOswiadczenieCertyfikatemZus(this.npwz, this.haslo).then(response => {
          ProfileLekarzy.ustawProfil().then(r => {
            this.$router.replace({ name: 'StronaStartowa' })
          })
        }, (blad) => {
          this.przetwarzanie = false
          if (blad.body.kod === KodyBledow.ZLE_HASLO_DO_CERTYFIKATU) {
            this.informacjaLogowanie()
            this.niepoprawneHaslo()
          } else {
            this.nieznanyBlad()
          }
        })
      })
    },
    wgrajCertyfikatZus () {
      this.$validator.validateAll(['certHaslo']).then((result) => {
        if (!result) { return }
        var params = {
          haslo: this.haslo
        }
        if (this.uuidPolecenia) {
          params.uuidPolecenia = this.uuidPolecenia
        }
        this.przetwarzanie = true
        this.$refs.kontrolkaDropzone.setOption('params', params)
        this.$refs.kontrolkaDropzone.processQueue()
      })
    },
    plikPrzetworzony (plik) {
      if (plik.status !== 'success') {
        this.pokazdialogCertyfikat = false
        this.przetwarzanie = false
        this.niepoprawnyCertyfikat()
      }
    },
    plikWgrany () {
      this.$store.commit(ZmianyStanu.ZAPAMIETAJ_HASLO_DO_CERTYFIKATU_ZUS, { haslo: this.haslo })
      ProfileLekarzy.ustawProfil().then(r => {
        this.$router.replace({ name: 'StronaStartowa' })
      })
    },
    dodanyPlik () {
      // opóźnienie przetwarzania kontrolki dropzone
      setTimeout(() => {
        if (this.$refs.kontrolkaDropzone.getAcceptedFiles().length > 0) {
          this.pokazdialogCertyfikat = true
        }
      }, 300)
    },
    usunCertyfikat () {
      this.$refs.kontrolkaDropzone.removeAllFiles()
    }
  },
  notifications: {
    niepoprawneHaslo: {
      title: Komunikaty.ZLE_HASLO_LOGOWANIE.TYTUL,
      message: Komunikaty.ZLE_HASLO_LOGOWANIE.TRESC,
      type: 'error',
      timeout: 7000
    },
    informacjaLogowanie: {
      title: 'Brak certyfikatu?',
      message: 'Jeżeli nie przesyłałeś jeszcze certyfikatu do eZLApki, zrób to klilkając poniżej w szary prostokąt.',
      type: 'info',
      timeout: 15000
    },
    niepoprawnyCertyfikat: {
      title: Komunikaty.ZLY_CERTYFIKAT.TYTUL,
      message: Komunikaty.ZLY_CERTYFIKAT.TRESC,
      type: 'error',
      timeout: 7000
    },
    nieznanyBlad: {
      title: Komunikaty.NIEZNANY_BLAD.TYTUL,
      message: Komunikaty.NIEZNANY_BLAD.TRESC,
      type: 'error',
      timeout: 7000
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/variables';

section {
  margin-bottom: 2.5rem;
}

h1 {
  color: $black;
  font-size: 1.07rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

p {
  color: #6F6F6F;
}

.btn-login {
  padding: 3px 16px;
  border: none;
  border-radius: 18px;
  line-height: 30px;
  margin: 0 3rem 1rem 0;

  display: inline-flex;
  align-items: center;

  font-weight: bold;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.2) !important;

  img {
    height: 18px;
    margin-right: 0.5rem;
  }
}

.cert-dropzone {
  border-radius: 18px;
  border-color: #BDBDBD;

  font-family: $font-family-base;
  font-weight: bold;
  color: #BDBDBD;
}

.modal-login {
  /deep/ .modal-header {
    border-bottom: none;
    .modal-title {
      font-size: $font-size-base;
    }
  }

  /deep/ .modal-content {
    padding: 1rem 2rem 2rem 2rem;
  }

  .form-control {
    font-size: 1.5rem;
    letter-spacing: 0.1em;

    &::placeholder {
      font-size: $font-size-base;
    }
  }
}

@media (max-width: $md) {
  .btn-login {
    display: block;
    margin: 1rem auto;
    min-width: 160px;
  }
}
</style>
