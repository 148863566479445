import Vue from 'vue'
import Utils from './Utils'
import * as ZmianyStanu from '../store/ZmianyStanu'
import Store from '../store/index'

export default {

  przyczynyAnulowaniaEzla () {
    if (Store.state.slownikPrzyczynAnulowaniaEzla.length > 0) {
      return Promise.resolve(Store.state.slownikPrzyczynAnulowaniaEzla)
    } else {
      return Vue.http.get('slowniki-zus/przyczyny-anulowania-ezla').then(response => {
        Store.commit({
          type: ZmianyStanu.ZAPAMIETAJ_SLOWNIK_PRZYCZYN_ANULOWANIA_EZLA,
          slownik: response.body
        })
        return response.body
      }, Utils.przechwycBlad)
    }
  }
}
