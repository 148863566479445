<template>
  <b-container class="container-alert mt-3">

    <b-card class="card-page">
      <b-row align-v="center">
        <b-col cols="2" class="text-center">
          <icon name="check-circle" scale="3" class="text-primary"></icon>
        </b-col>
        <b-col cols="10">
          <h4>Za chwilę zostaniesz przeniesiony na stronę ePUAP-u w celu podpisania eZLA.</h4>
        </b-col>
      </b-row>

      <countdown :leading-zero="false"
        :time="dismissSecs * 1000"
        @countdownend="rozpocznijPodpis()">
        <template slot-scope="props">
          <p class="my-4">Przejście do strony ePUAP-u nastąpi za {{ props.seconds }} sekundy...</p>
          <b-progress variant="info" :max="dismissSecs" :value="props.seconds" height="4px"></b-progress>
        </template>
      </countdown>

      <div class="mt-4">
        <b-btn variant="primary" size="lg" @click="rozpocznijPodpis()">Chodźmy od razu!</b-btn>
      </div>
    </b-card>

  </b-container>
</template>

<script>
export default {
  name: 'przekierowanie-podpis-epuap',
  data () {
    return {
      dismissSecs: 5
    }
  },
  methods: {
    rozpocznijPodpis () {
      window.location.replace(this.$route.params.url)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
