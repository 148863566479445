<template>
  <div class="text-center text-info mt-3">
    <icon name="cog" scale="3" spin></icon>
  </div>
</template>

<script>
import * as ZmianyStanu from '../store/ZmianyStanu'

export default {
  name: 'wejscie-z-polecenia',
  mounted: function () {
    this.$store.commit({
      type: ZmianyStanu.ZAPAMIETAJ_UUID_POLECENIA,
      uuid: this.$route.params.uuid
    })
    this.$router.replace({ path: '/' })
  }
}
</script>

<style>

</style>
