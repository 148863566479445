<template>
  <div>
    <transition name="slide-fade" mode="out-in">
      <!-- kafelki -->
      <div v-if="!trybEdycja">
        <div class="tiles-container">
          <Podopieczni :podopieczni="podopieczniDoWyboru"
            :value="podopieczny"
            @input="wybranoPodopiecznego"
            @edytuj="edytujPodopiecznego"
            @usun="usunPodopiecznego">
          </Podopieczni>
        </div>

        <!-- przycisk dodawania -->
        <b-button v-if="podopieczniDoWyboru.length > 0"
          class="btn-add"
          variant="light"
          @click="nowyPodopieczny()"
          v-b-tooltip="'nowy podopieczny'">
          <strong>+</strong>
        </b-button>
        <b-button v-if="podopieczniDoWyboru.length === 0"
          variant="secondary"
          @click="nowyPodopieczny()">
          zdefiniuj podopiecznego
        </b-button>
      </div>

      <!-- formularz -->
      <div v-if="trybEdycja">
        <PodopiecznyForm :podopieczny="kopiaPodopiecznego"
          @zapisz="zapisz"
          @cofnij="trybEdycja = false">
        </PodopiecznyForm>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from 'moment'
import Podopieczni from './Podopieczni'
import PodopiecznyForm from './PodopiecznyForm'

export default {
  name: 'rodzina-ubezpieczonego',
  components: { Podopieczni, PodopiecznyForm },
  props: ['rodzina', 'podopieczny'],
  inject: ['$validator'],
  data () {
    return {
      trybEdycja: false,
      edytowanyPodopieczny: null,
      podopieczniDoWyboru: []
    }
  },
  computed: {
    kopiaPodopiecznego () {
      return Object.assign({}, this.edytowanyPodopieczny)
    }
  },
  mounted () {
    this.podopieczniDoWyboru = this.rodzina.map(p => {
      p.dataUrodzenia = this.formatujDateUrodzenia(p.dataUrodzenia)
      p.kodPokrewienstwa = null
      return p
    })
  },
  methods: {
    wybranoPodopiecznego (podopieczny) {
      this.podpowiedzKod(podopieczny)
      this.$emit('update:podopieczny', podopieczny)
    },
    nowyPodopieczny () {
      this.edytowanyPodopieczny = { nowy: true }
      this.trybEdycja = true
    },
    edytujPodopiecznego (p) {
      this.edytowanyPodopieczny = p
      this.trybEdycja = true
    },
    usunPodopiecznego (p) {
      let index = this.podopieczniDoWyboru.indexOf(p)
      if (index !== -1) {
        this.podopieczniDoWyboru.splice(index, 1)
      }
      if (p === this.podopieczny) {
        this.wybranoPodopiecznego(null)
      }
    },
    zapisz (p) {
      const index = this.podopieczniDoWyboru.indexOf(this.edytowanyPodopieczny)
      if (index !== -1) {
        this.podopieczniDoWyboru[index] = p
      } else {
        this.podopieczniDoWyboru.push(p)
      }
      this.wybranoPodopiecznego(p)
      this.trybEdycja = false
    },
    formatujDateUrodzenia (d) {
      return moment(d).locale('pl').format('YYYY-MM-DD')
    },
    podpowiedzKod (podopieczny) {
      if (!podopieczny || podopieczny.kodPokrewienstwa) {
        return
      }

      if (moment(podopieczny.dataUrodzenia).add(18, 'years').isAfter(moment())) {
        podopieczny.kodPokrewienstwa = 'DZIECKO'
      } else {
        podopieczny.kodPokrewienstwa = 'OPIEKUN'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";

.tiles-container {
  display: inline-block;
  vertical-align: top;
}

.btn-add {
  margin-top: 0.25rem;
  margin-left: -4px;
  background-color: $input-bg;
  height: 95px;
}
</style>
