<template>
  <b-container class="mt-3">
    <b-card class="card-page" no-body>

      <b-card-body>
        <b-table class="table-grid mt-4" stacked="md" :items="licencje" :fields="naglowek">
          <template slot="klucz" slot-scope="data">
           <code>{{ data.item.klucz }}</code>
          </template>
          <template slot="dataOd" slot-scope="data">
            <span class="no-wrap">{{ data.item.dataOd | formatujDate }} &rarr; {{ data.item.dataDo | formatujDate }}</span>
          </template>
          <template slot="nrFaktury" slot-scope="data">
            <a v-if="data.item.nrFaktury" :href="'/api/faktury/' + encodeURIComponent(data.item.nrFaktury)" target="_blank">Pobierz fakturę</a>
          </template>
        </b-table>
      </b-card-body>

    </b-card>
  </b-container>
</template>

<script>
import Licencje from '@/services/Licencje'
import * as Komunikaty from '@/constants/Komunikaty'

export default {
  name: 'licencje',
  data () {
    return {
      licencje: [],
      naglowek: {
        klucz: {
          label: 'Klucz licencji'
        },
        dataOd: {
          label: 'Okres ważności'
        },
        koszt: {
          label: 'Zapłacono',
          formatter: 'formatujKwote'
        },
        nrFaktury: {
          label: ' '
        }
      }
    }
  },
  created () {
    Licencje.moje().then(licencje => {
      this.licencje = licencje
    }, blad => {
      this.bladPobieraniaLicencji()
    })
  },
  methods: {
    formatujKwote (kwota) {
      return this.$options.filters.formatujKwote(kwota)
    }
  },
  notifications: {
    bladPobieraniaLicencji: {
      title: Komunikaty.NIEZNANY_BLAD.TYTUL,
      message: Komunikaty.NIEZNANY_BLAD.TRESC,
      type: 'error',
      timeout: 7000
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

</style>
