<template>

  <b-container class="container-alert mt-3">

    <b-card class="card-page">
      <div @mouseover="wstrzymajLicznik">
        <b-row align-v="center">
          <b-col cols="2" class="text-center">
            <icon name="check-circle" scale="3" class="text-primary"></icon>
          </b-col>
          <b-col cols="10">
            <h4>Nowe eZLA zostało pomyślnie wysłane do ZUS.</h4>
          </b-col>
        </b-row>

        <hr class="my-4">

        <b-row align-v="center">
          <b-col cols="2" class="text-center">
            <div class="bg-icon">
              <icon name="print" scale="2" class="text-white"></icon>
            </div>
          </b-col>
          <b-col cols="10">
            <h4>Wydruk kopii wystawionego eZLA.</h4>
          </b-col>
        </b-row>

        <p class="my-4">
          Kliknij poniższy przycisk, aby pobrać plik PDF, który następnie możesz wydrukować.
        </p>

        <b-table v-if="platnicy.length > 0" :items="platnicy" :fields="naglowek">
          <template slot="nazwa" slot-scope="data">
            {{ formatujPlatnika(data.item) }}
          </template>
          <template slot="maPue" slot-scope="data">
            <b-badge v-if="!data.item.maPue"
              variant="warning"
              v-b-tooltip.top="ostrzezenie">
              płatnik nie posiada PUE
            </b-badge>
          </template>
          <template slot="wydruk" slot-scope="data">
            <b-btn :href="'/api/ezla/' + uuidZwolnieniaDlaPlatnika(data.item)"
              target="_blank"
              size="sm"
              variant="info">Pobierz PDF</b-btn>
          </template>
        </b-table>
        <p v-if="platnicy.length === 0">
          <b-btn :href="'/api/ezla/' + uuidZwolnien['KRUS']"
              target="_blank"
              size="sm"
              variant="info">Pobierz PDF</b-btn>
        </p>

        <p>
          Wydruk możesz także wykonać w dowolnym momencie, przechodząc do
          <router-link :to="{name: 'WystawioneZwolnienia'}">wystawionych zwolnień</router-link>.
        </p>
      </div>

      <hr class="my-4">

      <div v-if="autoPrzekierowanie" @mouseover="wznowLicznik">
        <countdown :leading-zero="false"
          :time="dismissSecs * 1000"
          ref="countdown"
          @countdownend="naGlowna()">
          <template slot-scope="props">
            <p class="my-4">Przejście do strony głównej nastąpi za {{props.seconds}} sekundy...</p>
            <b-progress variant="info" :max="dismissSecs" :value="props.seconds" height="4px"></b-progress>
          </template>
        </countdown>

        <div class="mt-4">
          <b-btn variant="primary" size="lg" @click="naGlowna()">Chodźmy od razu!</b-btn>
        </div>
      </div>

      <div v-if="!autoPrzekierowanie">
        <div class="mt-4">
          <b-btn variant="primary" size="lg" @click="naGlowna()">Przejdź do strony głównej</b-btn>
        </div>
      </div>
    </b-card>

  </b-container>
</template>

<script>
import * as Komunikaty from '../../constants/Komunikaty'

export default {
  name: 'nowe-ezla-sukces',
  data () {
    return {
      dismissSecs: 15,
      haslo: null,
      naglowek: {
        nazwa: {
          label: 'Płatnik'
        },
        maPue: {
          label: 'PUE'
        },
        wydruk: {
          label: ' '
        }
      },
      ostrzezenie: Komunikaty.PLATNIK_NIE_MA_PUE.TRESC_PODSUMOWANIE
    }
  },
  computed: {
    co () {
      return this.$route.params.co
    },
    uuidZwolnien () {
      return this.$route.params.uuidZwolnien
    },
    uuidZwolnienDoWydruku () {
      return this.$route.params.uuidZwolnienDoWydruku
    },
    platnicy () {
      return this.$route.params.platnicy
    },
    autoPrzekierowanie () {
      return !this.platnicy.find((p) => { return !p.maPue })
    }
  },
  methods: {
    naGlowna () {
      this.$router.replace({ name: 'Wyszukiwanie' })
    },
    wznowLicznik () {
      if (this.$refs.countdown) {
        this.$refs.countdown.start()
      }
    },
    wstrzymajLicznik () {
      if (this.$refs.countdown) {
        this.$refs.countdown.pause()
      }
    },
    formatujPlatnika (platnik) {
      if (platnik.instytucja) {
        return `${platnik.instytucja.nip} - ${platnik.instytucja.nazwa}`
      } else {
        return `${platnik.osoba.pesel} - ${platnik.osoba.imie} ${platnik.osoba.nazwisko}`
      }
    },
    uuidZwolnieniaDlaPlatnika (platnik) {
      if (platnik.instytucja) {
        return this.uuidZwolnien[platnik.instytucja.nip]
      } else {
        return this.uuidZwolnien[platnik.osoba.pesel]
      }
    }
  },
  notifications: {
    oknoWydrukuZablokowane: {
      title: Komunikaty.OKNO_WYDRUKU_ZABLOKOWANE.TYTUL,
      message: Komunikaty.OKNO_WYDRUKU_ZABLOKOWANE.TRESC,
      type: 'warn',
      timeout: 5000
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables';

.bg-icon {
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  padding: 7px;
  background-color: $info;
}

table {
  /deep/ thead {
    display: none;
  }
  /deep/ td {
    border: none;
    padding: 0.75rem 0;
  }
}
</style>
