import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import router from '../router'

const isProd = process.env.NODE_ENV === 'production'

Vue.use(VueAnalytics, {
  id: 'UA-114384972-1',
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  },
  autoTracking: {
    exception: true
  }
})
