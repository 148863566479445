import Vue from 'vue'
import Utils from './Utils'

export default {

  wyszukaj (fraza) {
    return Vue.http.get('ubezpieczeni/sugestie', { params: { q: fraza, instytucja: 1 } }).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  pobierz (pesel, miejsce) {
    return Vue.http.get('ubezpieczeni/' + pesel, { params: { miejsceUbezpieczenia: miejsce } }).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  pobierzRodzine (pesel) {
    return Vue.http.get('ubezpieczeni/' + pesel + '/podopieczni').then(response => {
      return response.body
    }, Utils.przechwycBlad)
  }
}
