export const ZLE_HASLO_REJESTRACJA = {
  TYTUL: 'Niepoprawne hasło!',
  TRESC: 'Wygląda na to, że podane hasło nia pasuje do wskazanego pliku z certyfikatem.<br>Spróbuj jeszcze raz!'
}

export const ZLE_HASLO_LOGOWANIE = {
  TYTUL: 'Niepoprawne hasło!',
  TRESC: 'Wygląda na to, że podałeś nie to hasło.<br>Spokojnie, spróbuj jeszcze raz!'
}

export const ZLY_CERTYFIKAT = {
  TYTUL: 'Coś poszło nie tak...',
  TRESC: 'Czy hasło do certyfikatu jest poprawne?<br>Spróbuj jeszcze raz!'
}

export const ZLE_HASLO_NOWE_EZLA = {
  TYTUL: 'Niepoprawne hasło!',
  TRESC: 'Nowe eZLA nie zostało wystawione, ponieważ podałeś błędne hasło do certyfikatu.<br>Spokojnie, spróbuj jeszcze raz!'
}

export const BLAD_CERTYFIKATU = {
  TYTUL: 'Coś poszło nie tak...',
  TRESC: 'Nie jesteśmy w stanie odczytać certyfikatu z wybranego pliku...'
}

export const OKNO_WYDRUKU_ZABLOKOWANE = {
  TYTUL: 'Okno z wydrukiem zablokowane!',
  TRESC: 'Przeglądarka zablokowała otwarcie nowego okna z wydrukiem ZLA.'
}

export const NIEZNANY_BLAD = {
  TYTUL: 'Coś poszło nie tak...',
  TRESC: 'Wydarzyło się coś czego nie przewidzieliśmy. Pracujemy nad rozwiązaniem problemu. Przepraszamy!'
}

export const WYGASLA_SESJA_ZUS = {
  TYTUL: 'Wygasła sesja w ZUS!',
  TRESC: 'Aby kontynuować pracę w programie, musisz ponownie zalogować się w ZUS.'
}

export const BLAD_ZUS = {
  TYTUL: 'Wystąpił nieoczekiwany błąd w ZUS!',
  TRESC: 'Wydarzyło się po stronie ZUS coś czego nie przewidzieliśmy. Spróbuj ponownie za chwilę.'
}

export const PLATNIK_NIE_MA_PUE = {
  TRESC_NOWE_ZLA: 'Płatnik nie ma PUE, więc musisz przekazać ubezpieczonemu wydruk ZLA. Wydruki dla wszystkich płatników będą dostępne na kolejnym ekranie.',
  TRESC_PODSUMOWANIE: 'Płatnik nie ma PUE, więc musisz przekazać ubezpieczonemu wydruk ZLA. Kliknij przycisk Pobierz PDF i otwarty dokument wyślij na drukarkę.'
}

export const KUP_PREMIUM_TOOLTIP = {
  TRESC: 'Nie chcesz za każdym razem wpisywać hasła? Kup wersję Premium!'
}
