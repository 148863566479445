<template>
  <div>
    <transition name="slide-fade" mode="out-in">
      <span v-if="!trybZmianaDanych">
        <span class="text-heading">{{ubezpieczony.pesel || ubezpieczony.numerPaszportu}} - {{ubezpieczony.imie}} {{ubezpieczony.nazwisko}}</span>

        <b-badge href="#"
          class="ml-2"
          variant="light"
          @click="kopiujDane()"
          v-b-tooltip="'edycja danych'">
          <icon name="pencil" scale="1.5" class="text-muted"></icon>
        </b-badge>

        <poprzednie-zwolnienia :pesel="ubezpieczony.pesel"></poprzednie-zwolnienia>

        <br>
      </span>

      <div v-if="trybZmianaDanych">
        <b-form-row>
          <b-col sm="6">
            <b-form-group label="Imię:"
                          :invalid-feedback="errors.first('imie')">
              <b-form-input name="pImie"
                            v-model="ubezpieczony.imie"
                            v-validate="'required'"
                            data-vv-as="imię"
                            data-vv-name="imie"
                            :state="!this.errors.has('imie')">
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Nazwisko:"
                          :invalid-feedback="errors.first('nazwisko')">
              <b-form-input name="pNazwisko"
                            v-model="ubezpieczony.nazwisko"
                            v-validate="'required'"
                            data-vv-as="nazwisko"
                            data-vv-name="nazwisko"
                            :state="!this.errors.has('nazwisko')">
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="6">
            <b-form-group label="Data urodzenia:"
              :invalid-feedback="errors.first('dataUrodzenia')">
              <flat-pickr class="form-control"
                name="dataUrodzenia"
                :config="konfigDaty"
                v-model="ubezpieczony.dataUrodzenia"
                v-validate="'required|date_format:YYYY-MM-DD'"
                data-vv-as="data urodzenia"
                :class="{ 'is-invalid': this.errors.has('dataUrodzenia') }">
              </flat-pickr>
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>
    </transition>

    <div v-if="trybZmianaDanych" class="text-right">
      <b-button class="mr-2"
        variant="primary"
        @click="zapisz()">
        zapisz
      </b-button>
      <b-link class="text-muted-light" v-if="!brakDanychUbezpieczonego"
        @click="cofnij()">
        <icon name="undo" scale="0.7"></icon> cofnij
      </b-link>
    </div>
  </div>
</template>

<script>
import PoprzednieZwolnienia from './PoprzednieZwolnienia'

export default {
  name: 'ubezpieczony',
  props: ['ubezpieczony'],
  components: { PoprzednieZwolnienia },
  inject: ['$validator'],
  data () {
    return {
      trybZmianaDanych: false,
      brakDanychUbezpieczonego: false,
      stareDane: {
        imie: null,
        nazwisko: null
      },
      konfigDaty: {
        maxDate: new Date(),
        allowInput: true,
        dateFormat: 'Y-m-d',
        locale: 'pl'
      }
    }
  },
  mounted () {
    this.brakDanychUbezpieczonego = !this.ubezpieczony.imie && !this.ubezpieczony.nazwisko
    this.trybZmianaDanych = this.brakDanychUbezpieczonego
  },
  methods: {
    kopiujDane () {
      this.stareDane.imie = this.ubezpieczony.imie
      this.stareDane.nazwisko = this.ubezpieczony.nazwisko
      this.trybZmianaDanych = true
    },
    cofnij () {
      this.ubezpieczony.imie = this.stareDane.imie
      this.ubezpieczony.nazwisko = this.stareDane.nazwisko
      this.trybZmianaDanych = false
    },
    zapisz () {
      this.$validator.validateAll(['imie', 'nazwisko', 'dataUrodzenia']).then((wynikWalidacji) => {
        if (!wynikWalidacji) {
          return
        }
        this.trybZmianaDanych = false
        this.brakDanychUbezpieczonego = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.btn-edit {
  color: inherit;
}

.text-heading {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
