import { render, staticRenderFns } from "./WystawPodpisz.vue?vue&type=template&id=427edff8&scoped=true&"
import script from "./WystawPodpisz.vue?vue&type=script&lang=js&"
export * from "./WystawPodpisz.vue?vue&type=script&lang=js&"
import style0 from "./WystawPodpisz.vue?vue&type=style&index=0&id=427edff8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427edff8",
  null
  
)

component.options.__file = "WystawPodpisz.vue"
export default component.exports