import { render, staticRenderFns } from "./Wiadomosci.vue?vue&type=template&id=1f02292c&scoped=true&"
import script from "./Wiadomosci.vue?vue&type=script&lang=js&"
export * from "./Wiadomosci.vue?vue&type=script&lang=js&"
import style0 from "./Wiadomosci.vue?vue&type=style&index=0&id=1f02292c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f02292c",
  null
  
)

component.options.__file = "Wiadomosci.vue"
export default component.exports