<template>
  <div>
    <b-form-row>
      <b-col sm="6">
        <b-form-group label="Ulica:">
          <b-form-input name="ulica"
                        v-model="adres.ulica">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Nr domu:"
                      :invalid-feedback="errors.first('nrDomu')">
          <b-form-input name="nrDomu"
                        v-model="adres.nrDomu"
                        v-validate="'required'"
                        data-vv-as="nr domu"
                        data-vv-name="nrDomu"
                        :state="!this.errors.has('nrDomu')">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Nr mieszkania:">
          <b-form-input name="nrMieszkania"
                        v-model="adres.nrMieszkania">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="6">
        <b-form-group label="Miejscowość:"
                      :invalid-feedback="errors.first('miejscowosc')">
          <b-form-input name="miejscowosc"
                        v-model="adres.miejscowosc"
                        v-validate="'required'"
                        data-vv-as="miejscowość"
                        data-vv-name="miejscowosc"
                        :state="!this.errors.has('miejscowosc')">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label="Kod pocztowy:"
                      :invalid-feedback="errors.first('kodPocztowy')">
          <b-form-input name="kodPocztowy"
                        v-model="adres.kodPocztowy"
                        v-validate="'required'"
                        data-vv-as="kod pocztowy"
                        data-vv-name="kodPocztowy"
                        :state="!this.errors.has('kodPocztowy')">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div v-if="pokazPrzyciski" class="text-right">
      <b-button class="mr-2"
        variant="primary"
        @click="zapisz()">
        zapisz
      </b-button>
      <b-link class="text-muted-light"
        @click="cofnij()">
        <icon name="undo" scale="0.7"></icon> cofnij
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'adres-form',
  props: {
    adres: {
      required: true,
      type: Object
    },
    pokazPrzyciski: {
      default: true,
      type: Boolean
    }
  },
  inject: ['$validator'],
  methods: {
    zapisz () {
      this.$validator.validateAll(['nrDomu', 'miejscowosc', 'kodPocztowy']).then((result) => {
        if (!result) { return }
        this.$emit('zapisz', this.adres)
      })
    },
    cofnij () {
      this.$emit('cofnij')
    }
  }
}
</script>
