import { render, staticRenderFns } from "./KodChoroby.vue?vue&type=template&id=79c10462&"
import script from "./KodChoroby.vue?vue&type=script&lang=js&"
export * from "./KodChoroby.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "KodChoroby.vue"
export default component.exports