import Vue from 'vue'
import Utils from './Utils'

export default {

  sprawdzStatus (id) {
    return Vue.http.get('platnosci-online/' + id).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  }

}
