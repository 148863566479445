<template>
  <transition name="slide-fade" mode="out-in">
    <div v-if="!trybEdycja">
      <!-- kafelki -->
      <div class="tiles-container">
        <div class="checkbox-container"
          v-for="(adres, index) in adresy"
          :key="index">

          <div class="custom-control">
            {{ sformatujMiasto(adres) }}<br>{{ sformatujUlice(adres) }}
          </div>

          <a class="icon icon-edit"
            @click.stop="edytujAdres(adres)"
            v-b-tooltip="'edytuj adres'">
            <icon name="pencil" scale="0.8" class="fa-fw text-muted"></icon>
          </a>
          <a class="icon icon-delete"
            @click.stop="usunAdres(adres)"
            v-b-tooltip="'usuń adres'">
            <icon name="times" scale="0.8" class="fa-fw text-muted"></icon>
          </a>
        </div>
      </div>

      <!-- przycisk dodawania -->
      <b-button class="btn-add"
        variant="light"
        @click="nowyAdres()"
        v-b-tooltip="'nowy adres'">
        <strong>+</strong>
      </b-button>
    </div>

    <!-- formularz -->
    <div v-if="trybEdycja">
      <AdresForm :adres="kopiaAdresu"
        @zapisz="zapisz"
        @cofnij="trybEdycja = false">
      </AdresForm>
    </div>
  </transition>
</template>

<script>
import AdresForm from '../form/AdresForm'

export default {
  name: 'adresy',
  components: { AdresForm },
  props: ['adresy'],
  inject: ['$validator'],
  data () {
    return {
      trybEdycja: false,
      edytowanyAdres: null
    }
  },
  computed: {
    kopiaAdresu () {
      return Object.assign({}, this.edytowanyAdres)
    }
  },
  methods: {
    nowyAdres () {
      this.edytowanyAdres = {}
      this.trybEdycja = true
    },
    edytujAdres (adres) {
      this.edytowanyAdres = adres
      this.trybEdycja = true
    },
    usunAdres (adres) {
      const index = this.adresy.indexOf(adres)
      if (index !== -1) {
        this.adresy.splice(index, 1)
      }
    },
    zapisz (adres) {
      const index = this.adresy.indexOf(this.edytowanyAdres)
      if (index !== -1) {
        this.adresy[index] = adres
      } else {
        this.adresy.push(adres)
      }
      this.trybEdycja = false
    },
    sformatujMiasto (adres) {
      return `${adres.kodPocztowy} ${adres.miejscowosc}`
    },
    sformatujUlice (adres) {
      let str = adres.ulica ? `${adres.ulica} ${adres.nrDomu}` : `${adres.poczta} ${adres.nrDomu}`
      str += adres.nrMieszkania ? `/${adres.nrMieszkania}` : ''

      return str
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";

.tiles-container {
  display: inline-block;
  vertical-align: top;
}

.custom-control {
  padding: 1rem 2.5rem 1rem 1rem;
}

.btn-add {
  margin-top: 0.25rem;
  margin-left: -4px;
  background-color: $input-bg;
  height: 80px;
}
</style>
