import Vue from 'vue'
import Utils from './Utils'
import Store from '@/store/index.js'
import * as DostawcyPodpisu from '../constants/DostawcyPodpisu'
import * as ZmianyStanu from '../store/ZmianyStanu'

export default {

  podpiszOswiadczenieEpuapem () {
    return Vue.http.post('logowania-do-zus', { dostawcaPodpisu: DostawcyPodpisu.EPUAP }).then(response => {
      return response.body
    }, Utils.przechwycBlad)
  },

  podpiszOswiadczenieCertyfikatemZus (npwz, haslo) {
    let zadanie = {
      dostawcaPodpisu: DostawcyPodpisu.CERTYFIKAT_ZUS,
      hasloDoCertyfikatu: haslo,
      npwz: npwz
    }
    return Vue.http.post('logowania-do-zus', zadanie).then(response => {
      Store.commit(ZmianyStanu.ZAPAMIETAJ_HASLO_DO_CERTYFIKATU_ZUS, { haslo: haslo })

      return response.body
    }, Utils.przechwycBlad)
  }
}
