<template>
  <div id="page-top">

    <header class="masthead text-center text-white d-flex">
      <div class="container my-auto">
        <div class="row">
          <div class="col-lg-10 mx-auto">
            <h1 class="text-uppercase">
              <strong>Wystawiaj eZLA!</strong>
            </h1>
            <hr>
          </div>
          <div class="col-lg-8 mx-auto">
            <p class="mb-5">
              <strong>
                Już dziś możesz minimalnym nakładem pracy wystawić swojemu pacjentowi elektroniczne zwolnienie lekarskie.
              </strong>
            </p>
            <router-link :to="{name: 'Logowanie'}" class="btn btn-primary btn-xl m-2">Rozpocznij!</router-link>
            <b-btn class="btn-info btn-xl m-2 btn-video" v-b-modal.modalFilm>Zobacz film <icon name="play-circle"/></b-btn>
            <router-link :to="{name: 'Demo'}" class="btn btn-demo btn-xl m-2">Demo</router-link>
          </div>
        </div>
      </div>
    </header>

    <section id="oaplikacji">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">O EZLAPCE</h2>
            <hr class="my-4">
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 text-center">
            <div class="service-box mt-5 mx-auto">
              <span class="sr-icons"><icon name="question" scale="4" class="text-primary mb-3"></icon></span>
              <h3 class="mb-3">Czym jest eZLApka</h3>
              <hr>
              <p class="text-justify">
                eZLApka to internetowa aplikacja umożliwiająca wystawianie elektronicznych zwolnień lekarskich (eZLA).
              </p>
              <p class="text-justify text-muted">
                Możesz z niej swobodnie korzystać na swoim komputerze, tablecie jak i na smartfonie. eZLApka sprawdzi się więc w nowoczesnym gabinecie lekarskim, jak i w skromniej wyposażonej placówce czy na wizycie domowej. Wszystko czego potrzeba to połączenie z internetem.
              </p>
              <p class="text-justify text-muted">
                Zastanów się nad najczęstszym scenariuszem wystawiania przez Ciebie zwolnień lekarskich, porównaj ergonomię jego realizacji w konkurencyjnych programach, a następnie zacznij używać eZLApkę ciesząc się z zaoszczędzonego czasu i energii.
              </p>
              <p class="text-justify text-muted">
                Jeśli chcesz podzielić się pomysłem na dalsze usprawnienia aplikacji możesz zgłosić je <a href="#kontakt">tutaj</a>.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 text-center">
            <div class="service-box mt-5 mx-auto">
              <span class="sr-icons"><icon name="dollar" scale="4" class="text-primary mb-3"></icon></span>
              <h3 class="mb-3">Ile kosztuje eZLApka</h3>
              <hr>
              <p class="text-justify">W wersji podstawowej ZLApka jest i zawsze będzie bezpłatna.</p>
              <p class="text-justify text-muted">
                Obecnie funkcjonalność wersji podstawowej w zupełności wystarcza do wystawiania elektronicznych zwolnień lekarskich.
              </p>
              <p class="text-justify text-muted">
                Jednak, aby móc rozwijać nowe funkcje, nadążać za potrzebą zmian wynikających z przepisów prawa i rozwoju technologii oraz zapewnić ciągłość działania aplikacji w sposób spełniający najwyższe standardy bezpieczeństwa i dostępności oferowanych usług, potrzebne są środki finansowe, których pozyskanie umożliwia sprzedaż wersji płatnych. Dlatego jeśli uważasz, że trudy włożone w opracowanie eZLApki zasługują na Twoje docenienie lub chcesz mieć aktywny wkład w jej rozwój zachęcamy do zakupu wersji premium. Wersja ta rozszerza wersję podstawową o możliwość personalizacji i lepszego dostosowania aplikacji do profilu Twojej działalności, co uczyni pracę z eZLApką bardziej przyjemną i produktywną, pozostawiając Ci tym samym więcej czasu na leczenie Twoich pacjentów.
              </p>
              <p class="text-justify text-muted">
                Szczegóły znajdziesz na <a href="#cennik">tej stronie</a>.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 text-center">
            <div class="service-box mt-5 mx-auto">
              <span class="sr-icons"><icon name="handshake-o" scale="4" class="text-primary mb-3"></icon></span>
              <h3 class="mb-3">Jeśli doceniasz jakość eZLApki</h3>
              <hr>
              <p class="text-justify">Jeśli doceniasz profesjonalizm wykonania eZLApki i chciałbyś z nami budować potencjał swojego gabinetu lub przychodni lekarskiej, zapraszamy do współpracy.</p>
              <p class="text-justify text-muted">
                  Oferujemy wykonywanie stron internetowych opartych o sprawdzone i elastyczne systemy zarządzania treścią, integrację lub wdrażanie systemów obługi pacjenta w zakresie grafików wizyt, systemów kolejkowych czy wsparcia komercyjnych rozliczeń usług realizowanych Twoim pacjentom.
              </p>
              <p class="text-left text-muted">
                  Szczegóły znajdziesz na <a href="#cennikwww">tej stronie</a>.
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div class="container mt-5">
          <div class="col-lg-12">
            <p class="text-center">Korzystanie z aplikacji wymaga akceptacji warunków
              określonych w <b-link href="#" v-b-modal.modalRegulamin>Regulaminie</b-link>.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div class="banner-middle"></div>

    <section id="aktualnosci">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="section-heading">AKTUALNOŚCI</h2>
            <hr class="my-4">
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-6 mx-auto">

            <p class="my-3 text-muted text-center">05-01-2020</p>
            <h3 class="my-4">Konserwacja naszej infrastruktury.</h3>
            <p>W nocy z 07-01-2020 r. na 08-01-2020 r. między godziną 21:00 a 01:00 spodziewane są krótkie przerwy w działaniu eZLApki,
              spowodowane konserwacją infrastruktury, mającą na celu poprawię wydajności i niezawodności aplikacji.
            </p>

            <hr class="my-5">

            <p class="my-3 text-muted text-center">09-05-2019</p>
            <h3 class="my-4">Przyspieszamy!</h3>
            <p>Dziś została wdrożona kolejna wersją eZLApki, w której znacząco przyspieszyliśmy
              ładowanie danych ubezpieczonego. Wystawienie eZLA trwa teraz jeszcze krócej!
            </p>

            <hr class="my-5">

            <p class="my-3 text-muted text-center">23-03-2019</p>
            <h3 class="my-4">Usprawnienia w eZLApce.</h3>
            <p>Usunęliśmy kilka drobniejszych błędów w aplikacji, przez co działa ona jeszcze szybciej i stabilniej.
            </p>

            <hr class="my-5">

            <p class="my-3 text-muted text-center">13-12-2018</p>
            <h3 class="my-4">Nowe funkcje w eZLApce.</h3>
            <p>Dzięki informacjom zwrotnym od naszych użytkowników, wprowadziliśmy kilka nowych udogodnień
              w naszej aplikacji. Wśród nich m. in.:
              <ul>
                <li>dostęp do podglądu poprzednich zwolnień ubezpieczonego podczas wystawiania eZLA (<span class="badge badge-info">PREMIUM</span>)</li>
                <li>zapamiętywanie wpisanych adresów pobytu ubezpieczonego</li>
                <li>przekazywanie informacji o ustaniu zatrudnienia</li>
              </ul>
            </p>

            <hr class="my-5">

            <p class="my-3 text-muted text-center">23-11-2018</p>
            <h3 class="my-4">Promocja z okazji <em><strong>Black Friday</strong></em>.</h3>
            <p>Z okazji <em><strong>Black Friday</strong></em> przygotowaliśmy specjalną ofertę, zarówno dla obecnych, jak i nowych
            użytkowników.
            Zakup licencji <span class="badge badge-info">PREMIUM</span> na pół roku w terminie od 23.11 do 30.11 br.
            tylko za <strong>1zł</strong>!
            </p>
            <p>Nie czekaj, zaloguj się do aplikacji i skorzystaj już dziś!</p>
            <div class="text-center">
              <a class="btn btn-xl btn-info mt-4 text-white" href="/app/zakup-premium"
              v-b-tooltip.hover v-b-tooltip.bottom title="Skorzystanie z promocji będzie możliwe po zalogowaniu się do aplikacji certyfikatem ZUS.">Kup wersję Premium</a>
            </div>

            <hr class="my-5">

            <p class="my-3 text-muted text-center">09-10-2018</p>
            <h3 class="my-4">Nowa funkcja w eZLApce!</h3>
            <p>Dzięki sugestiom naszych użytkowników od dziś wprowadzamy nowe ułatwienie w aplikacji.
              Podczas wystawiania eZLA podpowiadamy datę zakończenia aktualnego
              zaświadczenia ubezpieczonego. Dzięki temu wiadomo od kiedy ma obowiązywać nowe ZLA.
            </p>
            <p>eZLApka łatwe eZLA!</p>

            <hr class="my-5">

            <p class="my-3 text-muted text-center">21-09-2018</p>
            <h3 class="my-4">eZLApka migruje do lepszej infrastruktury!</h3>
            <p>W odpowiedzi na rosnącą popularność eZLApki podjęliśmy decyzję o migracji usługi do infrastruktury o lepszych parametrach.</p>
            <p>W związku z powyższym w nocy z dnia 29 na 30 września nastąpi godzinna przerwa w działaniu aplikacji.</p>

            <hr class="my-5">

            <p class="my-3 text-muted text-center">25-05-2018</p>
            <h3 class="my-4">eZLApka gotowa na RODO!</h3>
            <p>W związku z rozpoczęciem obowiązywania od 25 maja 2018r. Rozporządzenia Parlamentu Europejskiego i
              Rady (UE) 2016/679 z dnia 27 kwietnia 2016r. o ochronie osób fizycznych
              w związku z przetwarzaniem danych osobowych i o swobodnym przepływie takich danych (RODO) informujemy,
              że przetwarzanie danych osobowych w eZLApce jest w pełni dostosowane do tych przepisów:
              <ul>
                <li>dane przetwarzane są wyłącznie w zadeklarowanym celu, prowadzona jest pełna ewidencja przetwarzania
                  danych osobowych,</li>
                <li>zapewniamy bezpieczne przesyłanie danych osobowych i wrażliwych w sieci Internet,</li>
                <li>przechowujemy dane w bezpieczny sposób na terenie Unii Europejskiej w infrastrukturze jednego
                  z wiodących i certyfikowanych dostawców rozwiązań chmurowych - DigitalOcean, LLC.</li>
              </ul>
            </p>

            <hr class="my-5">

            <p class="my-3 text-muted text-center">21-04-2018</p>
            <h3 class="my-4">Przedłużenie okresu promocji</h3>
            <p>
              W związku ze zgłaszanymi problemami z dostępem do własnych certyfikatów ZUS podczas XVIII Kongresu Medycyny Rodzinnej
              przedłużamy okres promocji do <strong>27 kwietnia 2018 r</strong>.
            </p>

            <hr class="my-5">

            <p class="my-3 text-muted text-center">19-04-2018</p>
            <h3 class="my-4">Udostępniono pierwszą wersję eZLApki</h3>

            <p>Aby wystawiać elektroniczne zwolnienia lekarskie:</p>
            <ul>
              <li>
                uzyskaj bezpłatny certyfikat ZUS -
                <a target="_blank" href="http://www.zus.pl/o-zus/aktualnosci/-/publisher/aktualnosc/3/bezplatny-certyfikat-z-zus-nowe-rozwiazanie-dla-lekarzy-ktorzy-wystawiaja-elektroniczne-zwolnienia-lekarskie/1267817">
                  http://www.zus.pl/o-zus/aktualnosci/-/publisher/aktual...
                </a>
              </li>
            </ul>

            <p>oraz na stronie eZlapka.pl:</p>

            <ul>
              <li>zaloguj się certyfikatem ZUS,</li>
              <li>wprowadź dane zwolnienia, podpisz zwolnienie certyfikatem ZUS, eZLApka prześle elektroniczne zwolnienie do ZUS,</li>
              <li>jeżeli jest to wymagane, wydrukuj wystawione zwolnienie.</li>
            </ul>

            <a href="/app/logowanie">Rozpocznij już dziś!</a>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-primary" id="cennik">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mx-auto">
            <h2 class="section-heading text-white text-center my-4">Cennik</h2>
            <hr class="light my-4">
            <div class="table-responsive-sm">
              <table class="table text-white">
                <tr>
                  <th></th>
                  <th class="text-center">Wersja darmowa</th>
                  <th class="text-center">Wersja <span class="badge badge-info">Premium</span></th>
                </tr>
                <tr>
                  <td>Obsługa certyfikatu ZUS</td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Wystawienie eZLA w trybie bieżącym</td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Wydruk eZLA</td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Anulowanie eZLA</td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Wizytówka</td>
                  <td></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Podpowiadanie daty poprzedniego zwolnienia</td>
                  <td></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Podpowiadanie najczęściej używanych kodów chorób</td>
                  <td></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Zapamiętywanie hasła do certyfikatu ZUS</td>
                  <td></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Podtrzymywanie sesji z ZUS</td>
                  <td></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tfoot>
                  <tr>
                    <td></td>
                    <td class="text-center">
                      za darmo <br>
                      <a class="btn btn-xl btn-info mt-4 text-white" href="/app/logowanie">Rozpocznij</a>
                    </td>
                    <td class="text-center">
                      9,90 zł <s>19,90 zł</s> miesięcznie <br>
                      <a class="btn btn-xl btn-info mt-4 text-white" href="/app/zakup-premium">Kup wersję Premium</a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-dark" id="cennikwww">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mx-auto">

            <p class="my-4 text-white">
              Proponowany przez nas mechanizm wizytówek jest podstawowym sposobem zapewnienia publikacji w sieci informacji o rodzaju, sposobie, miejscu i terminach realizowanych usług. Chcąc budować swój internetowy wizerunek zapewniający większą rozpoznawalność marki, zwiększenie zaufania pacjentów i zdobywanie przewagi konkurencyjnej nad innymi gabinetami proponujemy spersonalizowane usługi tworzenia witryn internetowych w ramach trzech poniższych pakietów (ceny mogą się różnić w zależności od specyficznych wymagań zamawiającego).
            </p>

            <div class="table-responsive-sm">
              <table class="table text-white">
                <tr>
                  <th></th>
                  <th class="text-center">Strona z gotowego szablonu</th>
                  <th class="text-center">Strona z indywidualnym projektem graficznym</th>
                  <th class="text-center">Strona z systemem zarządzania treścią</th>
                </tr>
                <tr>
                  <td>Galeria zdjęć</td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Formularz kontaktowy</td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Mapa Google</td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Dostosowanie do urządzeń mobilnych</td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>Indywidualny projekt graficzny</td>
                  <td></td>
                  <td class="text-center"><icon name="check"></icon></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tr>
                  <td>System zarządzania treścią CMS</td>
                  <td></td>
                  <td></td>
                  <td class="text-center"><icon name="check"></icon></td>
                </tr>
                <tfoot>
                  <tr>
                    <td></td>
                    <td class="text-center">
                      500 zł
                    </td>
                    <td class="text-center">
                      1500 zł
                    </td>
                    <td class="text-center">
                      3100 zł
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="kontakt">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mx-auto text-center">
            <h2 class="section-heading">Kontakt</h2>
            <hr class="my-4">
            <p class="mb-5">Wszelkie sugestie, pomysły, uwagi możesz kierować na poniższy adres.</p>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-lg-4 ml-auto text-center">
            <i class="fa fa-phone fa-3x mb-3 sr-contact"></i>
            <p>123-456-6789</p>
          </div> -->
          <div class="col-lg-4 ml-auto mr-auto text-center">
            <span class="sr-contact"><icon name="envelope-o" scale="3" class="mb-3"></icon></span>
            <p>
              <a href="mailto:kontakt@ezlapka.pl">kontakt@ezlapka.pl</a>
            </p>
          </div>
        </div>
      </div>
    </section>

    <CookieLaw message="Korzystając ze strony wyrażasz zgodę na używanie cookies." button-text="Akceptuję" />

    <!-- modal regulamin -->
    <b-modal id="modalRegulamin" title="Regulamin" size="lg" ok-only ok-title="Zamknij" @show="pokazRegulamin()">
      <div class="my-4 container-scroll">
        <vue-markdown :source="regulamin"></vue-markdown>
      </div>
    </b-modal>

    <!-- modal film -->
    <b-modal id="modalFilm" centered size="lg" :hide-header="true" :hide-footer="true">
      <div class="text-center">
        <iframe id="ytplayer" type="text/html"
          width="720" height="405"
          src="https://www.youtube.com/embed/740yd5JHwJM?controls=0&loop=1&modestbranding=1&color=white"
          frameborder="0" allowfullscreen>
        </iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import ScrollReveal from 'scrollreveal'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'Glowna',
  components: { VueMarkdown, CookieLaw },
  metaInfo: {
    link: [{ rel: 'canonical', href: 'https://ezlapka.pl' }],
    meta: [
      { 'property': 'og:title', 'content': 'eZLApka - łatwe eZLA!', 'vmid': 'og:title' },
      { 'property': 'og:url', 'content': 'https://ezlapka.pl', 'vmid': 'og:url' }
    ]
  },
  data () {
    return {
      regulamin: ''
    }
  },
  mounted () {
    window.sr = ScrollReveal()
    window.sr.reveal('.sr-icons', {
      duration: 600,
      scale: 0.3,
      distance: '0px'
    }, 200)
    window.sr.reveal('.sr-contact', {
      duration: 600,
      scale: 0.3,
      distance: '0px'
    }, 300)
  },
  methods: {
    pokazRegulamin () {
      if (!this.regulamin) {
        this.$http.get('/regulamin.md').then(response => {
          this.regulamin = response.body
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.container-alert {
  max-width: $md;
}

.container-scroll {
  height: 30rem;
  overflow: auto;
  border: 1px solid $line-bg;
  padding: 1rem;
}
</style>

<style lang="scss">
@import '../../styles/variables.scss';

.Cookie.Cookie--base {
  .Cookie__button {
    background: $green;
    &:hover {
      background: $green-dark;
    }
  }
}
</style>
