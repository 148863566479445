<template>
  <div class="text-center">
    <span class="description"><strong>{{ dataOd | formatujDate }} &rarr; {{ dataDo | formatujDate }}</strong></span>
    <b-row>
      <b-col lg="6">
        <b-form-group class="form-group-data-od">
          <span class="text-label text-muted">od:</span>
          <b-button v-if="wSzpitalu" size="sm" variant="outline-secondary" :pressed="odDwaTygodnieTemu" v-on:click="ustawDateOd(dwaTygodnieTemu)">dwa tygodnie temu</b-button><!--
          --><b-button v-if="wSzpitalu" size="sm" variant="outline-secondary" :pressed="odTydzienTemu" v-on:click="ustawDateOd(tydzienTemu)">tydzień temu</b-button><!--
          --><b-button v-if="!wSzpitalu" size="sm" variant="outline-secondary" :pressed="odWczoraj" v-on:click="ustawDateOd(wczoraj)">wczoraj</b-button><!--
          --><b-button size="sm" variant="outline-secondary" :pressed="odDzisiaj" v-on:click="ustawDateOd(dzisiaj)">dziś</b-button><!--
          --><b-button v-if="!wSzpitalu" size="sm" variant="outline-secondary" :pressed="odJutra" v-on:click="ustawDateOd(jutro)">jutra</b-button>
          <flat-pickr class="form-control"
                      id="dataOd"
                      :config="konfigDatyOd"
                      v-model="dataOdKalendarz"
                      @on-change="zmienionoDateOd">
          </flat-pickr>
        </b-form-group>
      </b-col>

      <b-col lg="6">
        <b-form-group class="form-group-data-do">
          <span class="text-label text-muted">do:</span>
          <b-button v-if="wSzpitalu" size="sm" variant="outline-secondary" :pressed="doDzisiaj" v-on:click="ustawDateDo(dzisiaj)">dziś</b-button><!--
          --><b-button size="sm" variant="outline-secondary" :pressed="doZaTydzien" v-on:click="ustawDateDo(zaTydzien)">tydzień</b-button><!--
          --><b-button v-if="!wSzpitalu" size="sm" variant="outline-secondary" :pressed="doKoncaTygodnia" v-on:click="ustawDateDo(koniecTygodnia)">do końca tygodnia</b-button><!--
          --><b-button size="sm" variant="outline-secondary" :pressed="doZaDwaTygodnie" v-on:click="ustawDateDo(zaDwaTygodnie)">dwa tygodnie</b-button><!--
          --><b-button v-if="!wSzpitalu" size="sm" variant="outline-secondary" :pressed="doZaMiesiac" v-on:click="ustawDateDo(zaMiesiac)">miesiąc</b-button>
          <flat-pickr class="form-control"
                      id="dataDo"
                      :config="konfigDatyDo"
                      v-model="dataDoKalendarz"
                      @on-change="zmienionoDateDo">
          </flat-pickr>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'okres',
  props: ['dataOd', 'dataDo', 'wSzpitalu', 'brakOgraniczen'],
  data () {
    return {
      dataOdKalendarz: null,
      dataDoKalendarz: null,
      konfigDatyOd: {
        dateFormat: 'Y-m-d',
        inline: true,
        locale: 'pl',
        minDate: null,
        maxDate: null
      },
      konfigDatyDo: {
        dateFormat: 'Y-m-d',
        inline: true,
        locale: 'pl',
        minDate: null,
        maxDate: null
      },
      wczoraj: moment().subtract(1, 'days').toDate(),
      dzisiaj: moment().toDate(),
      jutro: moment().add(1, 'days').toDate(),
      zaTydzien: moment().add(1, 'weeks').toDate(),
      zaDwaTygodnie: moment().add(2, 'weeks').toDate(),
      koniecTygodnia: moment().endOf('week').add(1, 'days').toDate(),
      zaMiesiac: moment().add(1, 'months').toDate(),
      tydzienTemu: moment().subtract(1, 'weeks').toDate(),
      dwaTygodnieTemu: moment().subtract(2, 'weeks').toDate()
    }
  },
  watch: {
    dataOd (newValue, oldValue) {
      this.dataOdKalendarz = newValue
    },
    dataDo (newValue, oldValue) {
      this.dataDoKalendarz = newValue
    },
    brakOgraniczen (newValue, oldValue) {
      this.ustawOgraniczenia()
    }
  },
  created () {
    if (!this.dataOd) {
      this.ustawDateOd(this.wSzpitalu ? this.dwaTygodnieTemu : this.dzisiaj)
      this.ustawDateDo(this.wSzpitalu ? this.dzisiaj : this.koniecTygodnia)
    } else {
      this.ustawDateOd(new Date(this.dataOd))
      this.ustawDateDo(moment(new Date(this.dataOd)).add(3, 'weeks').toDate())
    }
    this.ustawOgraniczenia()
  },
  computed: {
    odDwaTygodnieTemu () {
      return this.tenSamDzien(this.dataOdKalendarz, this.dwaTygodnieTemu)
    },
    odTydzienTemu () {
      return this.tenSamDzien(this.dataOdKalendarz, this.tydzienTemu)
    },
    odWczoraj () {
      return this.tenSamDzien(this.dataOdKalendarz, this.wczoraj)
    },
    odDzisiaj () {
      return this.tenSamDzien(this.dataOdKalendarz, this.dzisiaj)
    },
    odJutra () {
      return this.tenSamDzien(this.dataOdKalendarz, this.jutro)
    },
    doDzisiaj () {
      return this.tenSamDzien(this.dataDoKalendarz, this.dzisiaj)
    },
    doZaTydzien () {
      return this.tenSamDzien(this.dataDoKalendarz, this.zaTydzien)
    },
    doKoncaTygodnia () {
      return this.tenSamDzien(this.dataDoKalendarz, this.koniecTygodnia)
    },
    doZaDwaTygodnie () {
      return this.tenSamDzien(this.dataDoKalendarz, this.zaDwaTygodnie)
    },
    doZaMiesiac () {
      return this.tenSamDzien(this.dataDoKalendarz, this.zaMiesiac)
    }
  },
  methods: {
    ustawDateOd (data) {
      this.dataOdKalendarz = data
      this.zmienionoDateOd(null, data)
      this.ustawOgraniczenia()
    },
    ustawDateDo (data) {
      this.dataDoKalendarz = data
      this.zmienionoDateDo(null, data)
      this.ustawOgraniczenia()
    },
    zmienionoDateOd (event, nowaData) {
      if (this.tenSamDzien(this.dataOd, nowaData)) {
        return
      }

      if (moment(nowaData).isAfter(moment(this.dataDoKalendarz))) {
        this.ustawDateDo(nowaData)
      }

      // hack na strefe czasowa
      let dataOd = nowaData ? moment(nowaData).hours(12) : null
      this.$emit('update:dataOd', dataOd.toDate())
    },
    zmienionoDateDo (event, nowaData) {
      if (this.tenSamDzien(this.dataDo, nowaData)) {
        return
      }

      if (moment(nowaData).isBefore(moment(this.dataOdKalendarz))) {
        this.ustawDateOd(nowaData)
      }

      // hack na strefe czasowa
      let dataDo = nowaData ? moment(nowaData).hours(12) : null
      this.$emit('update:dataDo', dataDo.toDate())
    },
    tenSamDzien (data1, data2) {
      return moment(data1).isSame(moment(data2), 'day')
    },
    ustawOgraniczenia () {
      if (this.wSzpitalu || this.brakOgraniczen) {
        this.konfigDatyOd.minDate = null
        this.konfigDatyOd.maxDate = null
        this.konfigDatyDo.minDate = null
        this.konfigDatyDo.maxDate = null
        return
      }
      const ustawowaMinDataOd = moment().startOf('day').subtract(3, 'days')
      const ustawowaMaxDataOd = moment().endOf('day').add(4, 'days')

      this.konfigDatyOd.minDate = ustawowaMinDataOd.toDate()
      this.konfigDatyOd.maxDate = ustawowaMaxDataOd.toDate()
      this.konfigDatyDo.minDate = ustawowaMinDataOd.toDate()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.description {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    display: block;
    background-color: white;
    margin-top: -0.75rem;
    padding: 0.75rem 0;
}

.form-group-data-od, .form-group-data-do {
  background-color: $input-bg;
  border-radius: 5px;
  width: 307.875px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  .text-label {
    font-size: 0.875rem;
  }
  .btn {
    border: none;
    border-radius: 0;
  }
  /deep/ .flatpickr-input {
    display: none;
  }
  /deep/ .flatpickr-calendar.inline {
    background-color: $input-bg;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  /deep/ .flatpickr-calendar.inline.arrowTop::before, .flatpickr-calendar.inline.arrowTop::after {
    display: none
  }
}

.form-group-data-od .flatpickr-day.selected {
  background-color: $primary !important;
  border-width: 0;
  border-left: 5px solid #8bddcd !important;
}

.form-group-data-do .flatpickr-day.selected {
  background-color: $primary !important;
  border-width: 0;
  border-right: 5px solid #8bddcd !important;
}
</style>
