<template>
  <b-container class="mt-3">
    <b-card class="card-page">
      <h4>Zakup wersji Premium</h4>

      <transition name="slide-fade" mode="out-in">
        <div v-if="!wybranaPozycja">
          <p>Wybierz licencję dostosowaną do Twoich potrzeb.</p>

          <b-card-group deck>
            <b-card v-for="pozycja in pozycjeCennika"
              :key="pozycja.idCennika" no-body style="min-width: 200px">
              <b-card-body>
                <h5>{{ pozycja.opis }}</h5>
                <b-badge variant="primary" class="badge-price my-2">{{ pozycja.cena | formatujKwote }}</b-badge>
              </b-card-body>
              <b-card-footer class="text-center">
                <b-btn variant="info" @click="wybierzLicencje(pozycja)">Wybierz</b-btn>
              </b-card-footer>
            </b-card>
          </b-card-group>
        </div>

        <div v-else>
          <div class="my-4">
            <b-link class="text-muted"
              @click="wybranaPozycja = null">
              &larr; wybierz inną licencję
            </b-link>
          </div>

          <PozycjaCennika v-bind="wybranaPozycja" v-on:update:ilosc="wybranaPozycja.ilosc = $event"></PozycjaCennika>

          <h4 class="my-4">Dane do faktury</h4>
          <b-form @submit.prevent="zaplacOnline">
            <kontrahent-form :kontrahent="kontrahent"></kontrahent-form>
            <div class="mt-4 text-center">
              <b-btn type="submit" variant="primary" :disabled="przetwarzanie" size="lg">
                <icon v-if="przetwarzanie" name="circle-o-notch" scale="0.8" spin></icon>
                Płacę on-line
                <img src="../../assets/przelewy24_neg.svg" alt="Przelewy24" class="przelewy24"/>
              </b-btn>
            </div>
          </b-form>
        </div>
      </transition>

    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Licencje from '../../services/Licencje'
import PozycjaCennika from './PozycjaCennika'
import KontrahentForm from '../form/KontrahentForm'

export default {
  name: 'nowa-licencja',
  components: { KontrahentForm, PozycjaCennika },
  data () {
    return {
      pozycjeCennika: [],
      wybranaPozycja: null,
      przetwarzanie: false,
      kontrahent: {
        nip: null,
        nazwa: null,
        email: null,
        adres: {
          kodPocztowy: null,
          miejscowosc: null,
          ulica: null,
          nrDomu: null,
          nrMieszkania: null
        }
      }
    }
  },
  computed: {
    ...mapGetters(['emailLekarza'])
  },
  mounted () {
    Licencje.dostepneLicencje().then(pc => {
      pc.forEach((p) => {
        p.ilosc = 1
        p.staraCena = 0
      })
      this.pozycjeCennika = pc
    })
    this.kontrahent.email = this.emailLekarza
  },
  methods: {
    wybierzLicencje (pozycjeCennika) {
      this.wybranaPozycja = pozycjeCennika
    },
    zaplacOnline () {
      this.$validator.validateAll().then((result) => {
        if (!result) { return }
        this.przetwarzanie = true

        Licencje.kup(this.wybranaPozycja, this.kontrahent).then(response => {
          window.location.href = response.url
        }, () => { this.przetwarzanie = false })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.card-page {
  max-width: $lg;
}

@media (max-width: $md) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.card {
  margin-bottom: 15px;
}

.badge-price {
  font-size: $font-size-base;
}

.przelewy24 {
  height: 26px;
  margin-left: 1rem;
}
</style>
