
import Vue from 'vue'
import VueResource from 'vue-resource'
import NProgress from 'nprogress'

Vue.use(VueResource)
Vue.http.options.root = '/api'

NProgress.configure({
  showSpinner: false
})

Vue.http.interceptors.push((request, next) => {
  NProgress.start()
  next((response) => {
    NProgress.done()
  })
})
