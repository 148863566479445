import Vue from 'vue'
import Utils from './Utils'

export default {

  moje () {
    return Vue.http.get('licencje/moje').then(response => { return response.body }, Utils.przechwycBlad)
  },

  dostepneLicencje () {
    return Vue.http.get('licencje').then(response => { return response.body }, Utils.przechwycBlad)
  },

  sprawdzKodRabatowy (kod) {
    return Vue.http.get('kody-rabatowe/' + kod).then(response => { return response.body }, error => {
      if (error.status === 409 || error.status === 404 || error.status === 400) {
        return Promise.reject(error)
      } else {
        return Utils.przechwycBlad(error)
      }
    })
  },

  realizujKodRabatowy (kod, pozycjaCennika, kontrahent) {
    let zakup = {
      idCennika: pozycjaCennika.idCennika,
      ile: pozycjaCennika.ilosc,
      kontrahent: kontrahent
    }
    return Vue.http.put('kody-rabatowe/' + kod, zakup).then(response => { return response.body }, error => {
      if (error.status === 409 || error.status === 404 || error.status === 400) {
        return Promise.reject(error)
      } else {
        return Utils.przechwycBlad(error)
      }
    })
  },

  kup (pozycjaCennika, kontrahent) {
    let zakup = {
      idCennika: pozycjaCennika.idCennika,
      ile: pozycjaCennika.ilosc,
      kontrahent: kontrahent
    }
    return Vue.http.post('licencje', zakup).then(response => { return response.body }, Utils.przechwycBlad)
  }

}
