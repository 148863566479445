<template>
  <b-container class="container-alert mt-3">
    <b-card class="card-page" no-body>
      <div class="card-body"></div>

      <div v-if="przetwarzanie" class="text-center text-info pb-4">
        <icon name="cog" scale="3" spin></icon>
      </div>

      <div v-else>
        <b-list-group v-if="wiadomosci.length > 0" flush
          class="list-group-card">
          <b-list-group-item v-for="(wiadomosc) in wiadomosci" :key="wiadomosc.uuid">
            <a v-b-toggle="wiadomosc.uuid">
              <h5 :class="{ 'read': wiadomosc.dataPrzeczytania }">
                <div class="bg-icon">
                  <icon name="envelope-o" scale="1.1" class="text-white"></icon>
                </div>
                {{ wiadomosc.tytul }}
              </h5>
            </a>
            <b-collapse :id="wiadomosc.uuid"
              class="content"
              @shown="przeczytana(wiadomosc)">
              <h5 class="ezla mb-4">
                <div class="bg-icon">
                  <img src="../../assets/logo_neg.svg" alt="Logo eZLApka">
                </div>
                eZLApka
              </h5>
              <p>{{ wiadomosc.tresc }}</p>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>

        <div v-else class="text-center text-muted pb-4">
          <p class="mb-0">Na razie nie mamy żadnych wiadomości.</p>
        </div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import Wiadomosci from '@/services/Wiadomosci'
import * as ZmianyStanu from '@/store/ZmianyStanu'

export default {
  name: 'wiadomosci',
  data () {
    return {
      przetwarzanie: true
    }
  },
  computed: {
    ...mapState([
      'wiadomosci'
    ])
  },
  mounted () {
    Wiadomosci.pobierz().then(wiadomosci => {
      this.$store.commit(ZmianyStanu.ZAPAMIETAJ_WIADOMOSCI, {
        wiadomosci: wiadomosci
      })
      this.przetwarzanie = false
    })
  },
  methods: {
    przeczytana (wiadomosc) {
      if (wiadomosc.dataPrzeczytania) { return }
      Wiadomosci.oznaczJakoPrzeczytana(wiadomosc.uuid).then(() => {
        wiadomosc.dataPrzeczytania = new Date()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

.container-alert {
  max-width: $md;
}

.card-body {
  padding: 0;
  height: 1.25rem;
}

.list-group-card {
  margin-top: -1.25rem;

  .list-group-item {
    padding: 0;
  }

  a {
    display: block;
    padding: 0.75rem 1.25rem;
    color: #495057;
    cursor: pointer;
  }

  a:hover {
    background: $body-bg;
    text-decoration: none;
  }

  .bg-icon {
    display: inline-block;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    padding: 9px 10px;
    background-color: $primary;
    margin-right: 1rem;
  }

  h5 {
    font-weight: 800;
  }

  h5.read {
    font-weight: normal;
    .bg-icon {
      background-color: $text-muted-light;
    }
  }

  h5.ezla {
    font-weight: normal;
    .bg-icon {
      padding: 8px 10px;
      img {
        height: 23px;
      }
    }
  }

  .content {
    padding: 0.75rem 1.25rem 0.75rem 63px;
    p {
      padding-left: 63px;
      white-space: pre-wrap;
    }
  }
}
</style>
