<template>
  <div>
    <b-form-row>
      <b-col sm="12">
        <b-form-group label="Data urodzenia:"
          :invalid-feedback="errors.first('podDataUrodzenia')">
          <flat-pickr class="form-control"
            name="podDataUrodzenia"
            :config="konfigDaty"
            v-model="podopieczny.dataUrodzenia"
            v-validate="'required|date_format:YYYY-MM-DD'"
            data-vv-as="data urodzenia"
            :class="{ 'is-invalid': this.errors.has('podDataUrodzenia') }">
          </flat-pickr>
        </b-form-group>
      </b-col>
    </b-form-row>

    <div class="text-right">
      <b-button class="mr-2"
        variant="primary"
        @click="zapisz()">
        zapisz
      </b-button>
      <b-link class="text-muted-right"
        @click="cofnij()">
        <icon name="undo" scale="0.7"></icon> cofnij
      </b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'podopieczny-form',
  props: ['podopieczny'],
  inject: ['$validator'],
  data () {
    return {
      konfigDaty: {
        maxDate: new Date(),
        allowInput: true,
        dateFormat: 'Y-m-d',
        locale: 'pl'
      }
    }
  },
  methods: {
    zapisz () {
      this.$validator.validateAll(['podDataUrodzenia']).then((result) => {
        if (!result) { return }
        this.$emit('zapisz', this.podopieczny)
      })
    },
    cofnij () {
      this.$emit('cofnij')
    }
  }
}
</script>
