<template>
  <div>
    <b-form-group horizontal label=" Nabywca:" label-cols="2">
      <b-form-radio-group buttons button-variant="outline-primary" v-model="rodzajKontrahenta">
        <b-form-radio value="osoba">Osoba fizyczna</b-form-radio>
        <b-form-radio value="firma">Firma</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <transition name="nip">
      <b-form-group
        v-if="rodzajKontrahenta == 'firma'"
        label="NIP:"
        description="Numer NIP"
        horizontal
        label-cols="2"
        :invalid-feedback="errors.first('nip')">
        <b-form-input name="nip"
          v-model="kontrahent.nip"
          v-validate="'required|nip'"
          data-vv-as="NIP"
          data-vv-name="nip"
          :state="!this.errors.has('nip')">
        </b-form-input>
      </b-form-group>
    </transition>
    <b-form-group label="Nazwa:"
      :description="podpowiedzNazwa"
      horizontal
      label-cols="2"
      :invalid-feedback="errors.first('nazwa')">
      <b-form-input name="nazwa"
        v-model="kontrahent.nazwa"
        v-validate="'required'"
        data-vv-name="nazwa"
        :state="!this.errors.has('nazwa')">
      </b-form-input>
    </b-form-group>
    <b-form-group label="E-mail:"
      description="Podaj adres email, na który wyślemy potwierdzenie płatności"
      horizontal
      label-cols="2"
      :invalid-feedback="errors.first('email')">
      <b-form-input name="email"
        v-model="kontrahent.email"
        v-validate="'required|email'"
        data-vv-as="e-mail"
        data-vv-name="email"
        :state="!this.errors.has('email')">
      </b-form-input>
    </b-form-group>
    <div class="form-row">
      <legend class="col-sm-2 col-form-label">Adres:</legend>
      <div class="col-sm-10">
        <AdresForm :adres="kontrahent.adres" :pokaz-przyciski="false"></AdresForm>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AdresForm from './AdresForm'

export default {
  name: 'kontrahent-form',
  components: { AdresForm },
  props: {
    kontrahent: {
      required: true,
      type: Object
    }
  },
  inject: ['$validator'],
  data: function () {
    return {
      rodzajKontrahenta: 'osoba'
    }
  },
  mounted: function () {
    if (!this.kontrahent.nazwa) {
      this.kontrahent.nazwa = this.imieNazwiskoLekarza
    }
    if (!this.kontrahent.email) {
      this.kontrahent.email = this.emailLekarza
    }
  },
  computed: {
    podpowiedzNazwa () {
      return this.rodzajKontrahenta === 'osoba' ? 'Podaj imię i nazwisko' : 'Podaj nazwę firmy'
    },
    ...mapGetters(['imieNazwiskoLekarza', 'emailLekarza'])
  }
}
</script>

<style lang="scss" scoped>
.nip-enter-active {
  transition: all .6s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.nip-leave-active {
  transition: all .4s ease;
}

.nip-enter, .nip-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
