<template>
  <div>
    <b-dropdown variant="primary" size="lg" right split
      :disabled="przetwarzanie"
      @click="zatwierdz">
      <template slot="button-content">
        <icon v-if="przetwarzanie" name="circle-o-notch" scale="0.8" spin></icon>
        Wystaw i podpisz {{ czymPodpisacOpis }}
      </template>
      <b-dropdown-item :active="wybranyZus" @click="wybierzZus()">certyfikatem ZUS</b-dropdown-item>
      <!-- <b-dropdown-item :active="wybranyEpuap" @click="wybierzEpuap()">profilem zaufanym ePUAP</b-dropdown-item> -->
    </b-dropdown>
    <!-- no-enforce-focus https://github.com/bootstrap-vue/bootstrap-vue/issues/1908#issuecomment-438521566 -->
    <b-modal id="modalHaslo"
             ref="modal"
             centered hide-footer
             no-enforce-focus
             title="Wprowadź hasło do certyfikatu:"
             @shown="przygotuj">
      <form @submit.stop.prevent="sprawdzHaslo">
        <b-form-group>
          <b-form-input type="password"
                        ref="poleHaslo"
                        placeholder="Hasło"
                        v-model="haslo">
          </b-form-input>
        </b-form-group>
        <b-form-group>
          <div class="text-left">
            <b-form-checkbox v-if="!maPremium" :disabled="true" v-b-tooltip.right="kupPremiumTooltip">
              Zapamiętaj hasło do certyfikatu
              <router-link :to="{name: 'PorownanieWersji'}">
                <b-badge variant="info">Premium</b-badge>
              </router-link>
            </b-form-checkbox>
          </div>
        </b-form-group>
        <b-button type="submit" variant="primary" block :disabled="przetwarzanie">
          <icon v-if="przetwarzanie" name="circle-o-notch" scale="0.8" spin></icon>
          Podpisz
        </b-button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import * as ZmianyStanu from '@/store/ZmianyStanu'
import * as DostawcyPodpisu from '@/constants/DostawcyPodpisu'
import * as Komunikaty from '@/constants/Komunikaty'

export default {
  name: 'wystaw-podpisz',
  props: ['przetwarzanie'],
  inject: ['$validator'],
  data () {
    return {
      czymPodpisac: this.$store.state.dostawcaPodpisu,
      haslo: null,
      kupPremiumTooltip: Komunikaty.KUP_PREMIUM_TOOLTIP.TRESC
    }
  },
  computed: {
    ...mapState(['hasloDoCertyfikatu']),
    ...mapGetters(['maPremium']),
    czymPodpisacOpis () {
      switch (this.czymPodpisac) {
        case DostawcyPodpisu.CERTYFIKAT_ZUS:
          return 'certyfikatem ZUS'
        case DostawcyPodpisu.EPUAP:
          return 'profilem zaufanym ePUAP'
      }

      return ''
    },
    wybranyZus () {
      return this.czymPodpisac === DostawcyPodpisu.CERTYFIKAT_ZUS
    },
    wybranyEpuap () {
      return this.czymPodpisac === DostawcyPodpisu.EPUAP
    }
  },
  methods: {
    wybierzZus () {
      this.wybierzDostawcePodpisu(DostawcyPodpisu.CERTYFIKAT_ZUS)
    },
    wybierzEpuap () {
      this.wybierzDostawcePodpisu(DostawcyPodpisu.EPUAP)
    },
    wybierzDostawcePodpisu (dostawcaPodpisu) {
      this.czymPodpisac = dostawcaPodpisu
      this.haslo = ''
      this.$store.commit({
        type: ZmianyStanu.USTAW_DOSTAWCE_PODPISU,
        dostawcaPodpisu: dostawcaPodpisu
      })
    },
    zatwierdz () {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$emit('pokazBledyWalidacji')
          return
        }
        if (this.wybranyZus) {
          if (this.maPremium) {
            this.haslo = this.hasloDoCertyfikatu
            this.wyslij()
          } else {
            this.$refs.modal.show()
          }
        } else {
          this.wyslij()
        }
      })
    },
    sprawdzHaslo (e) {
      e.preventDefault()
      if (!this.haslo) {
        alert('Musisz podać hasło do certyfikatu!')
      } else {
        this.$refs.modal.hide()
        this.wyslij()
      }
    },
    przygotuj () {
      this.haslo = ''
      this.$refs.poleHaslo.focus()
    },
    wyslij () {
      this.$emit('wystaw', { podpis: this.czymPodpisac, haslo: this.haslo })
    }
  }
}
</script>

<style lang="scss" scoped>

/deep/ .modal-content {
  padding: 1rem 2rem 2rem 2rem;
}

</style>
