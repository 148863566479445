import Vue from 'vue'
import Utils from './Utils'

export default {

  polec (emaile, wiadomosc) {
    let p = {
      adresyEmail: emaile,
      wiadomoscOdPolecajacego: wiadomosc
    }
    return Vue.http.post('polecenia-aplikacji', p).then(() => {}, Utils.przechwycBlad)
  }
}
