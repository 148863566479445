<template>
  <b-container class="container-search">
    <b-card>
      <b-card-header>
        <h3>Certyfikaty ZUS</h3>
      </b-card-header>

      <b-card-body>
        <b-container v-if="wyszukiwanie">
          <b-row align-h="center" class="text-center" style="margin-top: 20px">
            <b-col>
              <p class="lead">Szukamy certyfikatów na Twoim dysku {{dysk | wielkaLitera}}...</p>
            </b-col>
          </b-row>
          <b-row align-h="center">
            <b-col cols="10">
              <b-progress id="postep" height="2rem" :value="1" :max="1" variant="info" striped animated></b-progress>
            </b-col>
          </b-row>
        </b-container>
        <template v-else>
          <template v-if="certyfikaty.length > 1">
            <p class="lead">
              Znaleźliśmy <b-badge variant="primary">{{ certyfikaty.length }}</b-badge>
              certyfikaty na Twoim dysku {{dysk | wielkaLitera}}
            </p>
            <p>
              Wskaż ten, którego chcesz używać do logowania i podpisywania eZLA w ZUS.
            </p>
          </template>
          <template v-else-if="certyfikaty.length === 1">
            <p class="lead">
              Znaleźliśmy certyfikat na Twoim dysku {{dysk | wielkaLitera}}!
            </p>
            <p>
              Kliknij w niego, jeżeli chcesz go używać do logowania i podpisywania eZLA w ZUS.
            </p>
          </template>
          <template v-else>
            <p class="lead">
              Nie znaleźliśmy żadnego certyfikatu na Twoim dysku {{dysk | wielkaLitera}}.
            </p>
            <b-alert :show="dysk === 'dropbox'" variant="warning">Pamiętaj, że abyśmy mogli uzyskać dostęp do pliku z certyfikatem musisz umieścić go
              w <a href="https://www.dropbox.com/home/Aplikacje/ezlapka" target="_blank">katalogu naszej aplikacji</a>.
            </b-alert>
            <b-alert :show="dysk === 'onedrive'" variant="info">
              Jeżeli przed chwilą wgrałeś swój certyfikat na dysk OneDrive i dziwisz się że go tu nie ma,
              nie martw się! Po prostu usługa firmy Microsoft działa czasem ze sporym opóźnieniem.
              Odczekaj parę minut i spróbuj ponownie.
            </b-alert>
            <div class="mt-4">
              <b-btn variant="primary" size="lg" @click="wyszukajCertyfikaty">Sprawdź jeszcze raz!</b-btn>
            </div>
          </template>
          <div class="list-group">
            <a href="#" @click="wybierzCertyfikat(c)" class="list-group-item" v-for="c in certyfikaty" :key="c.uuid">
              <icon name="key" scale="1.5"></icon>
              {{ c.nazwaPliku }}
              <b-badge variant="info">utworzony: {{ c.dataUtworzenia | formatujDate }}</b-badge>
            </a>
          </div>
        </template>
      </b-card-body>
    </b-card>
    <!-- no-enforce-focus https://github.com/bootstrap-vue/bootstrap-vue/issues/1908#issuecomment-438521566 -->
    <b-modal id="modalHaslo"
             ref="modal"
             centered
             no-enforce-focus
             cancel-title="Anuluj"
             title="Wprowadź hasło do certyfikatu:"
             @ok="sprawdzHaslo"
             @shown="przygotuj">
      <form @submit.stop.prevent="wyslij">
        <b-form-input type="password"
                      ref="poleHaslo"
                      placeholder="Hasło"
                      required
                      v-model="haslo"></b-form-input>
      </form>
    </b-modal>
  </b-container>
</template>

<script>
import { mapState } from 'vuex'
import CertyfikatyZus from '../services/CertyfikatyZus'
import ProfileLekarzy from '../services/ProfileLekarzy'
import * as KodyBledow from '../constants/KodyBledow'
import * as Komunikaty from '../constants/Komunikaty'

export default {
  name: 'certyfikaty-zus',
  data () {
    return {
      wyszukiwanie: true,
      certyfikaty: [],
      wybranyCertyfikat: null,
      haslo: ''
    }
  },
  created () {
    this.wyszukajCertyfikaty()
  },
  computed: {
    dysk: function () {
      return this.$route.params.skad
    },
    ...mapState(['uuidPolecenia'])
  },
  filters: {
    wielkaLitera: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  methods: {
    wyszukajCertyfikaty: function () {
      this.wyszukiwanie = true
      CertyfikatyZus.pobierzCertyfikaty(this.dysk).then(certyfikaty => {
        this.certyfikaty = certyfikaty
        this.wyszukiwanie = false
      })
    },
    wybierzCertyfikat: function (c) {
      this.wybranyCertyfikat = c
      this.$refs.modal.show()
    },
    sprawdzHaslo: function (e) {
      e.preventDefault()
      if (!this.haslo) {
        this.bladBrakHasla()
      } else {
        this.wyslij()
      }
    },
    przygotuj: function () {
      this.haslo = ''
      this.$refs.poleHaslo.focus()
    },
    wyslij: function () {
      this.wybranyCertyfikat.haslo = this.haslo
      this.$refs.modal.hide()
      CertyfikatyZus.zalogujDoZus(this.wybranyCertyfikat, this.uuidPolecenia).then(response => {
        ProfileLekarzy.ustawProfil().then(r => {
          this.$router.replace({ name: 'StronaStartowa' })
        })
      }, blad => {
        if (blad.body.kod === KodyBledow.ZLE_HASLO_DO_CERTYFIKATU) {
          this.niepoprawneHaslo()
        } else {
          this.bladLadowaniaCertyfikatu()
        }
      })
    }
  },
  notifications: {
    bladBrakHasla: {
      title: 'Brak hasła!',
      message: 'Musisz podać hasło do certyfikatu.',
      type: 'error'
    },
    niepoprawneHaslo: {
      title: Komunikaty.ZLE_HASLO_REJESTRACJA.TYTUL,
      message: Komunikaty.ZLE_HASLO_REJESTRACJA.TRESC,
      type: 'error',
      timeout: 7000
    },
    bladLadowaniaCertyfikatu: {
      title: Komunikaty.BLAD_CERTYFIKATU.TYTUL,
      message: Komunikaty.BLAD_CERTYFIKATU.TRESC,
      type: 'error',
      timeout: 7000
    }
  }
}
</script>
