<template>
  <div>
    <b-form-checkbox id="pobytWSzpitalu"
      class="mb-3"
      v-model="pobytWSzpitalu"
      @change="zmienionoPobytWSzpitalu">
      Pobyt w szpitalu
    </b-form-checkbox>

    <Okres v-if="pobytWSzpitalu"
      :w-szpitalu="true"
      :data-od.sync="niezdolnosc.szpitalDataOd"
      :data-do.sync="niezdolnosc.szpitalDataDo">
    </Okres>

    <div v-if="pobytWSzpitalu"
      class="my-3">
      Okres niezdolności do pracy
    </div>
    <Okres :brakOgraniczen="pobytWSzpitalu"
      :data-od.sync="niezdolnosc.dataOd"
      :data-do.sync="niezdolnosc.dataDo">
    </Okres>
  </div>
</template>

<script>
import Okres from './Okres'

export default {
  name: 'niezdolnosc-do-pracy',
  props: ['niezdolnosc'],
  components: { Okres },
  data () {
    return {
      pobytWSzpitalu: false
    }
  },
  methods: {
    zmienionoPobytWSzpitalu (wSzpitalu) {
      if (!wSzpitalu) {
        this.niezdolnosc.szpitalDataOd = null
        this.niezdolnosc.szpitalDataDo = null
      }
    }
  }
}
</script>

<style scoped>
</style>
