<template>
  <b-container>
    <main role="main">

      <header class="text-center">
        <h1 class="my-4">DEMO</h1>
        <hr class="my-4">

        <div class="icon-eye"><icon name="eye" scale="5"></icon></div>
        <h3>Sprawdź jak prosto wystawia się eZLA!</h3>
        <hr class="my-4">
        <h3>Rozpocznij demo, a następnie:</h3>
      </header>

      <section class="section-wrapper">
        <div class="section-number"><h4>1</h4></div>
        <b-row align-v="center" class="section-body">
          <b-col md="6">
            <p class="my-1">Naciśnij <strong>przycisk</strong>:</p>
          </b-col>
          <b-col md="6" class="text-center">
            <b-img src="../../assets/demo_przycisk.png" fluid alt="Certyfikatem ZUS przechowywanym w eZLApce" />
          </b-col>
        </b-row>
      </section>

      <section class="section-wrapper">
        <div class="section-number"><h4>2</h4></div>
        <b-row align-v="center" class="section-body">
          <b-col md="6">
            <p class="my-1">
              Wpisz nr PWZ: <strong>1234567</strong>
              <button class="btn btn-link p-0 mr-2" v-b-tooltip="'kopiuj do schowka'" v-clipboard:copy="'1234567'">
                <icon name="clipboard" scale="0.8" class="text-muted"></icon>
              </button>
              oraz hasło: <strong>Proste123</strong>
              <button class="btn btn-link p-0 mr-2" v-b-tooltip="'kopiuj do schowka'" v-clipboard:copy="'Proste123'">
                <icon name="clipboard" scale="0.8" class="text-muted"></icon>
              </button>
            </p>
          </b-col>
          <b-col md="6" class="text-center">
            <b-img src="../../assets/demo_modal.png" fluid alt="Wprowadź NPWZ oraz hasło do certyfikatu" />
          </b-col>
        </b-row>
      </section>

      <section class="section-wrapper">
        <div class="section-number"><h4>3</h4></div>
        <b-row align-v="center" class="section-body">
          <b-col md="6">
            <p class="my-1">
              Wprowadź <strong>PESEL</strong> i zobacz jak wystawia się eZLA.
              W wersji demo <strong>nie wprowadzaj</strong> prawdziwego nr PESEL,
              który może być widoczny dla innych użytkowników i <strong>skorzystaj</strong> z jednego z poniższych:
            </p>
            <ul class="section-list">
              <li v-for="pesel in numeryPesel" :key="pesel">
                {{ pesel }}
                <button class="btn btn-link p-0 mr-2" v-b-tooltip="'kopiuj do schowka'" v-clipboard:copy="pesel">
                  <icon name="clipboard" scale="0.8" class="text-muted"></icon>
                </button>
              </li>
            </ul>
          </b-col>
          <b-col md="6" class="text-center">
            <b-img src="../../assets/demo_input.png" fluid alt="Wprowadź PESEL lub nazwisko" />
          </b-col>
        </b-row>
      </section>

      <section class="section-wrapper">
        <div class="section-number"><h4>!</h4></div>
        <b-row align-v="center" class="section-body">
          <b-col md="12">
            <p class="my-1">
              Wersja demonstracyjna jest przeznaczona jedynie do <strong>zapoznania</strong> się z systemem eZLApka:
            </p>
            <ul class="my-2">
              <li>wersja demonstracyjna <strong>nie umożliwia</strong> wystawienia ważnego eZLA, wprowadzone dane <strong>nie są przekazywane</strong> do systemu ZUS,</li>
              <li>działanie systemu ZUS jest <strong>symulowane</strong>, wszelkie udostępniane dane są <strong>fikcyjne</strong>,</li>
              <li>wszelkie wprowadzone dane zostaną <strong>usunięte</strong> kolejnego dnia.</li>
            </ul>
          </b-col>
        </b-row>
      </section>

      <section class="section-summary">
        <h2>Rozpocznij demo już teraz!</h2>
        <a href="https://demo.ezlapka.pl/app/logowanie" class="btn btn-demo btn-xl" target="_blank">Demo</a>
      </section>
    </main>
  </b-container>
</template>

<script>
export default {
  name: 'Demo',
  metaInfo: {
    title: 'Demo',
    link: [{ rel: 'canonical', href: 'https://ezlapka.pl/demo' }],
    meta: [
      { 'property': 'og:title', 'content': 'Demo | eZLApka - łatwe eZLA!', 'vmid': 'og:title' },
      { 'property': 'og:url', 'content': 'https://ezlapka.pl/demo', 'vmid': 'og:url' }
    ]
  },
  data () {
    return {
      numeryPesel: [
        '83081281313',
        '59061936995',
        '94120853817',
        '49122384674',
        '88011193654',
        '60061616635',
        '94082015294',
        '81010385956',
        '47040987195',
        '47031246337'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/variables.scss';

header {
  margin-top: 10rem;

  .icon-eye {
    color: $primary;
    margin-top: 4rem;
  }
}

h1 {
  font-size: 2rem;
}

.section-wrapper {
  padding: 6rem 0 0 0 !important;

  .section-number {
    border-top: 1px solid #DDD;
  }

  h4 {
    display: block;
    width: 36px;
    height: 36px;
    margin: -18px auto;
    background-color: $primary;
    border-radius: 18px;
    color: white;
    text-align: center;
    font-size: 21px;
    font-weight: bold;
    line-height: 36px;
  }

  .section-body {
    padding: 4rem 0 0 0;
  }

  .section-list {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    margin: 2rem 0;
  }
}

.section-summary {
  padding: 6rem 0 0 0 !important;
  text-align: center;

  .btn-demo {
      background-color: #f8ac4a;
      color: white;
      margin: 2rem 0 4rem 0;

      &:hover {
        background-color: darken($color: #f8ac4a, $amount: 10);
        color: white !important;
      }
    }
}
</style>
