import Vue from 'vue'
import Utils from './Utils'
import * as ZmianyStanu from '../store/ZmianyStanu'
import Store from '../store/index'

export default {

  ustawProfil () {
    return Vue.http.get('lekarze/ja').then(response => {
      Store.commit({
        type: ZmianyStanu.USTAW_PROFIL_LEKARZA,
        profil: response.body
      })
    }, Utils.przechwycBlad)
  }
}
